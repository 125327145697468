import moment from "moment";
import { Offcanvas } from "react-bootstrap";
import { handleCurrency } from "../../helper/helper";
import { useEffect, useState } from "react";
import { fetchDashboardBidDetails } from "../../action/dashboard";

export default function ViewBidDetails ({ isOpenModal, setIsOpenModal}) {
  const [viewBidDetails, setViewBidDetails] = useState({ loading: false, data: null })
  useEffect(() => {
    if (isOpenModal) {
      fetchBidDetails(isOpenModal)
    }
  }, [isOpenModal])
  const fetchBidDetails = async (id) => {
    setViewBidDetails({ loading: true, data: null })
    const result = await fetchDashboardBidDetails(id)
    setViewBidDetails({ loading: false, data: result?.data || null })
  } 
    return( <Offcanvas
        show={isOpenModal !== 0}
        onHide={() => {
          setIsOpenModal(0);
          setViewBidDetails((c) => ({ ...c, data: null }))
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>View Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="add_buyer_content">
            {viewBidDetails.loading
              ?
              <div className="loader text-center">Please wait...</div>
              :
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Order Id</div>
                      <div className="input_field">
                        <div className="input">{viewBidDetails.data?.orderId || ""}</div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Order Date</div>
                      <div className="input_field">
                        <div className="input">{viewBidDetails.data?.createdAt ? moment(viewBidDetails.data?.createdAt).format("DD/MM/YYYY") : ""}</div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Delivery Date</div>
                      <div className="input_field">
                        <div className="input">{viewBidDetails.data?.deliveryDate ? moment(viewBidDetails.data?.deliveryDate).format("DD/MM/YYYY") : ""}</div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Address</div>
                      <div className="input_field">
                        <div className="input">{viewBidDetails.data?.addressDetails?.address || ""}</div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Product</div>
                      <div className="input_field">
                        <div className="input">{viewBidDetails.data?.productDetails?.name?.en || ""}</div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Variety</div>
                      <div className="input_field">
                        <div className="input">{viewBidDetails.data?.verietyDetails?.name?.en || ""}</div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Grade</div>
                      <div className="input_field">
                        <div className="input">{viewBidDetails.data?.gradeDetails?.name || ""}</div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Price</div>
                      <div className="input_field">
                        <div className="input">{handleCurrency()} {viewBidDetails.data?.price || ""}</div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Quantity</div>
                      <div className="input_field">
                        <div className="input">  {viewBidDetails.data?.quantity} {viewBidDetails.data?.unitDetails?.name?.en || ""}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex_btn">
                  <button
                    onClick={() => {
                      setIsOpenModal(0);
                      setViewBidDetails((c) => ({ ...c, data: null }))
                    }}
                    className="btn btn-dark" type="button">
                    Close
                  </button>
                </div>
              </form>}
          </div>
        </Offcanvas.Body>
      </Offcanvas>)
}