import React, { useState, useEffect, useRef } from "react";
import { Field, useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import Button from "../../components/bootstrap/Button";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import {
  getGrades,
  getProducts,
  getunits,
  getVeriety,
} from "../../action/users";
import { createBuyerReuest } from "../../action/buyer";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import { handleKeyDown, handleTitle } from "../../helper/helper";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { DeliveryAddress } from "../../components/address/deliveryAddress";
import { WarehouseAddress } from "../../components/address/warehouseAddress";
import { MultiselectDropdown } from "../../components/common/MultiselectDropdown";
import Form from "react-bootstrap/Form";

export const AddBuyerRequest = () => {
  const navigate = useNavigate();

  const [prodId, setProdId] = useState("");
  const [location, setLocation] = useState({});
  const [verietyId, setVerietyId] = useState("");
  const [units, setUnits] = useState([]);
  const [products, setproducts] = useState([]);
  const [verietyList, setVerietyList] = useState([]);
  const [grades, setGrades] = useState([]);
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [showWarehouseDropdown, setShowWarehouseDropdown] = useState(false);
  const [value, setValue] = useState(0);
  const [coordinates, setCoordinates] = useState([]);

  const fetchUnits = async () => {
    const response = await getunits();
    if (response.status) {
      setUnits(response.data);
    }
  };

  const fetchProducts = async () => {
    const response = await getProducts();
    if (response.status) {
      setproducts(response.data);
    }
  };

  const fetchVerieties = async () => {
    const response = await getVeriety(prodId);
    if (response.status) {
      setVerietyList(response.data);
    }
  };

  const fetchGrades = async () => {
    const response = await getGrades();
    if (response.status) {
      setGrades(response.data);
    }
  };

  useEffect(() => {
    if (prodId) fetchVerieties();
  }, [prodId]);

  useEffect(() => {
    fetchUnits();
    fetchProducts();
    fetchGrades();
  }, []);

  const addBidFormik = useFormik({
    initialValues: {
      productId: "",
      quantity: "",
      price: "",
      unitId: "",
      deliveryDate: "",
      addressId: "",
      requestedToWarehouseIds: [],
    },
    onSubmit: async (values) => {
      values.addressId = selectedAddressId;
      if (showWarehouseDropdown) {
        if (selectedWarehouseIds == 0) {
          toast.custom(
            <SingleErrorMessage
              data={"Please select warehouse"}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
          return;
        }
        values.requestedToWarehouseIds = selectedWarehouseIds;
      }
      console.log(values);
      const response = await createBuyerReuest(values);
      if (response.status) {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        addBidFormik.handleReset();
        navigate("/buyerReqest/list");
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const getMSP = (verietyList, selectedVeriety) => {
    const selectedMSP = verietyList.find(
      (element) => element._id == selectedVeriety
    );

    if (!selectedMSP?.msp?.minimum_selling_price) {
      return "";
    }
    return (
      (selectedMSP?.msp?.minimum_selling_price || "") +
      "/ " +
      (selectedMSP?.msp?.unit?.shortcode || "")
    );
  };

  const handleAddressSelect = (data) => {
    setSelectedAddressId(data?.id);
    setCoordinates(data?.coordinates);
  };

  const handleWarehouseSelect = (serviceIds) => {
    setSelectedWarehouseIds(serviceIds);
  };

  const handleRangeChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            {/* <div className="payout_data_ttl">Create New Request</div> */}
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb
                paths={[
                  {
                    name: `${handleTitle()} Requests`,
                    link: "/buyerReqest/list",
                  },
                  { name: "Add", link: "/buyerReqest/add" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="add_buyer_content">
        <form
          action=""
          // className="payment_form"
          onSubmit={addBidFormik.handleSubmit}>
          <div className="row">
            <ul className="form-field-list">
              <li>
                <div className="input-label">
                  Delivery Locaton
                  <span>*</span>
                </div>
                <DeliveryAddress onAddressSelect={handleAddressSelect} />
              </li>
              <li>
                <div className="input-label">Warehouse Request Options</div>
                <div className="checkform_box">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="option1"
                      onChange={() => {
                        setShowWarehouseDropdown(true);
                      }}
                      required
                    />
                    <label
                      class="form-check-label"
                      for="inlineRadio1">
                      Proximity Based Request
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                      onChange={() => {
                        setShowWarehouseDropdown(false);
                      }}
                      required
                    />
                    <label
                      class="form-check-label"
                      for="inlineRadio2">
                      All Warehouse Request
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div className="input-label">
                  Product Category
                  <span>*</span>
                </div>
                <div className="input_field">
                  <Select
                    className="input"
                    onChange={(e) => {
                      setVerietyList([]);
                      setProdId(e.target.value);
                      addBidFormik.setFieldValue("productId", e.target.value);
                    }}
                    required
                    value={addBidFormik.values.productId ?? ""}>
                    <Option value="">Select Order Type</Option>
                    {products.map((unit) => {
                      return (
                        <Option
                          key={unit._id}
                          id={unit._id}
                          value={unit._id}>
                          {unit.name.en}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </li>
              <li>
                <div className="input-label">
                  Product Variety
                  <span>*</span>
                </div>
                <div className="input_field">
                  <Select
                    className="input"
                    onChange={(e) => {
                      setVerietyId(e.target.value);
                      addBidFormik.setFieldValue("verietyId", e.target.value);
                    }}
                    value={addBidFormik.values.verietyId ?? ""}
                    required>
                    <Option value="">Select Variety</Option>
                    {verietyList.map((veriety) => {
                      return (
                        <Option
                          key={veriety._id}
                          id={veriety._id}
                          value={veriety._id}>
                          {veriety?.name?.en}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </li>
              <li>
                <div className="input-label">
                  Expected Delivery Date
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    required
                    className="input"
                    name="deliveryDate"
                    type="date"
                    placeholder="select date"
                    value={addBidFormik.values.deliveryDate}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={addBidFormik.handleChange}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
              </li>
              <li>
                <div className="input-label">
                  Expected Price/kg
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter price"
                    id="beneficiary"
                    name="price"
                    min="1"
                    max="100000000"
                    onChange={(e) => {
                      addBidFormik.setFieldValue("price", e.target.value);
                    }}
                    value={addBidFormik.values.price}
                    required
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </li>
              <li>
                <div className="input-label">Minimum selling Price(MSP)</div>
                <div className="input_field">
                  <input
                    disabled
                    className="input"
                    name="msp"
                    type="text"
                    placeholder="minimum selling price"
                    value={getMSP(verietyList, addBidFormik.values.verietyId)}
                  />
                </div>
              </li>
              <li>
                <div className="input-label">
                  Quantity Request
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="number"
                    className="input"
                    placeholder="Enter quantity"
                    id="beneficiary"
                    name="quantity"
                    min="1"
                    max="100000000"
                    onChange={(e) => {
                      addBidFormik.setFieldValue("quantity", e.target.value);
                    }}
                    value={addBidFormik.values.quantity}
                    required
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </li>
              <li>
                <div className="input-label">
                  Grade
                  <span>*</span>
                </div>
                <div className="input_field">
                  <Select
                    className="input"
                    onChange={(e) => {
                      addBidFormik.setFieldValue("gradeId", e.target.value);
                    }}
                    value={addBidFormik.values.gradeId ?? ""}
                    required>
                    <Option value="">Select Grade</Option>
                    {grades.map((grade) => {
                      return (
                        <Option
                          key={grade._id}
                          id={grade._id}
                          value={grade._id}>
                          {grade.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </li>

              {/* <div className="input-box">
                <div className="input-label">Warehouse</div>
                <WarehouseAddress
                  productId={verietyId}
                  onAddressSelect={handleWarehouseSelect}
                />
              </div> */}

              <li>
                <div className="input-label">
                  Unit
                  <span>*</span>
                </div>
                <div className="input_field">
                  <Select
                    className="input"
                    onChange={(e) => {
                      console.log(e.target.value, "  e");
                      addBidFormik.setFieldValue("unitId", e.target.value);
                    }}
                    value={addBidFormik.values.unitId ?? ""}
                    required>
                    <Option value="">Select Unit</Option>
                    {units.map((unit) => {
                      return (
                        <Option
                          key={unit._id}
                          id={unit._id}
                          value={unit._id}>
                          {unit.shortcode}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </li>
              {showWarehouseDropdown ? (
                <li>
                  <Form.Label>Distance: {value}(KM)</Form.Label>
                  <Form.Range
                    value={value}
                    onChange={handleRangeChange}
                    min={0} // Minimum value for the range slider
                    max={4000} // Maximum value for the range slider
                    step={1}
                  />
                </li>
              ) : (
                ""
              )}

              {showWarehouseDropdown ? (
                <li>
                  <div className="input-label">Warehouse</div>
                  <MultiselectDropdown
                    productId={verietyId}
                    distance={value}
                    coordinates={coordinates}
                    onAddressSelect={handleWarehouseSelect}
                  />
                </li>
              ) : (
                ""
              )}
            </ul>

            <div className="addBuyer_submit-btn addBuyer_submit-btn">
              <Button
                className="btn btn-success"
                type="submit">
                Save
              </Button>
              <Button
                color="dark"
                // isLight="btn btn-primary"
                tag="a"
                to="/buyerReqest/list">
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
