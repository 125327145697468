import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import trashIcon from "../../assets/images/trash-03.svg";
import editIcon from "../../assets/images/edit_btn.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import Button from "../../components/bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  addUnitList,
  deleteUnit,
  getPagesList,
  getUnit,
  putUnit,
} from "../../action/unitList";
import { useFormik } from "formik";
import Pagination from "../../customComponents/pagination/paginationView";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";

const GetUnits = () => {
  const [unitRequestList, setUnitRequestList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState("");

  const getUnitList = async () => {
    setIsLoading(true);
    const response = await getPagesList(pageSize, pageNo);
    if (response.status) {
      setUnitRequestList(response.data.list);
      setTotalData(response.data.count);
      setIsLoading(false);
    }
  };

  const handleDeleteUnit = async () => {
    const response = await deleteUnit(id);
    if (response.status) {
      setPageNo(1);
      getUnitList();
      setShowDeleteModel(false);
      toast.custom(
        <SingleErrorMessage
          data={"Unit deleted Successfully"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  useEffect(() => {
    getUnitList();
  }, [pageNo, pageSize]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    addFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const addFormik = useFormik({
    initialValues: {
      name: { en: "" },
      shortcode: "",
      conversion: {
        value: 0,
      },
    },
    onSubmit: async (values, { resetForm }) => {
      const res = await addUnitList(values);
      if (res.status) {
        setShow(false);
        getUnitList();
        handleClose();
        resetForm();
        toast.custom(
          <SingleErrorMessage
            data={"Unit added successfully"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const editFormik = useFormik({
    initialValues: {
      name: { en: "" },
      shortcode: "",
      conversion: {
        value: 0,
      },
    },
    onSubmit: async (values) => {
      const res = await putUnit(id, values);
      if (res.status) {
        getUnitList();
        setIsEditMode(false);
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const getUnitDetails = async (unitId) => {
    const response = await getUnit(unitId);
    if (response.status) {
      editFormik.setFieldValue("name", response.data?.name);
      editFormik.setFieldValue("shortcode", response.data?.shortcode);
      editFormik.setFieldValue(
        "conversion.value",
        response.data?.conversion?.value
      );
    }
  };

  return (
    <div
      className="payout_data-content"
      style={{ height: "100%" }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Units" }]} />
            </div>
          </div>
          <Button
            color="info"
            tag="a"
            onClick={handleShow}>
            Create New
            <img
              src={plusWhite}
              alt=""
              className="payout_down"
            />
          </Button>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Alias</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan="6"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {unitRequestList?.length > 0 ? (
                      unitRequestList?.map((item) => {
                        return (
                          <tr key={item._id}>
                            <td>{item?.name?.en}</td>
                            <td>{item?.shortcode ?? "-"}</td>
                            <td>
                              <div className="flex_btn">
                                <img
                                  src={editIcon}
                                  onClick={() => {
                                    setId(item._id);
                                    getUnitDetails(item._id);
                                    setIsEditMode(true);
                                  }}
                                />
                                <img
                                  src={trashIcon}
                                  onClick={() => {
                                    setId(item._id);
                                    setShowDeleteModel(true);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="text_center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        {unitRequestList.length > 0 ? (
          <Pagination
            currentPage={pageNo}
            totalCount={totalData}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        ) : (
          <></>
        )}
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Create New Unit</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={addFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Unit Name</div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="name.en"
                          placeholder="Enter name"
                          value={addFormik.values.name.en || ""}
                          onChange={addFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Alias</div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="shortcode"
                          placeholder="Enter alias"
                          value={addFormik.values.shortcode}
                          onChange={addFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Conversion Value (per Kg)</div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          name="conversion.value"
                          placeholder="Enter conversion value"
                          value={addFormik.values?.conversion?.value}
                          onChange={addFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addBuyer_submit-btn">
                  <button
                    className="btn btn-success"
                    type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={isEditMode}
          onHide={() => {
            setIsEditMode(false);
            addFormik.handleReset();
          }}
          placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Unit</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={editFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Unit Name</div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="name.en"
                          placeholder="Enter name"
                          value={editFormik.values?.name?.en}
                          onChange={editFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Alias</div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="shortcode"
                          placeholder="Enter alias"
                          value={editFormik.values.shortcode}
                          onChange={editFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Conversion Value (per Kg)</div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          name="conversion.value"
                          placeholder="Enter conversion value"
                          value={editFormik.values?.conversion?.value}
                          onChange={editFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addBuyer_submit-btn">
                  <button
                    className="btn btn-success"
                    type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={showDeleteModel}
          onHide={() => {
            setShowDeleteModel(false);
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this unit?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteModel(false);
              }}>
              No
            </Button>
            <Button
              className="btn-dark"
              variant="primary"
              onClick={() => {
                handleDeleteUnit();
              }}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default GetUnits;
