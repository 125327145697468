import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import trashicon from "../../assets/images/trash-03.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteAddress,
  editUserDetails,
  getUserDetails,
} from "../../action/userApi";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { getParentId } from "../../action/category";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import uploadImage from "../../action/imageUpload";
import { Addresses } from "../../components/address/userAddress";
import dummyProfileImage from "../../assets/images/dummy-profile-image.jpg";

const UserProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const role = localStorage.getItem("role");
  const [details, setDetails] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [verietyList, setVerietyList] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [deletedAddresses, setDeletedAddresses] = useState([]);
  const [initialAddresses, setInitialAddresses] = useState([]);
  const [initialProductIds, setInitialProductIds] = useState([]);
  const [location, setLocation] = useState({});
  const [searchAddress, setSearchAddress] = useState("");

  const handleAddProduct = () => {
    setProductIds([...productIds, ""]); // Add a new empty entry for the next dropdown
  };

  const getDetails = async () => {
    const response = await getUserDetails(id);
    if (response.status) {
      const data = response?.data ? response?.data[0] : [];
      setDetails(data);
      setProductIds(
        data?.warehouseProducts?.productIds?.length > 0
          ? data?.warehouseProducts?.productIds
          : []
      );
      setAddresses(
        data?.addresses?.length > 0
          ? data?.addresses
          : [{ coordinates: [0, 0], address: "", postalCode: "" }]
      );
      console.log(productIds);
      editProfileFormik.setFieldValue("name", data.name);
      editProfileFormik.setFieldValue("email", data?.email);
      editProfileFormik.setFieldValue("mobile", data?.mobile);
      editProfileFormik.setFieldValue("pic", data?.pic);
      editProfileFormik.setFieldValue("userId", data?._id);
      editProfileFormik.setFieldValue(
        "addresses",
        data?.addresses?.length > 0 ? data?.addresses : []
      );

      editProfileFormik.setFieldValue(
        "warehouseProducts",
        data?.warehouseProducts
      );
    }
  };

  const fetchVerieties = async () => {
    const response = await getParentId(1);
    if (response.status) {
      setVerietyList(response.data);
    }
  };

  const editProfileFormik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      pic: "",
      userId: "",
      addresses: [],
      warehouseProducts: {
        productIds: [],
      },
    },

    onSubmit: async (values) => {
      // values.coordinates = [location.lng, location.lat];
      // values.location = location;
      values.addresses = addresses;

      let deletedIds = [];
      if (productIds?.length) {
        values.warehouseProducts = values.warehouseProducts || {};
        values.warehouseProducts.productIds = productIds;
      }
      if (deletedAddresses?.length) {
        deletedIds = deletedAddresses.map((id) => {
          return id._id;
        });
      }

      console.log(values);

      const response = await editUserDetails(values);
      if (response.status) {
        await handleDeleteAddressIds(deletedIds);
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        navigate("/");
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleDeleteAddressIds = async (ids) => {
    const payload = {
      addressIds: ids,
    };
    const response = await deleteAddress(payload);
    if (response.status) {
      console.log("deleted");
    }
  };

  useEffect(() => {
    getDetails();
    fetchVerieties();
  }, [id]);

  const handleSelectChange = (index, value) => {
    if (productIds.includes(value) && productIds[index] !== value) {
      toast.custom(
        <SingleErrorMessage
          data={
            "This product is already selected. Please choose another product."
          }
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
      return;
    }
    // Create a new array with the updated value at the specified index
    const updatedProductIds = productIds.map((product, i) =>
      i === index ? value : product
    );
    setProductIds(updatedProductIds);
  };

  const handleAddAddress = () => {
    setAddresses([
      ...addresses,
      {
        coordinates: [0, 0],
        address: "",
        postalCode: "",
      },
    ]);
  };

  // const handleDeleteAddress = (index) => {
  //   const addressToRemove = addresses[index];
  //   setDeletedAddresses([...deletedAddresses, addressToRemove]);
  //   setAddresses(addresses.filter((_, i) => i !== index));
  // };
  const handleDeleteProduct = (index) =>
    setProductIds(productIds.filter((_, i) => i !== index));

  const handleDeleteAddress = (index) => {
    const deletedAddress = addresses[index];
    setDeletedAddresses([...deletedAddresses, deletedAddress]);
    setAddresses(addresses.filter((_, i) => i !== index));
  };

  const handleCancel = () => {
    getDetails();
    setAddresses([...initialAddresses]);
    setProductIds([...initialProductIds]);
    setEditMode(false);
    setDeletedAddresses([]); // Reset deleted addresses on cancel
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadMediaImage(file);
    }
  };

  const uploadMediaImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadImage(formData, "profile");
    if (res.status) {
      editProfileFormik.setFieldValue("pic", res.data[0]);
    }
  };

  return (
    <>
      <div className="profile_div">
        <div class="payout_data-head">
          <div class="payout_data-head-top">
            <div class="payout_data-left">
              <div class="payout_data_ttl1">Profile</div>
            </div>
            {role == "SUPERADMIN" || role == "ADMIN" ? (
              ""
            ) : !editMode ? (
              <Button
                color="info"
                tag="a"
                type="button"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
        <form onSubmit={editProfileFormik.handleSubmit}>
          <div className="payout_inner_content">
            <div className="payout_data-content tablePage_content_header">
              <div className="Personal_div">
                <div className="left_img_div">
                  <h6>General Details </h6>
                  <input
                    type="file"
                    id="img_upload"
                    hidden
                    onChange={handleImageChange}
                  />
                  <img
                    src={
                      editProfileFormik.values.pic
                        ? process.env.REACT_APP_S3_URL +
                          "/" +
                          editProfileFormik.values.pic
                        : dummyProfileImage
                    }
                    alt="profileimg"
                    className="profile_img"
                  />
                  {editMode && (
                    <button
                      type="button"
                      className="profile_btn"
                      onClick={() =>
                        document.getElementById("img_upload").click()
                      }
                    >
                      Change Image
                    </button>
                  )}
                </div>
                <div className="right_detail_div">
                  <ul>
                    <li>
                      <label>Name</label>
                      <input
                        type="text"
                        value={editProfileFormik.values?.name}
                        onChange={(e) => {
                          editProfileFormik.setFieldValue(
                            "name",
                            e.target.value
                          );
                        }}
                        readOnly={!editMode}
                      />
                    </li>
                    <li>
                      <label>Email Id</label>
                      <input
                        type="text"
                        value={editProfileFormik.values?.email}
                        readOnly={!editMode}
                        onChange={(e) => {
                          editProfileFormik.setFieldValue(
                            "email",
                            e.target.value
                          );
                        }}
                      />
                    </li>
                    <li>
                      <label>Phone No.</label>
                      <input
                        type="text"
                        value={editProfileFormik.values?.mobile}
                        readOnly={!editMode}
                        onChange={(e) => {
                          editProfileFormik.setFieldValue(
                            "mobile",
                            e.target.value
                          );
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="payout_inner_content">
            {(["BUYER", "EXPORTER", "RETAILER", "WAREHOUSE"].includes(role) ||
              (["SUPERADMIN", "ADMIN"].includes(role) &&
                addresses.length > 0)) && (
              <div className="payout_data-content tablePage_content_header">
                <div className="address_section">
                  <div className="address_head_div">
                    <h6>Address Details</h6>
                    {editMode ? (
                      <button
                        type="button"
                        className="profile_btn"
                        onClick={handleAddAddress}
                      >
                        Add Address
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  {addresses && addresses?.length > 0
                    ? addresses?.map((address, index) => {
                        console.log(address);
                        return (
                          <div key={index} className="address_div">
                            <div className="address_label">
                              <h4>Address {index + 1}</h4>
                              {editMode && !address?.isDefault ? (
                                <div
                                  className="rm_add"
                                  onClick={() => handleDeleteAddress(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={trashicon} alt="trash icon" />{" "}
                                  Remove Section
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <Addresses
                              setAddresses={setAddresses}
                              addresses={addresses}
                              addressesDetails={address}
                              index={index}
                              editMode={editMode}
                              editProfileFormik={editProfileFormik}
                            />
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            )}
          </div>
          <div className="payout_inner_content">
            {role === "WAREHOUSE" ||
            (role == "SUPERADMIN" && productIds.length > 0) ||
            (role == "ADMIN" && productIds.length > 0) ? (
              <div className="payout_data-content tablePage_content_header">
                <div className="product_details">
                  <div className="product_head">
                    <h6>Product Details</h6>
                    {editMode ? (
                      <button
                        type="button"
                        className="profile_btn"
                        onClick={handleAddProduct}
                      >
                        Add Products
                      </button>
                    ) : (
                      " "
                    )}
                  </div>
                  <div className="product_list">
                    <ul>
                      {productIds?.map((product, index) => {
                        // Filter verietyList to exclude products already in productIds, except the current index
                        const filteredVerietyList = verietyList.filter(
                          (veriety) =>
                            !productIds.includes(veriety._id) ||
                            veriety._id === product
                        );

                        return (
                          <li key={index}>
                            <div className="product_label">
                              <label>Product {index + 1}</label>
                              {editMode && (
                                <img
                                  src={trashicon}
                                  alt="trashicon"
                                  onClick={() => handleDeleteProduct(index)}
                                />
                              )}
                            </div>
                            <div className="product_dropdn">
                              <select
                                value={product}
                                disabled={!editMode}
                                onChange={(e) =>
                                  handleSelectChange(index, e.target.value)
                                }
                              >
                                <option>Select Product</option>
                                {filteredVerietyList.map((veriety) => (
                                  <option key={veriety._id} value={veriety._id}>
                                    {veriety?.name?.en}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            {editMode ? (
              <div className="profile_submit_div">
                <button className="btn btn-success btn_submit">Save</button>
                <button
                  className="btn_submit btn btn-dark"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* </>
          ) : (
            ""
          )} */}
        </form>
      </div>
    </>
  );
};

export default UserProfile;
