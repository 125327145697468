import { SideMenuItems } from "./sideNavItems";
import {
  adminMenu,
  sidenavData,
  warehouse,
  exporterMenu,
  retailerMenu,
  superAdminMenu,
} from "../data/sidenav";
import logo from "../assets/images/gaon_logo.png";
import Help from "../assets/images/help-msg.svg";
import settings from "../assets/images/settings.svg";
import dummyProfileImage from "../assets/images/dummy-profile-image.jpg";
import logoutIcon from "../assets/images/log-out.svg";
import { LOGOUT } from "../action/types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import chevronDownIcon from "../assets/images/chevron-down.svg";
import helpInfo from "../assets/images/need_help.svg";
import circleInfo from "../assets/images/info-circle.svg";
import "../styles/style.css";
import "../styles/payout.css";
import { SingleErrorMessage } from "./singleErrorMessage";
import Tooltip from "@mui/material/Tooltip";
import { Logout } from "./logout";
import { getMe } from "../action/users";
import bellIcon from "../assets/images/bell.svg";
import { helper } from "echarts";

export function SideNav({ onClick }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    pic: "",
  });
  const [toggle, setToggle] = useState(false);
  const toggleButton = () => {
    setToggle(!toggle);
  };
  const [open, setOpen] = useState(false);

  const handleClosePopUp = () => {
    setOpen(false);
  };

  const fetchMe = async () => {
    const response = await getMe();
    if (response.status) {
      setUserData(response?.data);
    }
  };

  const handleCloseToaster = () => {
    toast.dismiss();
  };
  const handleLogOut = () => {
    setOpen(true);
  };

  const logout = () => {
    // dispatch({
    //   type: LOGOUT,
    // });
    toast.custom(
      <SingleErrorMessage
        data={"Logout Successfully"}
        bColor={"#175FC9"}
        onClose={handleCloseToaster}
      />
    );
    localStorage.clear();
    navigate("/login");
    setOpen(false);
  };

  const role = localStorage.getItem("role");
  const roleType = localStorage.getItem("roleType");
  const userId = localStorage.getItem("userId");

  let navList =
    role == "BUYER"
      ? sidenavData
      : role == "EXPORTER"
      ? exporterMenu
      : role == "RETAILER"
      ? retailerMenu
      : role == "WAREHOUSE"
      ? warehouse
      : role == "SUPERADMIN"
      ? superAdminMenu
      : role == "ADMIN"
      ? adminMenu
      : [];

  useEffect(() => {
    fetchMe();
    console.log("rolll", role);
  }, []);

  return (
    <>
      <div className={`sideMenu ${toggle ? "collapse" : ""}`}>
        <div className="sidemenu_top">
          <div className="sideMenu_head">
            <div className="sidelogo">
              <img
                src={logo}
                alt=""
                className="sidelogoImg"
              />
            </div>
          </div>

          {navList.map((item, index) => (
            <SideMenuItems
              key={index}
              item={item}
              toggle={toggle}
            />
          ))}
          {role == "SUPERADMIN" ? (
            <div
              className={open ? "sideMenu-item openActive" : "sideMenu-item "}>
              <div
                className="sideLink"
                onClick={() => {
                  navigate("/banner/list");
                }}>
                <div className="sideLink_item">
                  <img
                    src={Help}
                    alt=""
                    className="sidelink_icon"
                  />

                  <span>Banner</span>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="sideMenu-bottom">
          {/* <div className="sideMenu-item">
            <div className="sideLink">
              <Link
                onClick={(e) => {
                  window.location.href = "mailto:support@seccure.biz";
                  e.preventDefault();
                }}
                className="sideLink_item"
              >
                <img src={helpInfo} alt="" className="sidelink_icon" /> Need
                Help?
              </Link>
            </div>
          </div>
          <div className="sideMenu-item">
            <div className="sideLink">
              <Link to={"#"} className="sideLink_item">
                <img src={settings} alt="" className="sidelink_icon" /> Settings
              </Link>
            </div>
          </div> */}
          <div className="side_bottom-card-box">
            <div className="side_bottom-card">
              <div className="bottom_crd-image">
                <img
                  src={
                    userData?.pic
                      ? process.env.REACT_APP_S3_URL + "/" + userData?.pic
                      : dummyProfileImage
                  }
                  alt=""
                  onClick={() => {
                    navigate(`/user/profile/${userId}`);
                  }}
                />
              </div>
              <div className="bottom_crd-text">
                <div className="bottom_crd-title ">
                  <div className="user-d-flex">
                    {/* {userData.name.length > 13
                      ? userData.name.substring(0, 13) + "..."
                      : userData.name} */}
                    {userData.name}
                    {/* {userData?.rolesDetails?.shortcode} */}
                    {/* {role !== "SUPERADMIN" && role !== "ADMIN" && (
                      <div>
                        <img
                          className="bell_icon"
                          src={bellIcon}
                          alt=""
                          height="25px"
                          width="25px"
                          onClick={() => {
                            navigate("/notification/list");
                          }}
                        />
                        {userData?.notificationCount > 0 && (
                          <span>{userData.notificationCount}</span>
                        )}
                      </div>
                    )} */}
                    {/* <span>
                      {userData.name.length > 13 && (
                        <Tooltip className="tooltips" title={userData.name}>
                          <img src={circleInfo} alt="" />
                        </Tooltip>
                      )}
                    </span> */}
                  </div>

                  {/* <div className="bottom_crd-desc">{userData.email}</div> */}
                  <div className="bottom_crd-desc">
                    {userData?.rolesDetails?.shortcode}
                  </div>

                  <div className="bottom_crd-desc">{userData.phone}</div>
                </div>
              </div>
              <div className="logout_btn">
                <img
                  src={logoutIcon}
                  alt=""
                  className="logout_img"
                  onClick={handleLogOut}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse_btn ${toggle ? "active" : ""}`}
        onClick={toggleButton}>
        <img
          src={chevronDownIcon}
          alt=""
          className="collapse_sideMenu"
        />
      </div>

      <Logout
        open={open}
        onClose={handleClosePopUp}
        onLogout={logout}
      />
    </>
  );
}
