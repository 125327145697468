import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import trashIcon from "../../assets/images/trash-03.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import Button from "../../components/bootstrap/Button";
import editIcon from "../../assets/images/edit_btn.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import Pagination from "../../customComponents/pagination/paginationView";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import {
  addGrade,
  deleteGrade,
  editGrade,
  getGradeDetails,
  getGradeList,
} from "../../action/category";

const GradeList = () => {
  const [gradeList, setGradeList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [id, setId] = useState("");
  const [showEditModel, setShowEditModel] = useState(false);

  const getGrades = async () => {
    setIsLoading(true);
    const response = await getGradeList(pageNo, pageSize);
    if (response.status) {
      setGradeList(response.data.list);
      setTotalData(response.data.count);
      setIsLoading(false);
    }
  };

  const getGradesDetails = async (gradeId) => {
    const response = await getGradeDetails(gradeId);
    if (response.status) {
      editFormik.setFieldValue("name", response.data.name);
    }
  };

  const handleDeleteUnit = async () => {
    const response = await deleteGrade(id);
    if (response.status) {
      setPageNo(1);
      getGrades();
      setShowDeleteModel(false);
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    addFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const addFormik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: async (values) => {
      const res = await addGrade(values);
      if (res.status) {
        setShow(false);
        getGrades();
        handleClose();
        addFormik.handleReset();
        toast.custom(
          <SingleErrorMessage
            data={"Grade added successfully"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const editFormik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: async (values) => {
      const res = await editGrade(id, values);
      if (res.status) {
        setShowEditModel(false);
        getGrades();
        handleClose();
        editFormik.handleReset();
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  useEffect(() => {
    getGrades();
  }, [pageNo, pageSize]);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Grades" }]} />
            </div>
          </div>
          <Button color="info" tag="a" onClick={handleShow}>
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Button>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {gradeList?.length > 0 ? (
                      gradeList?.map((item) => {
                        return (
                          <tr key={item._id}>
                            <td>{item?.name}</td>
                            <td className="flex_btn">
                              <img
                                src={editIcon}
                                onClick={() => {
                                  setId(item._id);
                                  getGradesDetails(item._id);
                                  setShowEditModel(true);
                                }}
                              />
                              <img
                                src={trashIcon}
                                onClick={() => {
                                  setId(item._id);
                                  setShowDeleteModel(true);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        {gradeList.length > 0 ? (
          <Pagination
            currentPage={pageNo}
            totalCount={totalData}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        ) : (
          <></>
        )}
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add New Grade</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={addFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Grade Name</div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="name"
                          placeholder="Enter Name"
                          value={addFormik.values.name || ""}
                          onChange={addFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addBuyer_submit-btn">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={showEditModel}
          onHide={() => {
            setShowEditModel(false);
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Grade</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={editFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Grade Name</div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="name"
                          placeholder="Enter Name"
                          value={editFormik.values.name || ""}
                          onChange={editFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addBuyer_submit-btn">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={showDeleteModel}
          onHide={() => {
            setShowDeleteModel(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this unit?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteModel(false);
              }}
            >
              No
            </Button>
            <Button
              className="btn-dark"
              variant="primary"
              onClick={() => {
                handleDeleteUnit();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default GradeList;
