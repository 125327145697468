import React, { useEffect, useRef, useState } from "react";
import "../../styles/payout.css";
import "../../styles/style.css";
import "../../styles/dashboard.css";
import arrdwn from "../../assets/images/arr-dwn_vactor.png";
import d_icon from "../../assets/images/dashboard_card_icon.svg";
import {
  AmountGraph,
  QuanttityGraph,
} from "../../components/Graph/quanttityGraph";
import { OrderChart } from "../../components/Graph/orderChart";
import { DeleveryCelender } from "../../components/deleveryCelender";
import { TopCard } from "../../components/Graph/cards/topCard";
import { CommonTable } from "../../components/commonComponents/commonTable/commonTable";
import {
  fetchDashboardBidDetails,
  fetchOrderAmount,
  fetchOrderQuantity,
  fetchRecentAndOngoingRequests,
  fetchRequestCount,
  fetchRevenueAPI,
  fetchTotalPrice,
} from "../../action/dashboard";
import {
  formatAmount,
  getDateRange,
  getRange,
  handleCurrency,
  sumByKey,
} from "../../helper/helper";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import DatePickerWithButton from "./date-picker/datepicker";
import Offcanvas from "react-bootstrap/Offcanvas";
import ViewBidDetails from "../../components/popup/viewBidDetails";

export const Dashboard = () => {
  const [showdrop, setShowdrop] = useState(false);
  const [showAction, setShowAction] = useState(null);
  const [drptxt, setDroptxt] = useState("Week");
  const dropdownRef = useRef(null); // Reference for the dropdown
  const [topCardRequestData, setTopCardRequestData] = useState(
    getDateRange("week")
  );
  const [revenueRequestData, setRevenueRequestData] = useState({
    startDate: "",
    endDate: "",
  });
  const [revenueChartData, setRevenueChartData] = useState([]);
  const [recentBiddingList, setRecentBiddingList] = useState([]);
  const [ongoingOrderList, setOngoingOrderList] = useState([]);
  const [deliveryCalenderList, setDeliveryCalenderList] = useState([]);
  const [quantityCategoryWise, setQuantityCategoryWise] = useState([]);
  const [amountCategoryWise, setAmountCategoryWise] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(0);
  const [viewBidDetails, setViewBidDetails] = useState({
    loading: false,
    data: null,
  });

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (isOpenModal) {
      fetchBidDetails(isOpenModal);
    }
  }, [isOpenModal]);
  const fetchBidDetails = async (id) => {
    setViewBidDetails({ loading: true, data: null });
    const result = await fetchDashboardBidDetails(id);
    setViewBidDetails({ loading: false, data: result?.data || null });
  };
  const [topCardData, setTopCardData] = useState({
    requests: [],
    totalOrderValue: [],
  });
  const fetchTopCardData = async () => {
    const result = await fetchRequestCount(
      topCardRequestData.startDate,
      topCardRequestData.endDate
    );
    const totalPrice = await fetchTotalPrice(
      topCardRequestData.startDate,
      topCardRequestData.endDate
    );
    setTopCardData((f) => ({
      ...f,
      requests: result?.data?.[0]?.dailyCounts?.length
        ? result.data[0].dailyCounts
        : [],
      totalOrderValue: totalPrice?.data?.length ? totalPrice.data : [],
    }));
  };

  const fetchOrderListData = async (startDate, endDate, type, deliveryDate) => {
    const pageNo = 1;
    const limit = 5;
    const result = await fetchRecentAndOngoingRequests(
      startDate,
      endDate,
      type,
      deliveryDate,
      pageNo,
      limit
    );
    return result.data;
  };
  const updateOnLoadData = async () => {
    setRecentBiddingList(
      await fetchOrderListData(
        topCardRequestData.startDate,
        topCardRequestData.endDate,
        "1",
        ""
      )
    );
    setOngoingOrderList(
      await fetchOrderListData(
        topCardRequestData.startDate,
        topCardRequestData.endDate,
        "2",
        ""
      )
    );
    setQuantityCategoryWise(
      await fetchQuantityCategoryWise(
        topCardRequestData.startDate,
        topCardRequestData.endDate
      )
    );
    setAmountCategoryWise(
      await fetchAmountCategoryWise(
        topCardRequestData.startDate,
        topCardRequestData.endDate
      )
    );
  };

  const fetchDeliveryCalenderList = async (delivery_date) => {
    setDeliveryCalenderList(
      await fetchOrderListData("", "", "3", delivery_date)
    );
  };

  const fetchQuantityCategoryWise = async (startDate, endDate) => {
    const result = await fetchOrderQuantity(startDate, endDate);
    return result.data;
  };
  const fetchAmountCategoryWise = async (startDate, endDate) => {
    const result = await fetchOrderAmount(startDate, endDate);
    return result.data;
  };
  useEffect(() => {
    updateOnLoadData();
  }, [topCardRequestData]);
  const fetchRevenueData = async (s, e) => {
    const result = await fetchRevenueAPI(
      s || revenueRequestData.startDate,
      e || revenueRequestData.endDate
    );
    setRevenueChartData(result?.data?.length ? result.data : []);
    return result?.data?.length ? result.data : [];
  };
  useEffect(() => {
    fetchRevenueData();
  }, [revenueRequestData]);

  useEffect(() => {
    fetchTopCardData();
  }, [topCardRequestData]);

  const handleApply = () => {
    setTopCardRequestData({
      ...topCardRequestData,
      startDate: moment(dateState[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(dateState[0].endDate).format("YYYY-MM-DD"),
    });
    setShowCalender(false);
  };
  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowdrop(false); // Close dropdown if click outside
      setShowAction(null);
    }
  };
  function droptext(value) {
    if (value === 1) {
      setDroptxt("Today");
      setTopCardRequestData(getDateRange("today"));
    } else if (value === 2) {
      setDroptxt("Week");
      setTopCardRequestData(getDateRange("week"));
    } else if (value === 3) {
      setDroptxt("Month");
      setTopCardRequestData(getDateRange("month"));
    } else if (value === 4) {
      setDroptxt("Year");
      setTopCardRequestData(getDateRange("year"));
    } else if (value === 5) {
      setDroptxt("Custom date");
      setShowCalender(true);
      setDateState([
        {
          key: "selection",
          startDate: new Date(topCardRequestData.startDate),
          endDate: new Date(topCardRequestData.endDate),
        },
      ]);
    }
    setShowdrop(false);
  }

  useEffect(() => {
    if (showdrop) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [showdrop]); // Changed from setShowdrop to showdrop
  useEffect(() => {
    if (showAction) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [showAction]); // Changed from setShowdrop to showdrop
  return (
    <>
      {/* <div className="payout_data-content">
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left">
              <div className="payout_data_ttl"></div>
            </div>
          </div>
        </div>

        <Outlet />
      </div> */}
      <div className="dashboard">
        <div className="dashboard_head custom_calendar">
          <h1 className="dash_title">Dashboard</h1>
          <div className="dashboard_day" ref={dropdownRef}>
            <div
              className={`dashboard_day_drop_head ${
                showdrop ? "show" : "hide"
              }`}
              onClick={() => {
                setShowdrop(!showdrop);
              }}
            >
              {drptxt}
              <img src={arrdwn} alt="vactor" />
            </div>
            <div
              className={`dashboard_day_dropdown ${showdrop ? "show" : "hide"}`}
            >
              {/* <div
                className="dashboard_day_dropdown-item"
                onClick={() => {
                  droptext(1);
                }}>
                Today
              </div> */}
              <div
                className="dashboard_day_dropdown-item"
                onClick={() => {
                  droptext(2);
                }}
              >
                Week
              </div>
              <div
                className="dashboard_day_dropdown-item"
                onClick={() => {
                  droptext(3);
                }}
              >
                Month
              </div>
              <div
                className="dashboard_day_dropdown-item"
                onClick={() => {
                  droptext(4);
                }}
              >
                Year
              </div>
              <div
                className="dashboard_day_dropdown-item"
                onClick={() => {
                  droptext(5);
                }}
              >
                Custom Date
              </div>
            </div>
            {showCalender ? (
              <>
                <div className="fade show"></div>
                <div className=" d-flex justify-content-center">
                  <div>
                    <div className="dateRange custom_date_pick">
                      <DateRange
                        style={{ display: "block" }}
                        onChange={(item) => setDateState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={dateState}
                        // maxDate={new Date()}
                        editableDateInputs={false}
                      />
                      <div className="benyf_btn">
                        <button
                          className="beny_left-btn "
                          onClick={() => setShowCalender(false)}
                        >
                          Close
                        </button>
                        <button
                          className={`beny_right-btn newClass active`}
                          onClick={handleApply}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="dash_top_cards">
          <div className="row">
            <TopCard
              title="Total Request"
              icon={d_icon}
              color={"#A1CFDD"}
              Ymax={getRange(
                sumByKey(topCardData.requests, "completedCount") +
                  sumByKey(topCardData.requests, "pendingCount")
              )}
              total={formatAmount(
                sumByKey(topCardData.requests, "completedCount") +
                  sumByKey(topCardData.requests, "pendingCount")
              )}
              data={topCardData.requests?.map((el) => ({
                ...el,
                count: el.completedCount + el.pendingCount,
              }))}
            />
            <TopCard
              title="Pending Request"
              color={"#A1A0EC"}
              Ymax={getRange(
                sumByKey(topCardData.requests, "completedCount") +
                  sumByKey(topCardData.requests, "pendingCount")
              )}
              icon={d_icon}
              total={formatAmount(
                sumByKey(topCardData.requests, "pendingCount")
              )}
              data={topCardData.requests.map((el) => ({
                ...el,
                count: el.pendingCount,
              }))}
            />
            <TopCard
              title="Complete Request"
              color={"#FE9162"}
              Ymax={getRange(
                sumByKey(topCardData.requests, "completedCount") +
                  sumByKey(topCardData.requests, "pendingCount")
              )}
              icon={d_icon}
              total={formatAmount(
                sumByKey(topCardData.requests, "completedCount")
              )}
              data={topCardData.requests.map((el) => ({
                ...el,
                count: el.completedCount,
              }))}
            />
            <TopCard
              title="Total Order Value"
              color={"#FFCA58"}
              Ymax={getRange(
                sumByKey(topCardData.totalOrderValue, "totalAdjustedPrice")
              )}
              icon={d_icon}
              total={`${handleCurrency()} ${formatAmount(
                sumByKey(topCardData.totalOrderValue, "totalAdjustedPrice")
              )}`}
              data={topCardData.totalOrderValue.map((el) => ({
                ...el,
                count: el.totalAdjustedPrice,
              }))}
            />
          </div>
        </div>
        <div className="dash_mid_content">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card1-box">
                    <div className="card1">
                      <div className="card1_head">
                        <div className="card1_ttl">
                          Order Quantity Category wise
                        </div>
                        <div className="card1_date">
                          {/* <input
                            type="date"
                            className="graph_datepicker"
                          /> */}
                          <DatePickerWithButton
                            fetchFunc={fetchQuantityCategoryWise}
                            setFunc={setQuantityCategoryWise}
                          />
                        </div>
                      </div>
                      <div className="card1_cont">
                        {/* <img
                          src={graph2}
                          alt=""
                        /> */}
                        <QuanttityGraph
                          data={
                            quantityCategoryWise?.length
                              ? quantityCategoryWise.map((el) => ({
                                  name: el.productName.en,
                                  value: el.totalConversionUnit,
                                }))
                              : []
                          }
                          unit={"Kg"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card1-box">
                    <div className="card1">
                      <div className="card1_head">
                        <div className="card1_ttl">
                          Order Amount Category wise
                        </div>
                        <div className="card1_date">
                          {/* <input
                            type="date"
                            className="graph_datepicker"
                          />                           */}
                          <DatePickerWithButton
                            fetchFunc={fetchAmountCategoryWise}
                            setFunc={setAmountCategoryWise}
                          />
                        </div>
                      </div>
                      <div className="card1_cont">
                        {/* <img
                          src={graph2}
                          alt=""
                        /> */}
                        <AmountGraph
                          data={
                            amountCategoryWise?.length
                              ? amountCategoryWise.map((el) => ({
                                  name: el.productName.en,
                                  value: el.totalAdjustedPrice,
                                }))
                              : []
                          }
                          unit={handleCurrency()}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card1-box">
                    <div className="card1">
                      <div className="card1_head">
                        <div className="card1_ttl">Revenue Analytics</div>
                        <div className="card1_date">
                          {/* <input
                            type="date"
                            className="graph_datepicker"
                          /> */}
                          <DatePickerWithButton
                            fetchFunc={fetchRevenueData}
                            setFunc={setRevenueChartData}
                          />
                        </div>
                      </div>
                      <div className="card1_cont">
                        {" "}
                        <OrderChart
                          data={
                            revenueChartData.length
                              ? {
                                  x: revenueChartData.map((el) =>
                                    el.month
                                      ? moment(el.month).format("MMM YYYY")
                                      : moment(el.date).format("DD MMM YYYY")
                                  ),
                                  y: revenueChartData.map(
                                    (el) => el.totalAdjustedPrice
                                  ),
                                }
                              : { x: [], y: [] }
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <DeleveryCelender
                data={deliveryCalenderList?.[0]?.paginatedResults}
                fetchDeliveryCalenderList={fetchDeliveryCalenderList}
                viewDetails={setIsOpenModal}
              />
            </div>
          </div>
        </div>
        <div className="table_box">
          <CommonTable
            title="Recent Biding List"
            data={recentBiddingList?.[0]?.paginatedResults}
            viewDetails={setIsOpenModal}
          />
          <CommonTable
            title="Ongoing Order List"
            data={ongoingOrderList?.[0]?.paginatedResults}
            viewDetails={setIsOpenModal}
          />
        </div>
      </div>
      <Offcanvas
        show={isOpenModal !== 0}
        onHide={() => {
          setIsOpenModal(0);
          setViewBidDetails((c) => ({ ...c, data: null }));
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>View Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="add_buyer_content">
            {viewBidDetails.loading ? (
              <div className="loader text-center">Please wait...</div>
            ) : (
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Order Id</div>
                      <div className="input_field">
                        <div className="input">
                          {viewBidDetails.data?.orderId || ""}
                        </div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Order Date</div>
                      <div className="input_field">
                        <div className="input">
                          {moment(viewBidDetails.data?.createdAt).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Address</div>
                      <div className="input_field">
                        <div className="input">
                          {viewBidDetails.data?.addressDetails?.location
                            ?.address || ""}
                        </div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Product</div>
                      <div className="input_field">
                        <div className="input">
                          {viewBidDetails.data?.productDetails?.name?.en || ""}
                        </div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Variety</div>
                      <div className="input_field">
                        <div className="input">
                          {viewBidDetails.data?.verietyDetails?.name?.en || ""}
                        </div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Grade</div>
                      <div className="input_field">
                        <div className="input">
                          {viewBidDetails.data?.gradeDetails?.name || ""}
                        </div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Price</div>
                      <div className="input_field">
                        <div className="input">
                          {handleCurrency()} {viewBidDetails.data?.price || ""}
                        </div>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Quantity</div>
                      <div className="input_field">
                        <div className="input">
                          {" "}
                          {viewBidDetails.data?.quantity}{" "}
                          {viewBidDetails.data?.unitDetails?.name?.en || ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex_btn">
                  <button
                    onClick={() => {
                      setIsOpenModal(0);
                      setViewBidDetails((c) => ({ ...c, data: null }));
                    }}
                    className="btn btn-dark"
                    type="button"
                  >
                    Close
                  </button>
                </div>
              </form>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
