import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import { fetchBuyerRequestList } from "../../action/buyer";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import { handleCurrency, handleTitle } from "../../helper/helper";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";

export const BuyerClosedequestList = () => {
  const { id } = useParams();

  const [buyerReuqstList, setBuyerReuqstList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchBuyerList = async () => {
    setIsLoading(true);
    const response = await fetchBuyerRequestList(
      currentPage,
      paginationValue,
      2,
      id
    );
    if (response.status) {
      setBuyerReuqstList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const statusColorMapping = {
    6: "failed",
    2: "success",
  };
  const getStatusClassName = (status) => {
    return statusColorMapping[status] || "";
  };

  useEffect(() => {
    fetchBuyerList();
  }, [currentPage, paginationValue]);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  {
                    name: `${handleTitle()} Request List`,
                    link: `/buyerReqest/list`,
                  },
                  {
                    name: "Warehouse Closed Bid List",
                    link: `/buyerRequest/warehouseBid/:id`,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">User Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Address</div>
                  </th>

                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">MSP </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead headerAction">Status </div>
                  </th>
                </tr>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {buyerReuqstList.length > 0 ? (
                      buyerReuqstList.map((item) => {
                        return (
                          <tr>
                            <td className="noWrap">{item.orderId}</td>
                            <td className="phone_number">
                              {item?.warehouse?.name ?? "-"}
                            </td>
                            <td className="phone_number noWrap">
                              {moment(item?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td className="Status">
                              <div>
                                {item?.warehouseAddress?.location?.address}
                              </div>
                            </td>
                            <td className="table_amount ">{item?.product}</td>
                            <td className="table_amount noWrap">
                              {item?.veriety ?? "-"}
                            </td>
                            <td className="table_amount">{item?.grade}</td>
                            <td className="Status">
                              <div>
                                {item?.mspDetails?.minimum_selling_price || ""}/ {item?.mspDetails?.unit?.shortcode || ""}
                              </div>
                            </td>
                            <td className="Status">
                              <div className="noWrap">
                                {handleCurrency()}{" "}
                                {item?.warehouseDetails?.price}
                              </div>
                            </td>
                            <td className="Status noWrap">
                              <div>
                                {item?.warehouseDetails?.acceptedQty}{" "}
                                {item?.unit}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="noWrap">
                                {moment(
                                  item?.warehouseDetails?.deliveryDate
                                ).format("DD-MM-YYYY")}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`d-flex gap-2 ${getStatusClassName(
                                  item?.warehouseDetails?.status
                                )}`}>
                                {item?.warehouseDetails?.status == 2
                                  ? "Completed"
                                  : "Cancelled"}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <td
                            colSpan="11"
                            className="text_center">
                            No data available
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>

          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>
      </div>
    </div>
  );
};
