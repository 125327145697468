import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { getNotifications, putNotifications } from "../../action/users";
import infoBtn from "../../assets/images/info-circle.svg";
import ViewBidDetails from "../../components/popup/viewBidDetails";

export const Notiifcations = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(0);

  const fetchBuyerList = async () => {
    setIsLoading(true);
    const response = await getNotifications(currentPage, paginationValue);
    if (response.status) {
      setList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const readNotification = async (id, bidId) => {
    setIsOpenModal(0)
    setIsLoading(true);
    const response = await putNotifications(id);
    if (response.status) {
      setIsOpenModal(bidId)
      fetchBuyerList()
      // if (role == "WAREHOUSE") {
      //   navigate(`/bidWarehouse`);
      // } else {
      //   navigate(`/buyerReqest/list`);
      // }
    }
  };

  const role = localStorage.getItem("role");

  useEffect(() => {
    fetchBuyerList();
  }, [currentPage, paginationValue]);

  return (
    <>
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  {
                    name: `Dashboard`,
                    link: `/`,
                  },
                  {
                    name: "Notifications",
                    link: `/notification/list`,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Notification</div>
                  </th>
                  {/* <th className="thHeader">
                    <div className="payoutHead headerAction">Action </div>
                  </th> */}
                </tr>
                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {list.length > 0 ? (
                      list.map((item) => {
                        return (
                          <tr className={item?.isRead ? "" : "unread"}>
                            <td
                              className="noWrap"
                              onClick={() => {
                                readNotification(item._id, item?.payload?.data?.payload?.id || "");
                              }}
                            >
                              {item?.payload?.data?.body}
                            </td>
                            {/* <td>
                              <div className="action_payment addBuyer_submit-btn pd_0 text-center">
                                <img
                                  src={infoBtn}
                                  
                                />
                              </div>
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <td colSpan="11" className="text_center">
                            No data available
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>

          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>
      </div>
    </div>
    <ViewBidDetails isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
};
