import { requestWithToken } from "../helper/helper";

const fetchBuyerRequestList = async (pageNo, pageSize, status, buyerReqId) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);
    if (status && status !== 0) queryParams.push(`status=${status}`);
    if (buyerReqId) queryParams.push(`buyerReqId=${buyerReqId}`);
    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/buyerRequest/list/en${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createBuyerReuest = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/buyerRequest`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const reviceBuyerReuest = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/buyerRequest/revice`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const changeBidStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/buyerRequest/bid/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const completeBuyerBid = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/buyerRequest/complete/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchBuyerConfirmRequestList = async (currentPage, perPage) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/buyerRequest/confirm/list/en?pageNo=${currentPage}&pageSize=${perPage}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchBuyerPendingRequestList = async (pageNo, pageSize) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/buyerRequest/pending/list/en${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchBuyerInprogressRequestList = async (
  pageNo,
  pageSize,
  buyerReqId
) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);
    if (buyerReqId) queryParams.push(`buyerReqId=${buyerReqId}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/buyerRequest/inprogress/list${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const updateBuyerBid = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/buyerRequest/status/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchBuyerClosedRequestList = async (currentPage, perPage) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/buyerRequest/closed/list/en?pageNo=${currentPage}&pageSize=${perPage}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchBuyerRequestList,
  createBuyerReuest,
  reviceBuyerReuest,
  changeBidStatus,
  completeBuyerBid,
  fetchBuyerConfirmRequestList,
  fetchBuyerPendingRequestList,
  fetchBuyerInprogressRequestList,
  updateBuyerBid,
  fetchBuyerClosedRequestList,
};
