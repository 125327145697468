import React from "react";
import { AreaChart } from "../AreaChart";

export const TopCard = ({ title, icon, total, data, color, Ymax }) => {
  return (
    <>
      <div className="col-lg-3">
        <div className="card1">
          <div className="card_area_head">
            <div className="card_area_haed-icon" style={{backgroundColor:color}}>
              <img
                src={icon}
                alt="icon"
              />
            </div>
            <h2 className="graph_card_title">{title}</h2>
          </div>
          <div className="card_area_graph">
            <AreaChart total={total} data={data} color={color} Ymax={Ymax}/>
          </div>
        </div>
      </div>
    </>
  );
};
