import axios from "axios";
import moment from "moment";
import { useNavigation } from "react-router-dom";

export const requestWithoutToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_G_CONSOLE_ENDPOINT + url;

  switch (method) {
    case "GET":
      request = await axios.get(url);
      break;
    case "POST":
      request = await axios.post(url, body);
      break;
    default:
      break;
  }

  return request;
};

export const requestWithToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_G_CONSOLE_ENDPOINT + url;

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
    },
  };

  switch (method) {
    case "GET":
      request = await axios.get(url, headers);
      break;
    case "POST":
      request = await axios.post(url, body, headers);
      break;
    case "PUT":
      request = await axios.put(url, body, headers);
      break;
    case "DELETE":
      request = await axios.delete(url, {
        data: body,
        headers: headers.headers,
      });
      break;

    default:
      break;
  }

  return request;
};

export const middleware = (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem("fstacSecretKey");
    localStorage.removeItem("fstacMid");
    localStorage.removeItem("fstacData");
    localStorage.removeItem("fstacAuthToken");
    window.location.href = "/login";
    return null;
  }
  return Promise.reject(error);
};

function checkLogout(request) {
  const storedLoginTime = localStorage.getItem("loginTime");
  if (storedLoginTime == undefined || storedLoginTime == null) {
    logout();
  }
  if (storedLoginTime) {
    const loginTime = new Date(parseInt(storedLoginTime)).getTime();
    const currentTime = new Date().getTime();
    // console.log(loginTime, currentTime);
    const diffMs = currentTime - loginTime;

    if (diffMs > 86400000) {
      logout();
      return null;
    }
  }
  return request;
}

function logout() {
  localStorage.removeItem("auth-token");
  window.location.href = "/login";
}

export const hasNotch = () => {
  /**
   * For storybook test
   */
  const storybook = window.location !== window.parent.location;
  const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
  const aspect = window.screen.width / window.screen.height;
  const aspectFrame = window.innerWidth / window.innerHeight;
  return (
    (iPhone && aspect.toFixed(3) === "0.462") ||
    (storybook && aspectFrame.toFixed(3) === "0.462")
  );
};

export const handleKeyDown = (event) => {
  if (event.type === "keydown") {
    if (
      (event.key >= "0" && event.key <= "9") ||
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Home" ||
      event.key === "End" ||
      event.key === "Tab"
    ) {
      // Allow the key
    } else {
      // Prevent the key press for all other keys
      event.preventDefault();
    }
  }
};

export const handleTitle = () => {
  const role = localStorage.getItem("role");
  const title =
    role == "BUYER" ? "Buyer" : role == "EXPORTER" ? "Exporter" : "Retailer";

  return title;
};

export const handleBuyerTitle = () => {
  const roleType = localStorage.getItem("roleType");
  const title = roleType == "GAON" ? "Buyer" : "Exporter/Retailer";

  return title;
};

export const handleCurrency = () => {
  const roleType = localStorage.getItem("roleType");
  const title = roleType == "FARMLINK" ? "GYD" : "INR";

  return title;
};

export function getDateRange(option) {
  const today = new Date();
  let startDate = new Date(today);
  let endDate = new Date(today);

  switch (option.toLowerCase()) {
    case "today":
      // startDate and endDate both are today
      break;

    case "week":
      // Start from the beginning of the week (assuming Sunday as the start day)
      startDate.setDate(today.getDate() - today.getDay());
      endDate.setDate(startDate.getDate() + 6);
      break;

    case "month":
      // Start from the beginning of the month
      startDate.setDate(1);
      // Set to the last day of the month
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      break;

    case "year":
      // Start from January 1st
      startDate = new Date(today.getFullYear(), 0, 1);
      // Set to December 31st
      endDate = new Date(today.getFullYear(), 11, 31);
      break;

    default:
      throw new Error(
        "Invalid option. Use 'today', 'week', 'month', or 'year'."
      );
  }

  return {
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  };
}

export function sumByKey(arr, key) {
  return arr.reduce((sum, obj) => sum + (obj[key] || 0), 0);
}

export function formatAmount(amount) {
  if (amount >= 1e7) {
    // Crores
    return (amount / 1e7).toFixed(2) + " Cr";
  } else if (amount >= 1e5) {
    // Lakhs
    return (amount / 1e5).toFixed(2) + " L";
  } else if (amount >= 1e3) {
    // Thousands
    return (amount / 1e3).toFixed(2) + " K";
  }
  return amount.toString();
}

export function formatQuantity(quantity) {
  if (quantity >= 1e3) {
    // Tons
    return (quantity / 1e3).toFixed(2) + " tons";
  } else if (quantity >= 1e2) {
    // Quintals
    return (quantity / 1e2).toFixed(2) + " quintals";
  }
  return quantity + " kg";
}

export function getRange(value) {
  let range = 10;

  // Double or multiply by 5 to get the next range until it meets or exceeds the value
  while (range < value) {
    if (range < 100) {
      range *= 2; // Use a smaller multiplier for smaller ranges
    } else {
      range *= 5; // Use a larger multiplier for larger ranges
    }
  }

  return range;
}
