import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import { useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import {
  completeBuyerBid,
  fetchBuyerInprogressRequestList,
  fetchBuyerRequestList,
  reviceBuyerReuest,
  updateBuyerBid,
} from "../../action/buyer";
import Button from "../../components/bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import { handleCurrency, handleTitle } from "../../helper/helper";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { changeWarhouseBidStatus } from "../../action/warehouse";

export const BuyerInprogressRequestList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [buyerReuqstList, setBuyerReuqstList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const [warehouseId, setWarehouseId] = useState("");
  const [bidId, setId] = useState("");
  const [status, setStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [warehouseIds, setWarehouseIds] = useState([]);
  const [filteredList, setFilteredList] = useState(0);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [remark, setRemark] = useState("");

  const fetchInprogressReuqestList = async () => {
    setIsLoading(true);
    const response = await fetchBuyerInprogressRequestList(
      currentPage,
      paginationValue,
      id
    );
    if (response.status) {
      const list = response.data?.list;
      const uniqueIds = [...new Set(list.map((item) => item._id))];
      const results = uniqueIds.map((id) => {
        const sameIdRecords = list.filter((item) => item._id === id);
        const confirmRequests = sameIdRecords.filter(
          (item) => item.warehouseDetails.status === 1
        ).length;

        return confirmRequests;
      });
      setFilteredList(results);
      setBuyerReuqstList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleCompleteWarehouseBid = async () => {
    const payload = {
      status: 2,
    };
    // const response = await completeBuyerBid(id, payload);
    const response = await updateBuyerBid(id, payload);
    if (response.status) {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );

      navigate("/buyerReqest/list");
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const handleUpdateBid = async () => {
    const payload = {
      status: 2,
    };
    const response = await changeWarhouseBidStatus(warehouseId, payload);
    if (response.status) {
      console.log(filteredList, " dscsfa");
      if (filteredList == 1) {
        handleCompleteWarehouseBid();
      } else {
        // handlePartialCompleteBid();
        fetchInprogressReuqestList();
        setIsUpdateOpenModal(false);
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        navigate("/buyerReqest/list");
      }
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const handlePartialCompleteBid = async () => {
    const payload = {
      status: 5,
    };
    const response = await updateBuyerBid(id, payload);
    if (response.status) {
      console.log("Bid updated successfully");
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const bidCancelFormik = useFormik({
    initialValues: {
      status: 6,
      remark: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      const response = await changeWarhouseBidStatus(warehouseId, values);
      if (response.status) {
        if (filteredList == 1) {
          handleCompleteWarehouseBid();
        } else {
          fetchInprogressReuqestList();
          setIsOpenCancelModal(false);
          toast.custom(
            <SingleErrorMessage
              data={response.message}
              error={false}
              bColor="#175FC9"
              onClose={onCloseToaste}
            />
          );
        }
        bidCancelFormik.handleReset();
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const reviceFormik = useFormik({
    initialValues: {
      id: "",
      deliveryDate: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      values.id = warehouseId;
      const response = await reviceBuyerReuest(values);
      if (response.status) {
        if (filteredList == 1) {
          handleCompleteWarehouseBid();
        } else {
          fetchInprogressReuqestList();
          setIsOpenModal(false);
          reviceFormik.handleReset();
          toast.custom(
            <SingleErrorMessage
              data={response.message}
              error={false}
              bColor="#175FC9"
              onClose={onCloseToaste}
            />
          );
        }
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const checkLastRevice = async (wId) => {
    if (filteredList == 1) {
      const totalReviced = buyerReuqstList.filter(
        (item) => item?.warehouseDetails?.status === 3
      ).length;
      const payload = {};

      if (buyerReuqstList.length - 1 == totalReviced) {
        payload.status = 3;
      } else {
        payload.status = 2;
      }
      const response = await updateBuyerBid(id, payload);
      if (response.status) {
        navigate(`/buyerRequest/revice/${wId}`);
      }
    } else {
      navigate(`/buyerRequest/revice/${wId}`);
    }
  };

  useEffect(() => {
    fetchInprogressReuqestList();
  }, [currentPage, paginationValue]);

  useEffect(() => {
    console.log("Updated filteredList: ", filteredList);
  }, [filteredList]);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  {
                    name: `${handleTitle()} Request List`,
                    link: `/buyerReqest/list`,
                  },
                  {
                    name: "Warehouse Bid List",
                    link: `/buyerRequest/warehouseBid/:id`,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">User Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Address</div>
                  </th>

                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">MSP </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead headerAction">Action </div>
                  </th>
                </tr>
                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {buyerReuqstList.length > 0 ? (
                      buyerReuqstList.map((item) => {
                        return (
                          <tr>
                            <td className="noWrap">{item.orderId}</td>
                            <td className="phone_number">
                              {item?.userDetails?.name ?? "-"}
                            </td>
                            <td className="phone_number noWrap">
                              {moment(item?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td className="Status">
                              <div>
                                {" "}
                                {item?.addressDetails?.location?.address
                                  ? item?.addressDetails?.location?.address
                                  : item?.warehouseDefaultAddress[0]?.location
                                      ?.address}
                              </div>
                            </td>
                            <td className="table_amount ">{item?.product}</td>
                            <td className="table_amount noWrap">
                              {item?.veriety ?? "-"}
                            </td>
                            <td className="Status">
                              <div>
                                {item?.mspDetails?.minimum_selling_price || ""}/{" "}
                                {item?.mspDetails?.unit?.shortcode || ""}
                              </div>
                            </td>
                            <td className="Status">
                              <div className="noWrap">
                                {handleCurrency()}{" "}
                                {item?.warehouseDetails?.price}
                              </div>
                            </td>
                            <td className="table_amount">{item?.grade}</td>
                            <td nowrap className="Status noWrap">
                              {handleCurrency()}{" "}
                              {(item?.warehouseDetails?.price ?? 0) *
                                (item?.warehouseDetails?.acceptedQty ?? 0) *
                                (item?.conversion ?? 0)}
                            </td>
                            <td className="Status noWrap">
                              <div>
                                {item?.warehouseDetails?.acceptedQty}{" "}
                                {item?.unit}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="noWrap">
                                {moment(
                                  item?.warehouseDetails?.deliveryDate
                                ).format("DD-MM-YYYY")}
                              </div>
                            </td>
                            <td>
                              <div className="action_payment addBuyer_submit-btn pd_0">
                                {item?.warehouseDetails?.status === 1 ? (
                                  <>
                                    <Button
                                      color="dark"
                                      isLight="btn btn-primary"
                                      tag="a"
                                      onClick={() => {
                                        setId(item._id);
                                        setWarehouseId(
                                          item?.warehouseDetails?._id
                                        );
                                        navigate(
                                          `/buyerRequest/revice/${item?.warehouseDetails?._id}`
                                        );
                                      }}
                                    >
                                      Revise Request
                                    </Button>
                                    {"  "}
                                    <Button
                                      color="dark"
                                      isLight="btn btn-primary"
                                      tag="a"
                                      onClick={() => {
                                        setId(item._id);
                                        setWarehouseId(
                                          item?.warehouseDetails?._id
                                        );
                                        setIsUpdateOpenModal(true);
                                        setStatus(2);
                                      }}
                                    >
                                      Complete Request
                                    </Button>
                                    <Button
                                      className="btn-dark"
                                      color="info"
                                      isLight="btn btn-primary"
                                      tag="a"
                                      onClick={() => {
                                        setWarehouseId(
                                          item?.warehouseDetails?._id
                                        );
                                        setIsOpenCancelModal(true);
                                      }}
                                    >
                                      Cancel Request
                                    </Button>
                                  </>
                                ) : item?.warehouseDetails?.status === 3 ? (
                                  "Revised"
                                ) : item?.warehouseDetails?.status === 2 ? (
                                  "Completed"
                                ) : item?.warehouseDetails?.status === 6 ? (
                                  "Cancelled"
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <td colSpan="11" className="text_center">
                            No data available
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>

          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>

        {/* complet bid model */}
        <Modal
          show={isUpdateOpenModal}
          onHide={() => {
            setIsUpdateOpenModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bid</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to complete bid?</Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark"
              variant="secondary"
              onClick={() => {
                setIsUpdateOpenModal(false);
              }}
            >
              No
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={handleUpdateBid}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Revice Buyer Request Off Canvas */}
        <Offcanvas
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
            reviceFormik.handleReset();
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Revise Request</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form
                action=""
                // className="payment_form"
                onSubmit={reviceFormik.handleSubmit}
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Delivery Date</div>
                      <div className="input_field">
                        <input
                          className="input"
                          required
                          name="deliveryDate"
                          type="date"
                          placeholder="select date"
                          value={reviceFormik.values.deliveryDate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={reviceFormik.handleChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex_btn">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* complet bid model */}
        <Modal
          show={isOpenCancelModal}
          onHide={() => {
            setIsOpenCancelModal(false);
            bidCancelFormik.handleReset();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bid</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to cancel bid?
            <div className="add_buyer_content">
              <form action="" onSubmit={bidCancelFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Remark
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="remark"
                          placeholder="Enter remark"
                          required
                          value={bidCancelFormik.values.remark}
                          onChange={bidCancelFormik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex_btn">
                  <button
                    className="btn btn-dark"
                    type="button"
                    onClick={() => {
                      setIsOpenCancelModal(false);
                      bidCancelFormik.handleReset();
                    }}
                  >
                    No
                  </button>
                  <button className="btn btn-success" type="submit">
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
