import moment from "moment";
import React from "react";
import { handleCurrency } from "../../../helper/helper";

const CommonTableBody = React.forwardRef(
  ({ value, index, showAction, setShowAction, role, viewDetails }, ref) => {
    return (
      <tr key={index}>
        <td>{value.orderId}</td>
        <td>{moment(value.createdAt).format("DD/MM/YYYY")}</td>
        <td>{value?.addressDetails?.location?.address || ""}</td>
        <td>{value?.productDetails?.name?.en || ""}</td>
        <td>{value?.verietyDetails?.name?.en || ""}</td>
        <td>{value?.gradeDetails?.name || ""}</td>
        <td>
          {handleCurrency()}{" "}
          {role == "BUYER" ? value.price : value?.warehouseDetails?.price}
        </td>
        <td>
          {value.quantity} {value?.unitDetails?.name?.en || ""}
        </td>
        <td>
          {handleCurrency()}{" "}
          {role == "BUYER"
            ? (value?.price ?? 0) *
              (value?.quantity ?? 0) *
              (value?.conversionUnit ?? 0)
            : (value?.warehouseDetails?.price ?? 0) *
              (value?.warehouseDetails?.quantity ?? 0) *
              (value?.warehouseDetails?.conversionUnit ?? 0)}
        </td>
        <td>{moment(value.deliveryDate).format("DD/MM/YYYY")}</td>
        {role !== "SUPERADMIN" && role !== "ADMIN" && (
          <td>
            <div
              className="action_drop-box"
              ref={ref} // Using the forwarded ref here for dropdown
            >
              <div className="">
                <button
                  className="actionBtn"
                  onClick={() => viewDetails(value._id)}
                >
                  View
                </button>
              </div>
              {/* <div
              className="action_drop-box_button"
              onClick={() => {
                setShowAction(index); // Set the dropdown action for this row
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              className={`action_drop-box_dropdown ${
                showAction === index ? "show" : "hide"
              }`}>
           
            </div>*/}
            </div>
          </td>
        )}
      </tr>
    );
  }
);

export default CommonTableBody;
