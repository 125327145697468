import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import { useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import {
  changeBidStatus,
  fetchBuyerClosedRequestList,
  fetchBuyerConfirmRequestList,
  fetchBuyerInprogressRequestList,
  fetchBuyerPendingRequestList,
  fetchBuyerRequestList,
  reviceBuyerReuest,
  updateBuyerBid,
} from "../../action/buyer";
import Button from "../../components/bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import { handleCurrency, handleTitle } from "../../helper/helper";
import infoBtn from "../../assets/images/info-circle.svg";
import { useNavigate } from "react-router-dom";
import { changeWarhouseBidStatus } from "../../action/warehouse";

export const BuyerRequestList = () => {
  const navigate = useNavigate();

  const [buyerReuqstList, setBuyerReuqstList] = useState([]);
  const [reuqstConfirmList, setReuqstConfirmList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [closedList, setClosedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const [warehouseId, setWarehouseId] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredList, setFilteredList] = useState([]);
  const [buyerId, setBuyerId] = useState("");
  const [warehouseStatus, setWarehouseStatus] = useState(0);
  const [isCancelModal, setIsCancelModal] = useState(false);

  const fetchBuyerList = async () => {
    setIsLoading(true);
    const response = await fetchBuyerRequestList(
      currentPage,
      paginationValue,
      status
    );
    if (response.status) {
      setBuyerReuqstList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const fetchClosedReuqestList = async () => {
    setIsLoading(true);
    const response = await fetchBuyerClosedRequestList(
      currentPage,
      paginationValue
    );
    if (response.status) {
      setClosedList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const fetchBuyerPendingList = async () => {
    setIsLoading(true);
    const response = await fetchBuyerPendingRequestList(
      currentPage,
      paginationValue
    );
    if (response.status) {
      setPendingList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const fetchBuyerConfrimList = async () => {
    setIsLoading(true);
    const response = await fetchBuyerConfirmRequestList(
      currentPage,
      paginationValue
    );
    if (response.status) {
      setReuqstConfirmList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleUpdateBid = async () => {
    setIsLoading(true);
    const payload = {
      warehouseBidId: warehouseId,
      status: 2,
    };
    const response = await changeBidStatus(id, payload);
    if (response.status) {
      fetchBuyerList();
      setIsUpdateOpenModal(false);
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      setIsLoading(false);
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const reviceFormik = useFormik({
    initialValues: {
      id: "",
      deliveryDate: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      values.id = id;
      // console.log(values);
      const response = await reviceBuyerReuest(values);
      if (response.status) {
        fetchBuyerList();
        setIsOpenModal(false);
        reviceFormik.setFieldValue("id", "");
        reviceFormik.handleReset();
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        setIsLoading(false);
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  //complete buyer request (parent)
  const handleCompleteBuyerBid = async () => {
    const payload = {
      status: 2,
    };
    const response = await updateBuyerBid(buyerId, payload);
    if (response.status) {
      console.log("Bid updated successfully");
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      setIsUpdateOpenModal(false);
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  //complete warehouse request
  const handleUpdateWarehouseStatus = async () => {
    const payload = {
      status: warehouseStatus,
    };

    const response = await changeWarhouseBidStatus(warehouseId, payload);
    if (response.status) {
      const fetchRecord = filteredList.find((id) => id._id == buyerId);

      if (fetchRecord?.confirmRequests == 1) {
        handleCompleteBuyerBid();
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        setIsUpdateOpenModal(false);
      }
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  // useEffect(() => {
  //   fetchBuyerList();
  //   fetchBuyerPendingList();
  // }, [status, currentPage, paginationValue]);

  useEffect(() => {
    switch (status) {
      case 0:
        fetchBuyerPendingList();
        break;
      case 1:
        fetchBuyerConfrimList();
        break;
      case 2:
        fetchClosedReuqestList();
        break;
      case 5:
        fetchBuyerList();
        break;
    }
  }, [currentPage, paginationValue, status]);

  const statusColorMapping = {
    Cancelled: "failed",
    Completed: "success",
  };
  const getStatusClassName = (status) => {
    return statusColorMapping[status] || "";
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl"> {handleTitle()} Request List</div>
          </div>
          {/* <div className="payout_data-right"> */}

          <Button
            color="info"
            tag="a"
            //  onClick={handleShow}
            to="/buyerRequest/add">
            Create New
            <img
              src={plusWhite}
              alt=""
              className="payout_down"
            />
          </Button>
          {/* </div> */}
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabList>
            <Tab
              onClick={() => {
                setStatus(0);
                setCurrentPage(1);
                setPaginationValue(10);
                fetchBuyerPendingList();
              }}>
              Pending Request
              {/* <div className="paymentHits">125</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setStatus(1);
                setCurrentPage(1);
                setPaginationValue(10);
                fetchBuyerConfrimList();
              }}>
              {" "}
              Confirmed Request
            </Tab>
            <Tab
              onClick={() => {
                setStatus(5);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              {" "}
              Inprogress Request
            </Tab>
            <Tab
              onClick={() => {
                setStatus(2);
                setCurrentPage(1);
                setPaginationValue(10);
                fetchClosedReuqestList();
              }}>
              Closed Request
            </Tab>
          </TabList>
          <TabPanel>
            {/* <div className="payout_table-box upi-mandate_table-box"> */}
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">MSP </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead headerAction text-center">
                        Action{" "}
                      </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody> */}
                {isLoading ? (
                  <tr>
                    <td
                      colSpan="10"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {pendingList.length > 0 ? (
                      pendingList.map((item) => {
                        return (
                          <tr key={item._id}>
                            <td className="noWrap">{item.orderId}</td>
                            <td className="phone_number noWrap">
                              {moment(item?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td className="Status address_td">
                              {item?.location}
                              {/* <div className="noData"></div> */}
                            </td>
                            <td className="table_amount">{item?.product}</td>
                            <td
                              nowrap
                              className="table_amount noWrap">
                              {item?.veriety ?? "-"}
                            </td>
                            <td className="table_amount">{item?.grade}</td>
                            <td className="Status">
                              <div>
                                {item?.mspDetails?.minimum_selling_price || ""}/ {item?.mspDetails?.unit?.shortcode || ""}
                              </div>
                            </td>
                            <td className="Status">
                              <div>
                                {handleCurrency()} {item?.price ?? 0}
                              </div>
                            </td>
                            <td
                              nowrap
                              className="Status noWrap">
                              {item?.quantity} {item?.unit}
                            </td>
                            <td
                              nowrap
                              className="Status noWrap">
                              {handleCurrency()}{" "}
                              {(item?.price ?? 0) *
                                (item?.quantity ?? 0) *
                                (item?.conversion ?? 0)}
                            </td>
                            <td
                              nowrap
                              className="noWrap">
                              {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              <div className="action_payment">
                                <Button
                                  color="info"
                                  tag="a"
                                  className="flex_btn"
                                  to={`/buyerRequest/bid/list/${item?._id}`}
                                  isDisable={
                                    item?.bidsCount > 0 ? false : true
                                  }>
                                  {item?.bidsCount > 0 ? (
                                    <span> {item?.bidsCount} Bids </span>
                                  ) : (
                                    "No bids yet"
                                  )}
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <td
                            colspan="11"
                            className="text_center">
                            No data available
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
                {/* </tbody> */}
              </table>{" "}
            </div>
            {/* </div> */}
          </TabPanel>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Address</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">MSP </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead headerAction">Action </div>
                  </th>
                </tr>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {reuqstConfirmList.length > 0 ? (
                      reuqstConfirmList.map((item) => {
                        return (
                          <tr>
                            <td className="noWrap">{item.orderId}</td>
                            <td className="phone_number noWrap">
                              {moment(item?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td className="Status address_td">
                              <div>{item?.location}</div>
                            </td>
                            <td className="table_amount ">{item?.product}</td>
                            <td className="table_amount noWrap">
                              {item?.veriety ?? "-"}
                            </td>
                            <td className="table_amount">{item?.grade}</td>
                            <td className="Status">
                              <div>
                                {item?.mspDetails?.minimum_selling_price || ""}/ {item?.mspDetails?.unit?.shortcode || ""}
                              </div>
                            </td>
                            <td className="Status">
                              <div className="noWrap">
                                {handleCurrency()} {item?.price}
                              </div>
                            </td>
                            <td className="Status noWrap">
                              <div>
                                {item?.quantity} {item?.unit}
                              </div>
                            </td>
                            <td
                              nowrap
                              className="Status noWrap">
                              {handleCurrency()}{" "}
                              {(item?.price ?? 0) *
                                (item?.quantity ?? 0) *
                                (item?.conversion ?? 0)}
                            </td>
                            <td>
                              {" "}
                              <div className="noWrap">
                                {moment(item?.deliveryDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="action_payment addBuyer_submit-btn pd_0">
                                <img
                                  src={infoBtn}
                                  onClick={() => {
                                    navigate(
                                      `/buyerRequest/warehouseBid/${item._id}`
                                    );
                                  }}
                                  alt="infoIcon"
                                />
                                {/* <Button
                                  color="dark"
                                  isLight="btn btn-primary"
                                  tag="a"
                                  onClick={() => {
                                    setId(item._id);
                                    setIsOpenModal(true);
                                  }}
                                >
                                  Revise Request
                                </Button>
                                {"  "}
                                <Button
                                  color="dark"
                                  isLight="btn btn-primary"
                                  tag="a"
                                  onClick={() => {
                                    setId(item._id);
                                    setWarehouseId(item?.warehouseDetails?._id);
                                    setIsUpdateOpenModal(true);
                                  }}
                                >
                                  Complete Request
                                </Button> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <td
                            colspan="11"
                            className="text_center">
                            No data available
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Address</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">MSP </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Action </div>
                  </th>
                </tr>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {buyerReuqstList.length ? (
                      buyerReuqstList.map((item) => {
                        return (
                          <tr>
                            <td className="noWrap">{item.orderId}</td>
                            <td className="phone_number noWrap">
                              {moment(item?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td className="Status address_td">
                              <div>{item?.location}</div>
                            </td>
                            <td className="table_amount">{item?.product}</td>
                            <td className="table_amount noWrap">
                              {item?.veriety ?? "-"}
                            </td>
                            <td className="table_amount">{item?.grade}</td>
                            <td className="Status">
                              <div>
                                {item?.mspDetails?.minimum_selling_price || ""}/ {item?.mspDetails?.unit?.shortcode || ""}
                              </div>
                            </td>
                            <td className="Status">
                              <div className="noWrap">
                                {handleCurrency()} {item?.price}
                              </div>
                            </td>
                            <td className="Status">
                              <div>
                                {item?.quantity ?? item?.quantity} {item?.unit}
                              </div>
                            </td>
                            <td
                              nowrap
                              className="Status noWrap">
                              {handleCurrency()}{" "}
                              {(item?.price ?? 0) *
                                (item?.quantity ?? 0) *
                                (item?.conversion ?? 0)}
                            </td>
                            <td>
                              <div className="noWrap">
                                {moment(item?.deliveryDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="action_payment addBuyer_submit-btn pd_0">
                                <img
                                  src={infoBtn}
                                  onClick={() => {
                                    navigate(
                                      `/buyerRequest/inprogress/${item._id}`
                                    );
                                  }}
                                  alt="infoIcon"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <td
                            colspan="11"
                            className="text_center">
                            No data available
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>

          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Address</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">MSP </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead text-center">Status</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead text-center">Action</div>
                  </th>
                </tr>
                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {closedList.length ? (
                      closedList.map((item) => {
                        return (
                          <tr>
                            <td className="noWrap">{item.orderId}</td>
                            <td className="phone_number noWrap">
                              {moment(item?.createdAt).format("DD-MM-YYYY")}
                            </td>
                            <td className="Status address_td">
                              <div>{item?.location}</div>
                            </td>
                            <td className="table_amount">{item?.product}</td>
                            <td className="table_amount noWrap">
                              {item?.veriety ?? "-"}
                            </td>
                            <td className="table_amount">{item?.grade}</td>
                            <td className="Status">
                              <div>
                                {item?.mspDetails?.minimum_selling_price || ""}/ {item?.mspDetails?.unit?.shortcode || ""}
                              </div>
                            </td>
                            <td className="Status">
                              <div className="noWrap">
                                {handleCurrency()} {item?.price}
                              </div>
                            </td>
                            <td className="Status">
                              <div>
                                {item?.quantity} {item?.unit}
                              </div>
                            </td>
                            <td
                              nowrap
                              className="Status noWrap">
                              {handleCurrency()}{" "}
                              {(item?.price ?? 0) *
                                (item?.quantity ?? 0) *
                                (item?.conversion ?? 0)}
                            </td>
                            <td>
                              <div className="noWrap">
                                {moment(item?.deliveryDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </div>
                            </td>
                            <td>
                              <div
                                className={`d-flex gap-2 text-center ${getStatusClassName(
                                  item?.result
                                )}`}>
                                {item?.result == "Completed"
                                  ? "Completed"
                                  : "Cancelled"}
                              </div>
                            </td>
                            <td>
                              <div className="action_payment addBuyer_submit-btn pd_0 text-center">
                                <img
                                  src={infoBtn}
                                  onClick={() => {
                                    navigate(
                                      `/buyerRequest/closed/${item._id}`
                                    );
                                  }}
                                  alt="infoIcon"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        <tr>
                          <td
                            colspan="11"
                            className="text_center">
                            No data available
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>
          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>

        {/* complet bid model */}
        <Modal
          show={isUpdateOpenModal}
          onHide={() => {
            setIsUpdateOpenModal(false);
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Bid</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to close bid?</Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark"
              variant="secondary"
              onClick={() => {
                setIsUpdateOpenModal(false);
              }}>
              No
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={handleUpdateWarehouseStatus}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Revice Buyer Request Off Canvas */}
        <Offcanvas
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
            reviceFormik.handleReset();
          }}
          placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Revise Request</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form
                action=""
                // className="payment_form"
                onSubmit={reviceFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Delivery Date</div>
                      <div className="input_field">
                        <input
                          className="input"
                          required
                          name="deliveryDate"
                          type="date"
                          placeholder="select date"
                          value={reviceFormik.values.deliveryDate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={reviceFormik.handleChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 flex_btn">
                  <button
                    className="btn btn-success"
                    type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* complet bid model */}
        <Modal
          show={isCancelModal}
          onHide={() => {
            setIsCancelModal(false);
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Bid</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to cancel bid?</Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark"
              variant="secondary"
              onClick={() => {
                setIsCancelModal(false);
              }}>
              No
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={handleUpdateWarehouseStatus}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
