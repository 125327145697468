import React from "react";
import Multiselect from "multiselect-react-dropdown";
import { useState, useEffect } from "react";
import { fetchWarehouses } from "../../action/location";

export const MultiselectDropdown = ({
  productId,
  distance,
  coordinates,
  onAddressSelect,
  selectedWarehouse,
}) => {
  const [buyerAddresses, setBuyerAddresses] = useState([]);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [list, setList] = useState([]);

  const fetchWarehouseAddress = async () => {
    const payload = {
      distance: Number(distance),
      coordinates: coordinates,
      warehouseId: selectedWarehouse?._id,
    };
    const response = await fetchWarehouses(productId, payload);
    if (response.status) {
      setList(response.list);

      const arr = response?.list?.map((item) => ({
        id: item?.address?._id,
        name: item?.address?.location
          ? item?.name + ", " + item?.address?.location?.address
          : item?.name + ", " + item?.address?.address,
        // +
        // " " +
        // Math.round(item?.distanceLocation) +
        // " KM",
      }));
      console.log("DJODJI", response);
      setBuyerAddresses(arr);
    }
  };

  const handleSelectChange = (selectedList, selectedItem) => {
    const addressId = selectedItem.id;
    const addressItem = list.find((item) => item?.address?._id === addressId);
    const warehouseId = addressItem?.address?.userId; // Assuming addressId exists in the data structure

    const updatedSelectedAddresses = [
      ...selectedAddresses,
      { warehouseId, addressId },
    ];
    setSelectedAddresses(updatedSelectedAddresses);
    onAddressSelect(updatedSelectedAddresses); // Pass selected IDs to the parent component
  };

  const handleRemoveChange = (selectedList, removedItem) => {
    const updatedSelectedAddresses = selectedAddresses.filter(
      (item) => item.addressId !== removedItem.id
    );
    setSelectedAddresses(updatedSelectedAddresses);
    onAddressSelect(updatedSelectedAddresses);
  };

  useEffect(() => {
    console.log("DJODID", coordinates);
    if (productId && coordinates) fetchWarehouseAddress();
  }, [productId, distance, coordinates]);
  return (
    <>
      <Multiselect
        options={buyerAddresses}
        displayValue="name"
        placeholder="Select options"
        showCheckbox={true}
        closeOnSelect={false}
        showArrow={true}
        onSelect={handleSelectChange}
        onRemove={handleRemoveChange}
      />
    </>
  );
};
