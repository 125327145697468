import { requestWithToken } from "../helper/helper";

const getModuleList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/module?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createModule = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/module`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchModuleDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/moduleDetails/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const updateModule = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/module/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getContestList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/contest?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createContest = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/contest`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchContestDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/contestDetails/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const updateContest = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/contest/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const updateContestStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/moduleStart/${id}`,
      {isDisplay: payload}
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchParticipantList = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/participant/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const postWinner = async (id) => {
  try {
    const response = await requestWithToken("POST", `/admin/winners/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchWinners = async (id) => {
  try {
    const response = await requestWithToken("GET", `/contest/winners/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteContest = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/contest/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  getModuleList,
  createModule,
  fetchModuleDetails,
  updateModule,
  getContestList,
  createContest,
  fetchContestDetails,
  updateContest,
  fetchParticipantList,
  postWinner,
  fetchWinners,
  deleteContest,
  updateContestStatus
};
