import React, { useState, useEffect } from "react";
import infoBtn from "../../assets/images/info-circle.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import {
  editWarehouseBid,
  fetchFarmertList,
  postCompleteBid,
} from "../../action/warehouse";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../components/bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import { handleCurrency } from "../../helper/helper";

export const FarmerBidList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [buyerReuqstList, setBuyerReuqstList] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationValue, setPaginationValue] = useState(10);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  //   const [id, setId] = useState("");
  const [warehouseId, setWarehouseId] = useState("");
  const [status, setStatus] = useState(4);
  //   const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [farmerBidId, setFarmerBidId] = useState("");
  const [editQty, setEditQty] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [acceptedQty, setAcceptedQty] = useState(0);
  const [unit, setUnit] = useState("");

  const fetchList = async () => {
    setIsLoading(true);

    const response = await fetchFarmertList(
      currentPage,
      paginationValue,
      id,
      status
    );
    if (response.status) {
      setBidList(response.data.list);
      setTotalRecords(response.data.count ?? response?.data?.list?.length);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, paginationValue, status]);

  // const handleConfirm = async () => {
  //   setEditQty(true);
  //   setIsOpen(true);
  //   console.log(editQty);
  // };

  const handleCancel = async () => {
    setEditQty(false);
  };

  const handleCheckboxChange = (item) => {
    setCheckedItems((prev) => {
      const isChecked = prev.some(
        (checkedItem) => checkedItem._id === item._id
      );
      if (isChecked) {
        return prev.filter((checkedItem) => checkedItem._id !== item._id);
      } else {
        return [
          ...prev,
          {
            userId: item.farmerId,
            _id: item._id,
            quantity: Number(item.acceptedQty),
            unit: item.reqUnit,
          },
        ];
      }
    });
  };

  const handleQuantityChange = (e, item) => {
    const newQuantity = e.target.value;
    if (Number(newQuantity) > Number(item.quantity)) {
      alert(`Quantity cannot exceed ${item.quantity}`);
      return;
    }
    setCheckedItems((prev) =>
      prev.map((checkedItem) =>
        checkedItem._id === item._id
          ? { ...checkedItem, quantity: newQuantity }
          : checkedItem
      )
    );
  };

  const handleSave = async () => {
    const payload = {
      orderData: checkedItems,
    };
    console.log(payload);
    const response = await postCompleteBid(id, payload);
    if (response.status) {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      navigate("/bidWarehouse");
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const handleUpdateBid = async () => {
    const payload = {
      farmerBidId: farmerBidId,
      status: 1, // status 1 for confirm bid
      acceptedQty: acceptedQty,
    };
    const response = await editWarehouseBid(warehouseId, payload);
    if (response.status) {
      fetchList();
      setIsUpdateOpenModal(false);
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  { name: "Warehous Requests", link: "/warehouseRequest/list" },
                  { name: "Bid List", link: "/farmerBid/:id" },
                ]}
              />
            </div>
          </div>
          {/* {status == 1 ? (
            <Button
              color="info"
              tag="a"
              onClick={() => {
                handleConfirm();
              }}
            >
              Complete Bid
            </Button>
          ) : (
            ""
          )} */}
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabList>
            <Tab
              onClick={() => {
                setStatus(4);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              New Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setStatus(1);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              {" "}
              Confirmed Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            {/* <Tab
              onClick={() => {
                setStatus(2);
              }}
            >
              Completed Request
              <div className="paymentHits">{totalRecords}</div>
            </Tab> */}
          </TabList>
          <TabPanel>
            {/* <div className="payout_table-box upi-mandate_table-box"> */}
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">User Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Address</div>
                  </th>

                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">MSP</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader ">
                    <div className="text-center">Action </div>
                  </th>
                </tr>
              </thead>

              {isLoading ? (
                <tr>
                  <td
                    colSpan="9"
                    className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : bidList.length > 0 ? (
                bidList.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{item?.orderId}</td>
                      <td className="phone_number">{item?.userName}</td>
                      <td className="Status">
                        {item?.bidDetails?.location?.address}
                        {/* {item?.location} */}
                        {/* <div className="noData"></div> */}
                      </td>
                      <td className="table_amount noWrap">{item?.product}</td>
                      <td className="table_amount ">{item?.variety ?? "-"}</td>
                      <td className="">{item?.grade}</td>
                      <td className="Status noWrap">
                        <div>
                          {item?.mspDetails?.minimum_selling_price
                            ? item?.mspDetails?.minimum_selling_price +
                              " " +
                              item?.mspDetails?.unit?.shortcode
                            : "-"}
                        </div>
                      </td>
                      <td className="Status">
                        <div>
                          {handleCurrency()} {item?.price ?? 0}
                        </div>
                      </td>
                      <td className="Status">
                        <div>{item?.quantity + " " + item?.unit}</div>
                      </td>
                      <td>
                        <div>
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.quantity ?? 0) *
                            (item?.conversion ?? 0)}
                        </div>
                      </td>
                      <td className="noWrap">
                        {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                      </td>
                      <td className="flex_btn">
                        <img
                          src={infoBtn}
                          onClick={() => {
                            navigate(`/farmerBid/details/${item._id}`);
                          }}
                        />{" "}
                        <Button
                          // isOutline={!darkModeStatus}
                          color="dark"
                          isLight="btn btn-primary"
                          tag="a"
                          onClick={() => {
                            setWarehouseId(item?.bidDetails?._id);
                            setFarmerBidId(item._id);
                            setIsUpdateOpenModal(true);
                            setAcceptedQty(item?.quantity);
                            setUnit(item?.unit);
                          }}>
                          Accept
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td
                      colSpan="9"
                      className="text_center">
                      No data available
                    </td>
                  </tr>
                </>
              )}
            </table>{" "}
            {/* </div> */}
          </TabPanel>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader"></th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">User Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Address</div>
                  </th>

                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Accepted Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Action</div>
                  </th>
                </tr>
              </thead>

              {isLoading ? (
                <tr>
                  <td
                    colSpan="9"
                    className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : bidList.length > 0 ? (
                bidList.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(item)}
                          checked={checkedItems.some(
                            (checkedItem) => checkedItem._id === item._id
                          )}
                        />
                      </td>
                      <td>{item?.orderId}</td>
                      <td className="phone_number">{item?.userName}</td>
                      <td className="Status">
                        {item?.bidDetails?.location?.address}
                        {/* {item?.location} */}
                        {/* <div className="noData"></div> */}
                      </td>
                      <td className="table_amount">{item?.product}</td>
                      <td className="table_amount ">{item?.variety ?? "-"}</td>
                      <td className="">{item?.grade}</td>

                      <td className="Status">
                        <div>
                          {handleCurrency()} {item?.price ?? 0}
                        </div>
                      </td>
                      <td className="Status">
                        {editQty ? (
                          <td>
                            <input
                              type="text"
                              style={{ border: "none" }}
                              value={
                                checkedItems.find(
                                  (checkedItem) => checkedItem._id === item._id
                                )?.acceptedQty || item?.acceptedQty
                              }
                              size={3}
                              max={item?.acceptedQty}
                              min={0}
                              onChange={(e) => handleQuantityChange(e, item)}
                            />
                          </td>
                        ) : (
                          <td>
                            {item?.acceptedQty} {item?.unit}
                          </td>
                        )}
                      </td>
                      <td>
                        <div>
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.acceptedQty ?? 0) *
                            (item?.conversion ?? 0)}
                        </div>
                      </td>
                      <td>{moment(item?.deliveryDate).format("DD-MM-YYYY")}</td>
                      <td>
                        <img
                          src={infoBtn}
                          onClick={() => {
                            navigate(`/farmerBid/details/${item._id}`);
                          }}
                          alt="infoIcon"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td
                      colSpan="15"
                      className="text_center">
                      No data available
                    </td>
                  </tr>
                </>
              )}
            </table>
            {bidList.length ? (
              <div className="m-4 text-end flex_btn">
                <Button
                  className="btn-success "
                  // isOutline={!darkModeStatus}
                  color="info"
                  isLight="btn btn-primary"
                  tag="a"
                  isDisable={checkedItems.length > 0 ? false : true}
                  onClick={() => {
                    handleSave();
                  }}>
                  Complete
                </Button>
                {"  "}
                <Button
                  className="btn-dark"
                  // isOutline={!darkModeStatus}
                  color="info"
                  isLight="btn btn-primary"
                  tag="a"
                  onClick={() => {
                    handleCancel();
                  }}>
                  Cancel
                </Button>
              </div>
            ) : (
              <></>
            )}
          </TabPanel>
          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>
        {/* modal to accept bid */}
        <Modal
          show={isUpdateOpenModal}
          onHide={() => {
            setIsUpdateOpenModal(false);
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Accept Bid?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to update the quantity?
            <div className="row">
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input_field">
                    <input
                      type="number"
                      className="input"
                      name="quantity"
                      placeholder="Enter quantity"
                      required
                      value={acceptedQty}
                      onChange={(e) => {
                        setAcceptedQty(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">{unit}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark"
              variant="secondary"
              onClick={() => {
                setIsUpdateOpenModal(false);
              }}>
              No
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={handleUpdateBid}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
