import { requestWithToken } from "../helper/helper";

const fetchGaon = async (pageNo, pageSize, search, sort, order, blockId) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);
    if (blockId) queryParams.push(`blockId=${blockId}`);
    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/admin/gaonList${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchGaonDetails = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/gaonDetails/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editGaon = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/gaon/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { fetchGaon, editGaon, fetchGaonDetails };
