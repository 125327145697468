import moment from "moment";
import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import CalendarIcon from "../../../assets/images/calendarwithDownarrow.svg";
import CalendarCloseIcon from "../../../assets/images/closeGrey.svg";

const DatePickerWithButton = ({ fetchFunc, setFunc }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleApply = async () => {
    setFunc(
      await fetchFunc(
        moment(selectionRange.startDate).format("YYYY-MM-DD"),
        moment(selectionRange.endDate).format("YYYY-MM-DD")
      )
    );
    setShowPicker(false);
  };

  return (
    <div className="custom_date_pick">
      <button onClick={togglePicker} className="date_btn">
        {showPicker ? (
          <img
            src={CalendarCloseIcon}
            className="cal_close_btn"
            alt="close Dates"
          />
        ) : (
          <img src={CalendarIcon} className="cal_open_btn" alt="Select Dates" />
        )}
      </button>

      {showPicker && (
        <div>
          <DateRange
            ranges={[selectionRange]}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            maxDate={new Date()}
          />
          <div className="benyf_btn">
            <button
              className="beny_left-btn "
              onClick={() => setShowPicker(false)}
            >
              Close
            </button>
            <button
              className={`beny_right-btn newClass active`}
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePickerWithButton;
