import React, { useState, useEffect } from "react";
import Pagination from "../../customComponents/pagination/paginationView";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import {
  fetchAdmin,
  fetchUserTypeList,
  deleteUserType,
  editUserTypeList,
  createUserType,
  getUserTypeDetails,
} from "../../action/userApi";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import trashIcon from "../../assets/images/trash-03.svg";

import editIcon from "../../assets/images/edit_btn.svg";
const UserControl = () => {
  const [userList, setUserList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [show, setShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState("");
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  const getAdmin = async () => {
    const res = await fetchAdmin();
    if (res.status) {
      setRoleData(res.data);
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  const getUserDepartment = async () => {
    setIsLoading(true);
    const response = await fetchUserTypeList(pageNo, pageSize, sort, order); // Adjust 10 to the desired number of items per page
    if (response.status) {
      setUserList(response.data.results);
      setTotalData(response.data.totalCount);
      setIsLoading(false);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  useEffect(() => {
    getUserDepartment();
  }, [pageNo, pageSize, sort, order]);

  const handleDeleteUserType = async () => {
    const response = await deleteUserType(id);
    if (response.status) {
      setPageNo(1);
      getUserDepartment();
      setShowDeleteModel(false);
      toast.custom(
        <SingleErrorMessage
          data={"User Dept. deleted Successfully"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const EditUserType = async (roleId) => {
    const respo = await getUserTypeDetails(roleId);
    console.log(respo);
    if (respo.status === 1) {
      editFormik.setFieldValue("name", respo.data.name);
      editFormik.setFieldValue("roleId", respo.data.roleId);
    }
  };

  const handleClose = () => {
    setShow(false);
    addFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const addFormik = useFormik({
    initialValues: {
      name: "",
      isActive: true,
      roleId: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const res = await createUserType(values);
      if (res.status) {
        setShow(false);
        getUserDepartment();
        handleClose();
        resetForm();
        toast.custom(
          <SingleErrorMessage
            data={"User Department added successfully"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const editFormik = useFormik({
    initialValues: {
      isActive: true,
    },
    onSubmit: async (values) => {
      const res = await editUserTypeList(id, values);
      if (res.status) {
        setIsEditMode(false);
        getUserDepartment();
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });
  return (
    <>
      <div className="payout_inner_content">
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left">
              <div className="payout_data_ttl1">User Department</div>
            </div>

            <Button
              color="info"
              tag="a"
              type="button"
              onClick={() => {
                // setIsOpenCanvas(true)
                handleShow();
              }}
              // to="/user/add"
            >
              Add New Dept.
            </Button>
          </div>
        </div>
        <div className="tab_box">
          <Tabs>
            <TabPanel>
              <div className="">
                <table className="payout_table upi-mandate_table">
                  <thead>
                    <tr className="tableHead">
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Name</div>
                      </th>

                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="4">
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {userList?.length > 0 ? (
                          userList?.map((item) => (
                            <tr key={item?._id}>
                              <td>{item?.name}</td>
                              <td>
                                <img
                                  src={trashIcon}
                                  onClick={() => {
                                    // RemoveUserType(item._id);
                                    setId(item._id);
                                    setShowDeleteModel(true);
                                  }}
                                  alt="trash icon"
                                />
                                <img
                                  src={editIcon}
                                  onClick={() => {
                                    setId(item._id);
                                    EditUserType(item._id);
                                    setIsEditMode(true);
                                  }}
                                  alt="edit icon"
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text_center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </Tabs>
          {userList?.length > 0 && (
            <>
              <Pagination
                currentPage={pageNo}
                totalCount={totalData}
                pageSize={pageSize}
                onPageChange={(page) => setPageNo(page)}
                changePageSize={(page) => {
                  setPageSize(page);
                  setPageNo(1);
                }}
              />
            </>
          )}

          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Add New Department</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="add_buyer_content">
                <form onSubmit={addFormik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input-box">
                        <div className="input-label">Department Name</div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            name="name"
                            placeholder="Enter name"
                            value={addFormik.values.name || ""}
                            onChange={addFormik.handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="input-box">
                        <div className="input-label">Select Role</div>
                        <div className="input_field">
                          <select
                            className="input"
                            size="sm"
                            ariaLabel="Role"
                            onChange={(e) => {
                              addFormik.setFieldValue("roleId", e.target.value);
                            }}
                          >
                            <option value="">Select Role</option>
                            {roleData.map((i) => (
                              <option key={i._id} value={i._id}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addBuyer_submit-btn">
                    <button className="btn btn-success" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas
            show={isEditMode}
            onHide={() => {
              setIsEditMode(false);
              addFormik.handleReset();
            }}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Edit Department</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="add_buyer_content">
                <form onSubmit={editFormik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input-box">
                        <div className="input-label">Department Name</div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            name="name"
                            placeholder="Enter name"
                            value={editFormik.values?.name}
                            onChange={editFormik.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">Select Role</div>
                        <div className="input_field">
                          <select
                            name="roleId"
                            className="input"
                            size="sm"
                            ariaLabel="Role"
                            value={editFormik.values["roleId"]}
                            onChange={editFormik.handleChange}
                          >
                            <option value="">Select Role</option>
                            {roleData.map((i) => (
                              <option key={i._id} value={i._id}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="addBuyer_submit-btn">
                    <button className="btn btn-success" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <Modal
            show={showDeleteModel}
            onHide={() => {
              setShowDeleteModel(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>User Department</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this User Dept.?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowDeleteModel(false);
                }}
              >
                No
              </Button>
              <Button
                className="btn-dark"
                variant="primary"
                onClick={() => {
                  handleDeleteUserType();
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UserControl;
