import { requestWithToken } from "../helper/helper";

const fetchRequestCount = async (startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/dashboard/buyerRequest?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchTotalPrice = async (startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/dashboard/buyerRequestTotalPrice?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchOrderQuantity = async (startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/dashboard/buyerRequestOrderQuentity?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchOrderAmount = async (startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/dashboard/buyerRequestOrderAmount?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchRevenueAPI = async (startDate, endDate) => {
  try {
    const params = new URLSearchParams();

    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate); 
    const response = await requestWithToken(
      "GET",
      `/dashboard/revenue?${params.toString()}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchRecentAndOngoingRequests = async (startDate, endDate, type, deliveryDate, pageNo, limit) => {
  try {
    const params = new URLSearchParams();

    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    if (type) params.append("type", type);
    if (deliveryDate) params.append("delivery_date", deliveryDate);
    if (pageNo) params.append("pageNo", pageNo);
    if (limit) params.append("pageSize", limit);
    

    const response = await requestWithToken("GET", `/dashboard/buyerRequestList?${params.toString()}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchDashboardBidDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/dashboard/details/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchRequestCount,
  fetchTotalPrice,
  fetchOrderQuantity,
  fetchOrderAmount,
  fetchRecentAndOngoingRequests,
  fetchRevenueAPI,
  fetchDashboardBidDetails
};
