import { requestWithToken } from "../helper/helper";
const fetchBannerList = async (currentPage, perPage) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/banner?pageNo=${currentPage}&pageSize=${perPage}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deletebanner = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/banner/${id}`);

    return response;
  } catch (err) {
    return err.response.data;
  }
};
export { fetchBannerList, deletebanner };
