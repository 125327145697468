import { requestWithToken } from "../helper/helper";

const fetchNewFarmerRequestList = async (currentPage, perPage) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/farmerRequest/list?pageNo=${currentPage}&pageSize=${perPage}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const bidFarmerRequest = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/farmerRequest`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const InprogressRequests = async (currentPage, perPage, status) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/farmerRequest/warehouse/list?pageNo=${currentPage}&pageSize=${perPage}&status=${status}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const completeBid = async (id) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/farmerRequest/complete/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchRquestDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/farmerRequest/details/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchReject = async (id, status, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/farmerRequest/warehouse/status/${id}?status=${status}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchNewFarmerRequestList,
  bidFarmerRequest,
  InprogressRequests,
  completeBid,
  fetchRquestDetails,
  fetchReject,
};
