import React, { useEffect, useState } from "react";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import { deletebanner, fetchBannerList } from "../../action/banner";
import trashIcon from "../../assets/images/trash-03.svg";
import editIcon from "../../assets/images/edit_btn.svg";
import plusWhite from "../../assets/images/plus-white.svg";
import Button from "../../components/bootstrap/Button";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { SingleErrorMessage } from "../singleErrorMessage";
import { Modal, Offcanvas } from "react-bootstrap";
import toast from "react-hot-toast";
const Bannerlist = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [banner_Id, setBanner_Id] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);

  const getBannerList = async () => {
    setIsLoading(true);
    const response = await fetchBannerList(currentPage, paginationValue);
    if (response.status) {
      console.log(response.data);
      setIsLoading(false);
      setBannerList(response.data);
      setTotalRecords(response.data?.length);
    } else {
      console.log("not called");
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const deletebannerdata = async () => {
    console.log("no call sssss");
    console.log("no call sssss", banner_Id);
    const response = await deletebanner(banner_Id);
    if (response.status) {
      console.log("sssss");
      toast.custom(
        <SingleErrorMessage
          data={"User Dept. deleted Successfully"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      console.log("no   sssss");
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  useEffect(() => {
    getBannerList();
  }, [currentPage, paginationValue]);

  return (
    <div
      className="payout_data-content"
      style={{ height: "100%" }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Banner" }]} />
            </div>
          </div>
          <Button
            color="info"
            tag="a"
            onClick={() => {
              setIsOpenModal(true);
            }}>
            Create New
            <img
              src={plusWhite}
              alt=""
              className="payout_down"
            />
          </Button>
        </div>
      </div>
      <table className="payout_table upi-mandate_table">
        <thead>
          <tr className="tableHead">
            <th className="thHeader">
              <div className="payoutHead hndIcon">Title</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon"> Date</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Address</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Action</div>
            </th>
          </tr>
        </thead>

        {isLoading ? (
          <tr>
            <td
              colSpan="10"
              className="text-center">
              <Loader />
            </td>
          </tr>
        ) : (
          <>
            {bannerList?.length > 0 ? (
              bannerList?.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.title}</td>
                    <td>{item.desc}</td>
                    <td>
                      <div className="bannerImageList">
                        <img
                          src={
                            process.env.REACT_APP_S3_URL + "/" + item.assets.src
                          }
                          alt="banner"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_btn-box justify-content-lg-start gap-2">
                        <div className="flex_btn">
                          <img
                            alt="editIcon"
                            src={editIcon}
                            onClick={() => {}}
                          />
                        </div>
                        <div
                          className="flex_btn"
                          onClick={() => {
                            setShowDeleteModel(true);
                            setBanner_Id(item._id);
                          }}>
                          <img
                            src={trashIcon}
                            alt="trashIcon"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
                <tr>
                  <td
                    colspan="11"
                    className="text_center">
                    No data available
                  </td>
                </tr>
              </>
            )}
          </>
        )}
      </table>
      <Pagination
        currentPage={currentPage}
        totalCount={totalRecords}
        pageSize={paginationValue}
        onPageChange={(page) => setCurrentPage(page)}
        changePageSize={(page) => {
          setPaginationValue(page);
          setCurrentPage(1);
        }}
      />
      <Offcanvas
        show={isOpenModal}
        onHide={() => {
          setIsOpenModal(false);
        }}
        placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Revise Request</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="add_buyer_content">
            <form
              action=""
              // className="payment_form"
              onSubmit="">
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">Delivery Date</div>
                    <div className="input_field">
                      <input
                        className="input"
                        required
                        name="deliveryDate"
                        type="date"
                        placeholder="select date"
                        value=""
                        onChange=""
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex_btn">
                <button
                  className="btn btn-success"
                  type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={showDeleteModel}
        onHide={() => {
          setShowDeleteModel(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>User Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this User Dept.?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowDeleteModel(false);
            }}>
            No
          </Button>
          <Button
            className="btn-dark"
            variant="primary"
            onClick={() => {
              deletebannerdata();
            }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Bannerlist;
