import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { formatAmount } from "../../helper/helper";
export const OrderChart = ({ data }) => {
  if (
    !data ||
    !data.x ||
    data.x.length === 0 ||
    !data.y ||
    data.y.length === 0
  ) {
    return (
      <div
        style={{
          height: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No data found
      </div>
    );
  }
  const option = {
    color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
    grid: {
      left: "0",
      right: "40",
      bottom: "3%",
      containLabel: true,
    },

    xAxis: [
      {
        type: "category",
        data: data.x,
        show: true, // Hide X-axis labels
        boundaryGap: false,
      },
    ],
    yAxis: [
      {
        type: "value",
        show: true,
        axisLabel: {
          formatter: (value) =>  formatAmount(value),
        },
      },
    ],
    series: [
      {
        name: "Line 1",
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          color: "#3B8130",
          width: 3,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#3B813033",
            },
            {
              offset: 1,
              color: "#ffffff",
            },
          ]),
        },
        emphasis: {
          focus: "series",
        },
        data: data.y,
      },
    ],
  };
  return (
    <ReactECharts option={option} style={{ height: 400, width: "100%" }} />
  );
};
