import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import { fetchWarehouseBidList, acceptBid } from "../../action/warehouse";
import Button from "../../components/bootstrap/Button";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Pagination from "../../customComponents/pagination/paginationView";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { completeBuyerBid } from "../../action/buyer";
import { SingleErrorMessage } from "../singleErrorMessage";
import { handleCurrency } from "../../helper/helper";

export const WarehouseBidToBuyerList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [bidList, setBidList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [warehouseId, setWarehouseId] = useState("");
  const [status, setStatus] = useState(4);
  const [acceptedQty, setAcceptedQty] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const [unit, setUnit] = useState("");

  const fetchList = async () => {
    const response = await fetchWarehouseBidList(
      currentPage,
      paginationValue,
      id,
      status
    );
    if (response.status) {
      setBidList(response.data.list);
      setTotalRecords(response.data.count);
    }
  };

  useEffect(() => {
    fetchList();
  }, [currentPage, paginationValue, status]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAcceptWarehouseUpdate = async () => {
    const payload = {
      warehouseBidId: warehouseId,
      status: 7,
      acceptedQty: acceptedQty,
    };
    const response = await acceptBid(id, payload);
    if (response.status) {
      fetchList();
      setShow(false);
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      // navigate("/buyerReqest/list");
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const handleSave = async () => {
    const payload = {
      warehouseIds: checkedItems,
      status: 1,
    };
    const response = await completeBuyerBid(id, payload);
    if (response.status) {
      toast.custom(
        <SingleErrorMessage
          data={"Bid confirmed successfully"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      navigate("/buyerReqest/list");
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const handleCancel = async () => {
    setCheckedItems([]);
  };

  const handleCheckboxChange = (itemId) => {
    if (checkedItems.includes(itemId)) {
      // If the item ID is already checked, remove it from the array
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    } else {
      // If the item ID is not checked, add it to the array
      setCheckedItems([...checkedItems, itemId]);
    }
    console.log(checkedItems);
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb
                paths={[
                  { name: "Buyer Requests", link: "/buyerReqest/list" },
                  { name: "Bid List", link: "/buyerRequest/bid/list/:id" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabList>
            <Tab
              onClick={() => {
                setStatus(4);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              New Requests
            </Tab>
            <Tab
              onClick={() => {
                setStatus(7);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              {" "}
              Accepted Requests
            </Tab>
          </TabList>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">User Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Address</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">MSP</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Action </div>
                    </th>
                  </tr>
                </thead>
                {bidList.length > 0 ? (
                  bidList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item?.bidDetails?.orderId}</td>
                        <td className="phone_number">
                          {item?.userDetails?.name}
                        </td>
                        <td className="Status">
                          {item?.warehouseAddress?.location?.address}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount noWrap">{item?.product}</td>
                        <td className="table_amount noWrap">
                          {item?.veriety ?? "-"}
                        </td>
                        <td>
                          <div className="text-center">
                            {item?.mspDetails?.minimum_selling_price
                              ? item?.mspDetails?.minimum_selling_price +
                                "/" +
                                item?.mspDetails?.unit?.shortcode
                              : "-"}
                          </div>
                        </td>
                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status noWrap">
                          <div>
                            {item?.quantity} {item?.unit ? item?.unit : "-"}
                          </div>
                        </td>
                        <td
                          nowrap
                          className="Status noWrap">
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.quantity ?? 0) *
                            (item?.conversion ?? 0)}
                        </td>
                        <td className="noWrap">
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <div className="action_payment">
                            <Button
                              color="info"
                              tag="a"
                              className="flex_btn ml-1 btn-success"
                              onClick={() => {
                                setAcceptedQty(item?.quantity);
                                setUnit(item?.unit);
                                setWarehouseId(item?._id);
                                handleShow();
                              }}>
                              Accept Bid
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan="10"
                        className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="scroll_v">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader"></th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">User Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Address</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">MSP </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Accepted Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                  </tr>
                </thead>
                {bidList.length > 0 ? (
                  bidList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(item._id)}
                            checked={checkedItems.includes(item._id)}
                          />
                        </td>
                        <td>{item?.bidDetails?.orderId}</td>
                        <td className="phone_number">
                          {item?.userDetails?.name}
                        </td>
                        <td className="Status">
                          {item?.warehouseAddress?.location?.address}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount noWrap">{item?.product}</td>
                        <td className="table_amount noWrap">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="Status">
                              <div>
                                {item?.mspDetails?.minimum_selling_price || ""}/ {item?.mspDetails?.unit?.shortcode || ""}
                              </div>
                            </td>
                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>

                        <td className="Status noWrap">
                          <div>{item?.acceptedQty + " " + item?.unit}</div>
                        </td>
                        <td
                          nowrap
                          className="Status noWrap">
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.acceptedQty ?? 0) *
                            (item?.conversion ?? 0)}
                        </td>
                        <td className="noWrap">
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan="10"
                        className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </table>{" "}
              {bidList.length ? (
                <div className="m-4 text-end flex_btn">
                  <Button
                    className="btn-success "
                    color="info"
                    isLight="btn btn-primary"
                    tag="a"
                    isDisable={checkedItems.length > 0 ? false : true}
                    onClick={() => {
                      handleSave();
                    }}>
                    Confirm
                  </Button>
                  {"  "}
                  <Button
                    className="btn-dark"
                    color="info"
                    isLight="btn btn-primary"
                    tag="a"
                    onClick={() => {
                      handleCancel();
                    }}>
                    Cancel
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </TabPanel>

          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>

        <Modal
          show={show}
          onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Accept Bid?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to update the quantity?
            <div className="row">
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input_field">
                    <input
                      type="number"
                      className="input"
                      name="quantity"
                      placeholder="Enter quantity"
                      required
                      value={acceptedQty}
                      onChange={(e) => {
                        setAcceptedQty(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-box">{unit}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark"
              variant="secondary"
              onClick={handleClose}>
              No
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={handleAcceptWarehouseUpdate}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
