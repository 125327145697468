import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import { getunits } from "../../action/users";
import { useFormik } from "formik";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "../../components/bootstrap/Button";
import {
  handleBuyerTitle,
  handleCurrency,
  handleKeyDown,
} from "../../helper/helper";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import {
  bidFarmerRequest,
  completeBid,
  fetchNewFarmerRequestList,
  fetchReject,
  InprogressRequests,
} from "../../action/farmerProducts";
import { fetchAddresses } from "../../action/location";
import Modal from "react-bootstrap/Modal";
import infoBtn from "../../assets/images/info-circle.svg";
import { useNavigate } from "react-router-dom";

export const FarmerProducts = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationValue, setPaginationValue] = useState(10);
  const [status, setStatus] = useState("");
  const [showBidCanvas, setShowBidCanvas] = useState(false);
  const [units, setUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ongoingList, setOngoingList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [parentId, setParentId] = useState("");
  const [rejectId, setRejectId] = useState("");
  const [title, setTitle] = useState("");
  const [rejectStatus, setRejectStatus] = useState("");
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const [showrejectModel, setShowrejectModel] = useState(false);

  const fetchNewRequestList = async () => {
    setIsLoading(true);
    const response = await fetchNewFarmerRequestList(
      currentPage,
      paginationValue
    );
    if (response.status) {
      console.log("response", response);
      setList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const fetchWarehouseInprogressList = async (statusValue) => {
    setIsLoading(true);
    const response = await InprogressRequests(
      currentPage,
      paginationValue,
      statusValue
    );
    if (response.status) {
      setOngoingList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const addBidFormik = useFormik({
    initialValues: {
      parentId: "",
      price: 0,
      deliveryDate: "",
      quantity: "",
      unitId: "",
      addressId: "",
    },
    onSubmit: async (values) => {
      console.log("onsubmi", values);
      values.parentId = parentId;
      const response = await bidFarmerRequest(values);
      if (response.status) {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        fetchNewRequestList();
        setShowBidCanvas(false);
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        setShowBidCanvas(false);
      }
      addBidFormik.handleReset();
    },
  });

  const fetchUnits = async () => {
    const response = await getunits();
    if (response.status) {
      setUnits(response.data);
    }
  };

  const fetchDeliveryAddress = async () => {
    const response = await fetchAddresses();
    if (response.status) {
      console.log("address", response);
      setAddressList(response.list);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleUpdateBid = async () => {
    const response = await completeBid(parentId);
    if (response.status) {
      fetchWarehouseInprogressList(status);
      setIsUpdateOpenModal(false);
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };
  const handleReject = async () => {
    const payload = {
      status: rejectStatus,
    };
    console.log("called1", rejectId);
    const response = await fetchReject(rejectId, rejectStatus, payload);
    if (response.status) {
      console.log("called", rejectId);
      setShowrejectModel(false);
      fetchWarehouseInprogressList(status);
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };
  console.log("addBidformik", addBidFormik);
  useEffect(() => {
    fetchNewRequestList();
    fetchUnits();
    fetchDeliveryAddress();
  }, [currentPage, paginationValue]);

  useEffect(() => {
    if (status) fetchWarehouseInprogressList(status);
  }, [currentPage, paginationValue, status]);

  const statusColorMapping = {
    6: "failed",
    2: "success",
  };
  const getStatusClassName = (status) => {
    return statusColorMapping[status] || "";
  };

  const getMSP = (price, unit) => {
    if (price) {
      return `${price} / ${unit};`;
    }

    return "--";
  };
  function calculatePrice(price, accptqty) {
    let totalPrice = (price ?? 0) * (accptqty ?? 0);
    console.log(totalPrice);
    return totalPrice;
  }
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              Request List
              {/* <div className="hits">1321 Hits</div> */}
            </div>
            {/* <div className="payout_data-desc">
              Keep track of your daily payouts.
            </div> */}
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabList>
            <Tab
              onClick={() => {
                fetchNewRequestList();
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              New Requests
            </Tab>
            <Tab
              onClick={() => {
                setStatus(4);
                fetchWarehouseInprogressList(4);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              Applied Requests
            </Tab>
            <Tab
              onClick={() => {
                setStatus(1);
                fetchWarehouseInprogressList(1);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              Confirmed Request
            </Tab>
            <Tab
              onClick={() => {
                setStatus(3);
                fetchWarehouseInprogressList(3);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              Rejected Request
            </Tab>
            <Tab
              onClick={() => {
                setStatus(2);
                fetchWarehouseInprogressList(2);
                setCurrentPage(1);
                setPaginationValue(10);
              }}>
              Closed Request
            </Tab>
          </TabList>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Request Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Warehouse Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead"> Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Maximum Selling Price</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead text-center">Action </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody> */}

                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : list.length > 0 ? (
                  list.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="phone_number noWrap">
                          {item?.orderId ?? "-"}
                        </td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status">{item?.userDetails?.name}</td>
                        <td className="Status">{item?.gaons?.name}</td>
                        <td className="table_amount">
                          {
                            item?.product[0]?.verietyDetails[0]?.productDetails
                              ?.name.en
                          }
                        </td>

                        <td className="table_amount ">
                          {item?.product[0]?.verietyDetails[0]?.name.en}
                        </td>

                        <td className="">{item?.grade}</td>
                        <td className="table_amount ">
                          {getMSP(
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.minimum_selling_price,
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.unit?.shortcode
                          )}
                        </td>

                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>

                        <td className="Status">
                          <div>
                            {item?.quantity}
                            {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {handleCurrency()}
                            {calculatePrice(
                              item?.price,
                              item?.totalAcceptedBidQuantity
                            )}{" "}
                            {/* {(item?.price ?? 0) *
                              (item?.quantity ?? 0) *
                              (item?.conversion ?? 0)} */}
                          </div>
                        </td>
                        <td>
                          <div className="flex_btn text-center">
                            <img
                              src={infoBtn}
                              onClick={() => {
                                navigate(
                                  `/farmer/products/details/${item._id}`
                                );
                              }}
                              alt="info btn"
                            />
                            <Button
                              color="dark"
                              className="noWrap"
                              isLight="btn btn-primary"
                              tag="a"
                              onClick={() => {
                                // let id = item?.gaon?._id
                                //   ? item?.gaon?._id
                                //   : item?.warehouseDefaultAddress[0]?._id;
                                // // setAddressId(id);
                                // // setId(item._id);
                                setParentId(item?._id);
                                setShowBidCanvas(true);
                                addBidFormik.setValues({
                                  parentId: item?._id,
                                  price: item?.price,
                                  quantity: item?.quantity,
                                  unitId: item?.unitId,
                                  deliveryDate: moment(
                                    item?.deliveryDate
                                  ).format("YYYY-MM-DD"),
                                  addressId: item.addressDetails._id,
                                });
                              }}>
                              Apply Bid
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan="10"
                        className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
                {/* </tbody> */}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Request Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Warehouse Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead"> Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Maximum Selling Price</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Reason </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Action </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody> */}

                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : ongoingList.length > 0 ? (
                  ongoingList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="phone_number noWrap">
                          {item?.farmerDetails?.orderId ?? "-"}
                        </td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status">{item?.userDetails?.name}</td>
                        <td className="Status">
                          {item?.farmerDetails?.gaonDetails?.name}
                        </td>
                        <td className="table_amount">
                          {
                            item?.product[0]?.verietyDetails[0]?.productDetails
                              ?.name.en
                          }
                        </td>

                        <td className="table_amount ">
                          {item?.product[0]?.verietyDetails[0]?.name.en}
                        </td>

                        <td className="">{item?.grade}</td>
                        <td>
                          {getMSP(
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.minimum_selling_price,
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.unit?.shortcode
                          )}
                        </td>
                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status">
                          <div>
                            {item?.quantity}
                            {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {handleCurrency()}{" "}
                            {(item?.price ?? 0) *
                              (item?.quantity ?? 0) *
                              (item?.conversion ?? 0)}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div>{item?.reasonDetails?.title}</div>
                        </td>
                        <td>
                          {item?.status == 8 ? (
                            <div className="comform_brn-box">
                              <div
                                class="btn btn-light-dark noWrap"
                                role="button"
                                onClick={() => {
                                  setShowrejectModel(true);
                                  setRejectStatus(3);
                                  setRejectId(item._id);
                                  setTitle("Reject");
                                }}>
                                Reject
                              </div>
                              <div
                                class="btn btn-light-dark noWrap"
                                role="button"
                                onClick={() => {
                                  setShowrejectModel(true);
                                  setRejectStatus(9);
                                  setRejectId(item._id);
                                  setTitle("Accept");
                                }}>
                                Accept
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan="10"
                        className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
                {/* </tbody> */}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Request Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Warehouse Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead"> Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Maximum Selling Price</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Action </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody> */}

                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : ongoingList.length > 0 ? (
                  ongoingList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="phone_number noWrap">
                          {item?.farmerDetails?.orderId ?? "-"}
                        </td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status">{item?.userDetails?.name}</td>
                        <td className="Status">
                          {item?.farmerDetails?.gaonDetails?.name}
                        </td>
                        <td className="table_amount">
                          {
                            item?.product[0]?.verietyDetails[0]?.productDetails
                              ?.name.en
                          }
                        </td>

                        <td className="table_amount ">
                          {item?.product[0]?.verietyDetails[0]?.name.en}
                        </td>

                        <td className="">{item?.grade}</td>
                        <td>
                          {getMSP(
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.minimum_selling_price,
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.unit?.shortcode
                          )}
                        </td>
                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status">
                          <div>
                            {item?.quantity}
                            {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {handleCurrency()}{" "}
                            {(item?.price ?? 0) *
                              (item?.quantity ?? 0) *
                              (item?.conversion ?? 0)}
                          </div>
                        </td>
                        <td>
                          <Button
                            color="dark"
                            className="noWrap"
                            isLight="btn btn-primary"
                            tag="a"
                            onClick={() => {
                              setParentId(item._id);
                              setIsUpdateOpenModal(true);
                            }}>
                            Complete Bid
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan="10"
                        className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
                {/* </tbody> */}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Request Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Warehouse Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead"> Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Maximum Selling Price</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Reason </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody> */}

                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : ongoingList.length > 0 ? (
                  ongoingList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="phone_number noWrap">
                          {item?.farmerDetails?.orderId ?? "-" ?? "-"}
                        </td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status">{item?.userDetails?.name}</td>
                        <td className="Status">
                          {item?.farmerDetails?.gaonDetails?.name}
                        </td>
                        <td className="table_amount">
                          {
                            item?.product[0]?.verietyDetails[0]?.productDetails
                              ?.name.en
                          }
                        </td>

                        <td className="table_amount ">
                          {item?.product[0]?.verietyDetails[0]?.name.en}
                        </td>

                        <td className="">{item?.grade}</td>
                        <td>
                          {getMSP(
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.minimum_selling_price,
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.unit?.shortcode
                          )}
                        </td>
                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status">
                          <div>
                            {item?.quantity}
                            {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {handleCurrency()}{" "}
                            {(item?.price ?? 0) *
                              (item?.quantity ?? 0) *
                              (item?.conversion ?? 0)}
                          </div>
                        </td>
                        <td>
                          <div>{item?.remark ?? "-"}</div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan="10"
                        className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
                {/* </tbody> */}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Request Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Warehouse Name</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead"> Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Maximum Selling Price</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Status </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody> */}

                {isLoading ? (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : ongoingList.length > 0 ? (
                  ongoingList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="phone_number noWrap">
                          {item?.farmerDetails?.orderId ?? "-" ?? "-"}
                        </td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status">{item?.userDetails?.name}</td>
                        <td className="Status">
                          {item?.farmerDetails?.gaonDetails?.name}
                        </td>
                        <td className="table_amount">
                          {
                            item?.product[0]?.verietyDetails[0]?.productDetails
                              ?.name.en
                          }
                        </td>

                        <td className="table_amount ">
                          {item?.product[0]?.verietyDetails[0]?.name.en}
                        </td>

                        <td className="">{item?.grade}</td>
                        <td>
                          {getMSP(
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.minimum_selling_price,
                            item?.product[0]?.verietyDetails[0]?.mspDetails
                              ?.unit?.shortcode
                          )}
                        </td>
                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status">
                          <div>
                            {item?.quantity}
                            {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {handleCurrency()}{" "}
                            {(item?.price ?? 0) *
                              (item?.quantity ?? 0) *
                              (item?.conversion ?? 0)}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`d-flex gap-2 ${getStatusClassName(
                              item?.status
                            )}`}>
                            {item?.status == 2 ? "Completed" : "Cancelled"}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan="10"
                        className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
                {/* </tbody> */}
              </table>{" "}
            </div>
          </TabPanel>
          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>

        {/* off canvas to create warehouse new request to farmer*/}
        <Offcanvas
          show={showBidCanvas}
          onHide={() => {
            setShowBidCanvas(false);
            addBidFormik.handleReset();
          }}
          placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Apply Bid</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form
                action=""
                onSubmit={addBidFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Delivery Address
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <Select
                          className="input"
                          value={addBidFormik?.values?.addressId || ""}
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "addressId",
                              e.target.value
                            );
                          }}
                          required>
                          {/* Default option to show when no addressId is selected */}
                          {/* <Option value={addBidFormik?.values?.addressId}>
                            {addBidFormik?.values?.addressId}
                          </Option> */}
                          {/* {addBidFormik?.values?.addressId.length > 0 ?return <Option value="">{addBidFormik.dirty.values?.addressId}</Option> : null} */}

                          {/* Dynamically render addresses from addressList */}
                          {addressList.map((location) => (
                            <Option
                              key={location._id}
                              value={location._id}>
                              {location.address}, {location.postalCode}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Quantity
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter quantity"
                          id="beneficiary"
                          name="quantity"
                          min="1"
                          max="100000000"
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "quantity",
                              Number(e.target.value)
                            );
                          }}
                          value={addBidFormik.values.quantity}
                          required
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Unit
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <Select
                          className="input"
                          value={addBidFormik?.values?.unitId ?? ""}
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "unitId",
                              e.target.value
                            );
                          }}
                          required>
                          <Option value="">Select Unit</Option>
                          {units.map((unit) => {
                            return (
                              <Option
                                key={unit._id}
                                id={unit._id}
                                value={unit._id}>
                                {unit.shortcode}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Price
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter price"
                          id="beneficiary"
                          name="price"
                          min="1"
                          max="1000000"
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "price",
                              Number(e.target.value)
                            );
                          }}
                          value={addBidFormik.values.price}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Delivery Date
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          required
                          name="deliveryDate"
                          className="input"
                          type="date"
                          placeholder="select date"
                          value={addBidFormik.values.deliveryDate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={addBidFormik.handleChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex_btn mt-3 ">
                  <button
                    className="btn btn-success"
                    type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={isUpdateOpenModal}
          onHide={() => {
            setIsUpdateOpenModal(false);
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Complete Bid</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to complete this request?</Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark"
              variant="secondary"
              onClick={() => {
                setIsUpdateOpenModal(false);
              }}>
              No
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={handleUpdateBid}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showrejectModel}
          onHide={() => {
            setShowrejectModel(false);
          }}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to {title} this request?</Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark"
              variant="secondary"
              onClick={() => {
                setShowrejectModel(false);
              }}>
              No
            </Button>
            <Button
              className="btn-success"
              variant="primary"
              onClick={handleReject}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
