import {
  createBrowserRouter,
  createRoutesFromElements,
  BrowserRouter,
  Link,
  Route,
  Routes,
  RouterProvider,
} from "react-router-dom";

import { Login } from "../pages/login";
import { SignUp } from "../pages/signUp";
import { DashboardLayout } from "../pages/dashboardLayout";
import { ProtectedRoute } from "../routes/protectedRoutes";
import { PageNotFound } from "../pages/404Page";
import { ComingSoonPage } from "../pages/comingSoon";
import { UpiHome } from "../pages/upiMandate/upiHome";
import { Report } from "../pages/reports/report";
import { BuyerRequestList } from "../pages/Buyer/buyerRequestList";
import { WarehouseBidToBuyerList } from "../pages/Buyer/warehouseBidList";
import { WarehouseBidList } from "../pages/warehouse/bidWarehouseList";
import { RequestToFarmerList } from "../pages/warehouse/requestToFarmer";
import { FarmerBidList } from "../pages/warehouse/bidFarmerList";
import { AddBuyerRequest } from "../pages/Buyer/addBuyerRequest";
import Gaon from "../pages/gaon/Gaon";
import ShowGaon from "../pages/gaon/ShowGaon";
import UserList from "../pages/users/UserList";
import UserControl from "../pages/users/UserControl"; /// User Control
import CategoryListPage from "../pages/category/CategoryListPage";
import EditeGaon from "../pages/gaon/EditeGaon";
import { Dashboard } from "../pages/Dashboard/dashboard";
import { DetailsPage } from "../pages/warehouse/farmerBidDetails";
import ModuleList from "../pages/module/moduleList";
import ModuleAdd from "../pages/module/moduleAdd";
import ModuleEdit from "../pages/module/moduleEdit";
import ContestList from "../pages/module/contest/contestList";
import ContestAdd from "../pages/module/contest/contestAdd";
import ContestEdit from "../pages/module/contest/contestEdit";
import GetUnits from "../pages/unitLIst/units";
import MinimumSellingPrice from "../pages/minimumSellingPrice/minimumSellingPrice";
import { BuyerConfirmRequestList } from "../pages/Buyer/confirmWarehouseRequests";
import GradeList from "../pages/Grade/gradeList";
import { AddReviceBuyerRequest } from "../pages/Buyer/reviceRequest";
import { BuyerInprogressRequestList } from "../pages/Buyer/inprogressRequestsList";
import { BuyerClosedequestList } from "../pages/Buyer/completeWarehouseRequests";
import UserProfile from "../pages/users/profile";
import ProductList from "../pages/category/productList";
import CreateUser from "../pages/users/createUser";
import { Notiifcations } from "../pages/Notifications/notification";
import { FarmerProducts } from "../pages/warehouse/farmerProducts";
import { FarmerProductDetailsPage } from "../pages/warehouse/farmerProductDetails";
import Bannerlist from "../pages/banner/bannerlist";
const role = localStorage.getItem("role");

const route = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="login"
        element={<Login />}
      />
      {/* <Route path="signup" element={<SignUp />} /> */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }>
        <Route
          path="/"
          element={<Dashboard />}
        />
        <Route
          path="/gaon"
          element={<Gaon />}
        />
        <Route
          path="/gaon/edit/:id"
          element={<EditeGaon />}
        />
        <Route
          path="/gaon/details/:id"
          element={<ShowGaon />}
        />
        <Route
          path="/userList"
          element={<UserList />}
        />
        <Route
          path="/user_department"
          element={<UserControl />}
        />

        <Route
          path="/unitList"
          element={<GetUnits />}
        />
        <Route
          path="/product/list"
          element={<CategoryListPage />}
        />

        <Route
          path="/buyerReqest/list"
          element={<BuyerRequestList />}
        />
        <Route
          path="/bidWarehouse/:status?"
          element={<WarehouseBidList />}
        />
        <Route
          path="/warehouseRequest/list"
          element={<RequestToFarmerList />}
        />
        <Route
          path="/farmerBid/:id"
          element={<FarmerBidList />}
        />
        <Route
          path="/buyerRequest/bid/list/:id"
          element={<WarehouseBidToBuyerList />}
        />
        <Route
          path="/buyerRequest/add"
          element={<AddBuyerRequest />}
        />
        <Route
          path="/farmerBid/details/:id"
          element={<DetailsPage />}
        />

        <Route
          path="/module/list"
          element={<ModuleList />}
        />
        <Route
          path="/module/add"
          element={<ModuleAdd />}
        />
        <Route
          path="/module/edit/:id"
          element={<ModuleEdit />}
        />

        <Route
          path="/contest/list/:moduleId"
          element={<ContestList />}
        />
        <Route
          path="/contest/add/:moduleId"
          element={<ContestAdd />}
        />
        <Route
          path="/contest/edit/:id"
          element={<ContestEdit />}
        />
        <Route
          path="/msp/list"
          element={<MinimumSellingPrice />}
        />
        <Route
          path="/buyerRequest/warehouseBid/:id"
          element={<BuyerConfirmRequestList />}
        />
        <Route
          path="/grade/list"
          element={<GradeList />}
        />
        <Route
          path="/buyerRequest/revice/:id"
          element={<AddReviceBuyerRequest />}
        />
        <Route
          path="/buyerRequest/inprogress/:id"
          element={<BuyerInprogressRequestList />}
        />
        <Route
          path="/buyerRequest/closed/:id"
          element={<BuyerClosedequestList />}
        />
        <Route
          path="/user/profile/:id"
          element={<UserProfile />}
        />
        <Route
          path="/products/:name/:id"
          element={<ProductList />}
        />
        <Route
          path="/user/add"
          element={<CreateUser />}
        />
        <Route
          path="/notification/list"
          element={<Notiifcations />}
        />
        <Route
          path="/banner/list"
          element={<Bannerlist />}
        />
        <Route
          path="/farmer/products"
          element={<FarmerProducts />}
        />
        <Route
          path="/farmer/products/details/:id"
          element={<FarmerProductDetailsPage />}
        />

        <Route
          path="/"
          element={<PageNotFound />}
        />
      </Route>
      <Route
        path="*"
        element={<PageNotFound />}
      />
    </Route>
  )
);

export default route;
