import { requestWithToken } from "../helper/helper";

const getPagesList = async (pageSize, pageNo) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/unitList?pageNo=${pageNo}&pageSize=${pageSize} `
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchUnitList = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/unitList`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const addUnitList = async (formData) => {
  try {
    const response = await requestWithToken("POST", `/admin/unit`, formData);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteUnit = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/unit/${id} `);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getUnit = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/unitDetails/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const putUnit = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/unit/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchUnitList,
  addUnitList,
  getPagesList,
  deleteUnit,
  getUnit,
  putUnit,
};
