import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import trashIcon from "../../assets/images/trash-03.svg";
import editIcon from "../../assets/images/edit_btn.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import Button from "../../components/bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import Pagination from "../../customComponents/pagination/paginationView";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import {
  createMSP,
  deleteMSP,
  editMSP,
  getMSP,
  getMSPDetails,
} from "../../action/category";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import { getCountryList, getProductList, getunits } from "../../action/users";

const MinimumSellingPrice = () => {
  const [mspList, setMSPList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState("");
  const [verietyList, setVerietyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [units, setUnits] = useState([]);

  const fetchUnits = async () => {
    const response = await getunits();
    if (response.status) {
      setUnits(response.data);
    }
  };

  const getMSPList = async () => {
    setIsLoading(true);
    const response = await getMSP(pageNo, pageSize);
    if (response.status) {
      setMSPList(response.data.list);
      setTotalData(response.data.count);
      setIsLoading(false);
    }
  };

  const handleDeleteUnit = async () => {
    const response = await deleteMSP(id);
    if (response.status) {
      setPageNo(1);
      getMSPList();
      setShowDeleteModel(false);
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  useEffect(() => {
    getMSPList();
  }, [pageNo, pageSize]);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    addFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const fetchVerieties = async () => {
    const response = await getProductList();
    if (response.status) {
      setVerietyList(response.data);
    }
  };

  const fetchCountries = async () => {
    const response = await getCountryList();
    if (response.status) {
      setCountryList(response.data);
    }
  };

  const addFormik = useFormik({
    initialValues: {
      minimum_selling_price: "",
      productId: "",
      countryId: "",
      unitId: "",
    },
    onSubmit: async (values) => {
      console.log(values, "  ==");

      const res = await createMSP(values);
      if (res.status) {
        setShow(false);
        getMSPList();
        handleClose();
        addFormik.handleReset();
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const editFormik = useFormik({
    initialValues: {
      minimum_selling_price: "",
      productId: "",
      countryId: "",
      unitId: "",
    },
    onSubmit: async (values) => {
      const res = await editMSP(id, values);
      if (res.status) {
        getMSPList();
        setIsEditMode(false);
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res.message ?? res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const getDetails = async (mspId) => {
    const response = await getMSPDetails(mspId);
    if (response.status) {
      editFormik.setFieldValue(
        "minimum_selling_price",
        response.data?.minimum_selling_price
      );
      editFormik.setFieldValue("unitId", response.data?.unitId);
      editFormik.setFieldValue("productId", response.data?.productId);
      editFormik.setFieldValue("countryId", response.data?.countryId);
    }
  };

  useEffect(() => {
    fetchVerieties();
    fetchCountries();
    fetchUnits();
  }, []);

  return (
    <div className="payout_data-content" style={{ height: "100%" }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Minimum Selling Price" }]} />
            </div>
          </div>
          <Button color="info" tag="a" onClick={handleShow}>
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Button>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Product Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">MSP</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Country</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {mspList?.length > 0 ? (
                      mspList?.map((item) => {
                        return (
                          <tr key={item._id}>
                            <td>{item?.product?.name}</td>
                            <td>
                              {item?.minimum_selling_price +
                                " per " +
                                item?.unit?.name}
                            </td>
                            <td>{item?.country?.name}</td>
                            <td className="flex_btn">
                              <img
                                src={editIcon}
                                onClick={() => {
                                  setId(item._id);
                                  getDetails(item._id);
                                  setIsEditMode(true);
                                }}
                              />
                              <img
                                src={trashIcon}
                                onClick={() => {
                                  setId(item._id);
                                  setShowDeleteModel(true);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" className="text_center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        {mspList.length > 0 ? (
          <Pagination
            currentPage={pageNo}
            totalCount={totalData}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        ) : (
          <></>
        )}
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Create New</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={addFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Minimum Selling Price</div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          name="minimum_selling_price"
                          placeholder="Enter minimum selling price"
                          value={addFormik.values?.minimum_selling_price}
                          onChange={addFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Products</div>
                      <div className="input_field">
                        <Select
                          className="input"
                          onChange={(e) => {
                            addFormik.setFieldValue(
                              "productId",
                              e.target.value
                            );
                          }}
                          value={addFormik.values.productId ?? ""}
                          required
                        >
                          <Option value="">Select Product</Option>
                          {verietyList.map((veriety) => {
                            return (
                              <Option
                                key={veriety._id}
                                id={veriety._id}
                                value={veriety._id}
                              >
                                {veriety?.name?.en}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Unit</div>
                      <div className="input_field">
                        <Select
                          className="input"
                          onChange={(e) => {
                            addFormik.setFieldValue("unitId", e.target.value);
                          }}
                          value={addFormik.values.unitId ?? ""}
                          required
                        >
                          <Option value="">Select Unit</Option>
                          {units.map((unit) => {
                            return (
                              <Option
                                key={unit._id}
                                id={unit._id}
                                value={unit._id}
                              >
                                {unit?.shortcode}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Country</div>
                      <div className="input_field">
                        <Select
                          className="input"
                          onChange={(e) => {
                            addFormik.setFieldValue(
                              "countryId",
                              e.target.value
                            );
                          }}
                          value={addFormik.values.countryId ?? ""}
                          required
                        >
                          <Option value="">Select Country</Option>
                          {countryList.map((country) => {
                            return (
                              <Option
                                key={country._id}
                                id={country._id}
                                value={country._id}
                              >
                                {country?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addBuyer_submit-btn">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={isEditMode}
          onHide={() => {
            setIsEditMode(false);
            editFormik.handleReset();
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Unit</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={editFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">Minimum Selling Price</div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          name="minimum_selling_price"
                          placeholder="Enter minimum selling price"
                          value={editFormik.values?.minimum_selling_price}
                          onChange={editFormik.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Products</div>
                      <div className="input_field">
                        <Select
                          className="input"
                          onChange={(e) => {
                            editFormik.setFieldValue(
                              "productId",
                              e.target.value
                            );
                          }}
                          value={editFormik.values.productId ?? ""}
                          required
                        >
                          <Option value="">Select Product</Option>
                          {verietyList.map((veriety) => {
                            return (
                              <Option
                                key={veriety._id}
                                id={veriety._id}
                                value={veriety._id}
                              >
                                {veriety?.name?.en}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Unit</div>
                      <div className="input_field">
                        <Select
                          className="input"
                          onChange={(e) => {
                            editFormik.setFieldValue("unitId", e.target.value);
                          }}
                          value={editFormik.values.unitId ?? ""}
                          required
                        >
                          <Option value="">Select Unit</Option>
                          {units.map((unit) => {
                            return (
                              <Option
                                key={unit._id}
                                id={unit._id}
                                value={unit._id}
                              >
                                {unit?.shortcode}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">Country</div>
                      <div className="input_field">
                        <Select
                          className="input"
                          onChange={(e) => {
                            editFormik.setFieldValue(
                              "countryId",
                              e.target.value
                            );
                          }}
                          value={editFormik.values.countryId ?? ""}
                          required
                        >
                          <Option value="">Select Country</Option>
                          {countryList.map((country) => {
                            return (
                              <Option
                                key={country._id}
                                id={country._id}
                                value={country._id}
                              >
                                {country?.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addBuyer_submit-btn">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={showDeleteModel}
          onHide={() => {
            setShowDeleteModel(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this MSP?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowDeleteModel(false);
              }}
            >
              No
            </Button>
            <Button
              className="btn-dark"
              variant="primary"
              onClick={() => {
                handleDeleteUnit();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default MinimumSellingPrice;
