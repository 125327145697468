import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import searchIcon from "../../assets/images/search-lg.svg";
import filerIcon from "../../assets/images/Up_Down.svg";
import Button from "../../components/bootstrap/Button";
import { fetchGaon } from "../../action/gaonApi";
import Pagination from "../../customComponents/pagination/paginationView";
import { TabPanel, Tabs } from "react-tabs";
import EditIcon from "../../assets/images/edit_btn.svg";
import viewIcon from "../../assets/images/maskIcon.svg";
import { Loader } from "../loader";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import {
  fetchDistricts,
  fetchStates,
  fetchSubDistricts,
} from "../../action/location";
import { gaonTextBasedOnRoleTYpe } from "../../helper/constants";

const Gaon = () => {
  const [gaonList, setGaonList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState("");

  const fetchGaonList = async (searchValue = search) => {
    setIsLoading(true);
    const response = await fetchGaon(
      pageNo,
      pageSize,
      searchValue,
      sort,
      order,
      blockId
    );
    if (response.status) {
      setGaonList(response.data.list);
      setTotalData(response.data.totalRecords);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    const resetSearchValue = ""; // Reset search value
    setSearch("");
    fetchGaonList(resetSearchValue);
  };

  const getState = async () => {
    const res = await fetchStates();
    if (res.status) {
      setStates(res.data);
    }
  };

  const getDistrict = async () => {
    const res = await fetchDistricts(stateId);
    if (res.status) {
      setDistricts(res.data);
    }
  };

  const getSubDistrict = async () => {
    const res = await fetchSubDistricts(districtId);
    if (res.status) {
      setBlocks(res.data);
    }
  };

  useEffect(() => {
    fetchGaonList();
  }, [pageNo, pageSize, sort, order]);

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    getDistrict();
  }, [stateId]);

  useEffect(() => {
    getSubDistrict();
  }, [districtId]);
  return (
    <>
      <div className="payout_inner_content">
        <div className="payout_data-content tablePage_content_header">
          {/* <div className="payout_data-head">
            <div className="payout_data-head-top">
              <div className="payout_data-left">
                <div className="payout_data_ttl">
                  <Breadcrumb paths={[{ name: "Gaon", link: "/gaon" }]} />
                </div>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Search</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Type any keyword"
                    id="beneficiary"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <div className="build_search">
                    <img
                      src={searchIcon}
                      alt="Search Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">State</div>
                <Select
                  className="custom_dropdn"
                  onChange={(e) => {
                    setStateId(e.target.value);
                    // getDistrict();
                  }}>
                  <Option>Select State</Option>
                  {states.map((state) => (
                    <Option
                      key={state._id}
                      value={state._id}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">District</div>
                <Select
                  className="custom_dropdn"
                  onChange={(e) => {
                    setDistrictId(e.target.value);
                  }}>
                  <Option>Select District</Option>
                  {districts.map((district) => (
                    <Option
                      key={district._id}
                      value={district._id}>
                      {district.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Sub District</div>
                <Select
                  className="custom_dropdn"
                  onChange={(e) => {
                    setBlockId(e.target.value);
                  }}>
                  <Option value="">Select Sub District</Option>
                  {blocks.map((block) => (
                    <Option
                      key={block._id}
                      value={block._id}>
                      {block.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="submit_btn_grp">
            <button
              className="btn_submit btn btn-dark"
              type="button"
              onClick={() => {
                handleReset();
              }}
              disabled={!search && !blockId}>
              Reset
            </button>
            <button
              className={`btn btn-success btn_submit ${search ? "active" : ""}`}
              type="submit"
              disabled={!search && !blockId}
              onClick={() => {
                fetchGaonList();
              }}>
              Search
            </button>
          </div>
        </div>
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left">
              <div className="payout_data_ttl1">{gaonTextBasedOnRoleTYpe()}</div>
            </div>
          </div>
        </div>
        <div className="tab_box">
          <Tabs>
            <TabPanel>
              <div className="">
                <table className="payout_table upi-mandate_table">
                  <thead>
                    <tr className="tableHead">
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">
                          Name
                          <img
                            src={filerIcon}
                            onClick={() => {
                              setSort("name");
                              setOrder(order == 1 ? -1 : 1);
                            }}
                            alt="filter"
                          />
                        </div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Description</div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="4">
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {gaonList?.length > 0 ? (
                          gaonList?.map((item) => (
                            <tr key={item?._id}>
                              <td>{item?.name}</td>
                              <td>{item?.info ?? "-"}</td>
                              <td>
                                <div className="flex_btn">
                                  <Link
                                    to={`/gaon/details/${item?._id}`}
                                    style={{ marginRight: "4px" }}>
                                    <img
                                      src={viewIcon}
                                      alt="view"
                                      className="eye_icon"
                                    />
                                  </Link>

                                  <Link to={`/gaon/edit/${item?._id}`}>
                                    <img
                                      src={EditIcon}
                                      alt="Edit"
                                      className="edit_icon"
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="3"
                              className="text_center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </Tabs>
          <Pagination
            currentPage={pageNo}
            totalCount={totalData}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Gaon;
