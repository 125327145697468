import React, { useState, useEffect, useRef } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { toast } from "react-hot-toast";
import threedot from "../../assets/images/3-dot.svg";
import editIcon from "../../assets/images/edit_btn.svg";
import Button from "../../components/bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import Pagination from "../../customComponents/pagination/paginationView";
import {
  addCategoryData,
  deleteCategoryData,
  editCategory,
  getCategoryData,
  getCategoryDetails,
} from "../../action/category";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Modal } from "react-bootstrap";
import { SingleErrorMessage } from "../singleErrorMessage";
import { useNavigate } from "react-router-dom";
import uploadImage from "../../action/imageUpload";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";

const CategoryListPage = () => {
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const [categoryList, setCategoryList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [productId, setProductId] = useState("");
  const [editModel, setEditModel] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const getCategoryPage = async () => {
    try {
      setIsLoading(true);
      const response = await getCategoryData(pageNo, pageSize);
      if (response.status) {
        setCategoryList(response.data.list);
        setTotalData(response.data.count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  useEffect(() => {
    getCategoryPage(pageNo);
  }, [pageNo, pageSize]);

  const handleClose = () => {
    setShow(false);
    addCategoryFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const addCategoryFormik = useFormik({
    initialValues: {
      name: { en: "" },
      pic: "",
    },
    onSubmit: async (values) => {
      if (values.name.en == "") {
        toast.custom(
          <SingleErrorMessage
            data={"Please enter product name"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      if (values.pic == "" || values.pic == null) {
        toast.custom(
          <SingleErrorMessage
            data={"Please upload image"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      try {
        const res = await addCategoryData(values);
        if (res.status) {
          setShow(false);
          getCategoryPage();
          toast.custom(
            <SingleErrorMessage
              data={res.message}
              error={false}
              bColor="#175FC9"
              onClose={onCloseToaste}
            />
          );
        } else {
          getCategoryPage();
          setEditModel(false);
          toast.custom(
            <SingleErrorMessage
              data={res.message ? res.message : res}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
        }
        editCategoryFormik.handleReset();
      } catch (error) {
        console.error("Failed to add category", error);
      }
    },
  });

  const handleToggleOptions = (id) => {
    // e.stopPropagation();
    setShowOptions((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the visibility for the specific product
    }));
  };
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowOptions({}); // Close all popups if clicked outside
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const editCategoryFormik = useFormik({
    initialValues: {
      name: { en: "" },
      pic: "",
    },
    onSubmit: async (values) => {
      if (values.name.en == "") {
        toast.custom(
          <SingleErrorMessage
            data={"Please enter product name"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      if (values.pic == "" || values.pic == null) {
        toast.custom(
          <SingleErrorMessage
            data={"Please upload image"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      try {
        const res = await editCategory(productId, values);
        if (res.status) {
          toast.custom(
            <SingleErrorMessage
              data={res.message}
              error={false}
              bColor="#175FC9"
              onClose={onCloseToaste}
            />
          );
        } else {
          toast.custom(
            <SingleErrorMessage
              data={res.message ? res.message : res}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
        }
        getCategoryPage();
        setEditModel(false);
        editCategoryFormik.handleReset();
      } catch (error) {
        console.error("Failed to add category", error);
      }
    },
  });

  const fetchProductDetails = async (id) => {
    const res = await getCategoryDetails(id);
    if (res.status) {
      editCategoryFormik.setFieldValue("name.en", res.data?.name?.en);
      editCategoryFormik.setFieldValue("pic", res.data?.pic);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleDelete = async () => {
    try {
      const res = await deleteCategoryData(productId);
      if (res.status) {
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
          />
        );
        getCategoryPage();
        setShowDeleteModel(false);
      }
    } catch (error) {
      console.error("Failed to delete category", error);
    }
  };

  const uploadMediaImage = async (file, type) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadImage(formData, "gaon");
    if (res.status) {
      if (type == 1) {
        addCategoryFormik.setFieldValue("pic", res.data[0]);
      } else {
        editCategoryFormik.setFieldValue("pic", res.data[0]);
      }
    }
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Products" }]} />
            </div>
          </div>
          <Button color="info" tag="a" onClick={handleShow}>
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Button>
        </div>
      </div>
      <div className="main_category">
        <ul>
          {categoryList?.map((product, index) => {
            return (
              <li>
                <div className="option-div" key={index}>
                  <img
                    src={threedot}
                    alt="threedot"
                    onClick={() => handleToggleOptions(product._id)}
                  />
                </div>
                {showOptions[product._id] && (
                  <div ref={popupRef} className="options-menu" key={index}>
                    <button
                      type="button"
                      onClick={() => {
                        fetchProductDetails(product?._id);
                        setProductId(product?._id);
                        setEditModel(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setProductId(product?._id);
                        setShowDeleteModel(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
                <div
                  className="img_sec"
                  onClick={() => {
                    navigate(`/products/${product.name.en}/${product._id}`);
                  }}
                >
                  <img
                    src={process.env.REACT_APP_S3_URL + "/" + product?.pic}
                    alt={product?.name?.en}
                  />
                </div>
                <div className="category_name">{product.name.en}</div>
              </li>
            );
          })}
        </ul>
      </div>

      <Offcanvas
        show={show}
        onHide={(e) => {
          setShow(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Product</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="add_buyer_content">
            <form onSubmit={addCategoryFormik.handleSubmit}>
              <div className="row">
                <div className="input-box">
                  <div className="input-label">Product Name</div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      name={`name.en`}
                      placeholder="Enter product name"
                      value={addCategoryFormik.values.name.en || ""}
                      onChange={addCategoryFormik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="option-card" style={{ display: "flex" }}>
                  <ThumbnailPicker
                    width="60%"
                    height="250px"
                    onFileSelect={(file) => {
                      const _URL = window.URL || window.webkitURL;

                      const img = new Image();
                      uploadMediaImage(file, 1);

                      img.src = _URL.createObjectURL(file);
                    }}
                    image={addCategoryFormik.values.pic}
                    required
                  />
                </div>
              </div>
              <div className="addBuyer_submit-btn">
                <button className="btn btn-success" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={editModel}
        onHide={(e) => {
          setEditModel(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Product</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="add_buyer_content">
            <form onSubmit={editCategoryFormik.handleSubmit}>
              <div className="input-box">
                <div className="input-label">Product Name</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    name={`name.en`}
                    placeholder="Enter product name"
                    value={editCategoryFormik.values.name.en || ""}
                    onChange={editCategoryFormik.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="option-card" style={{ display: "flex" }}>
                <ThumbnailPicker
                  width="60%"
                  height="250px"
                  onFileSelect={(file) => {
                    const _URL = window.URL || window.webkitURL;

                    const img = new Image();
                    uploadMediaImage(file, 2);

                    img.src = _URL.createObjectURL(file);
                  }}
                  image={editCategoryFormik.values?.pic}
                  required
                />
              </div>
              <div className="addBuyer_submit-btn">
                <button className="btn btn-success" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={showDeleteModel}
        onHide={() => {
          setShowDeleteModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowDeleteModel(false);
            }}
          >
            Close
          </Button>
          <Button
            className="btn-dark"
            variant="primary"
            onClick={() => {
              handleDelete();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoryListPage;
