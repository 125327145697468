import React, { useEffect } from "react";

import { Navigate, useLocation, redirect } from "react-router-dom";

export const ProtectedRoute = (props) => {
  const { children } = props;
  let location = useLocation();
  const userMid = localStorage.getItem("auth-token");
  // const userSecret = localStorage.getItem("fstacSecretKey");
  if (!userMid) {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
      />
    );
  }
  return children;
};
