import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createContest,
  fetchModuleDetails,
  getModuleList,
} from "../../../action/contest";
import trashIcon from "../../../assets/images/trash-03.svg";
import Breadcrumb from "../../../Breadcrumb/Breadcrumb";
import { SingleErrorMessage } from "../../singleErrorMessage";
import { toast } from "react-hot-toast";
import ThumbnailPicker from "../../../components/ThumbnailPicker/ThumbnailPicker";
import uploadImage from "../../../action/imageUpload";

const ContestAdd = () => {
  const { moduleId } = useParams();
  const navigate = useNavigate();

  const [contestDetails, setContestDetails] = useState([]);
  const [quetionType, setQuetionType] = useState("TEXT");
  const [optionType, setOptionType] = useState("TEXT");
  const [optionTypeArr, setOptionTypeArr] = useState([]);

  const [videoType] = useState([
    { name: "Upload Internal Video", value: "INTERNAL_VIDEO" },
    { name: "Upload External Video", value: "EXTERNAL_VIDEO" },
  ]);
  const [type, setType] = useState("INTERNAL_VIDEO");

  const getModuleDetails = async () => {
    try {
      const response = await fetchModuleDetails(moduleId);
      if (response.status) {
        let moduleTypeArr = response.data[0].moduleType;

        // Set contest and option type arrays
        setContestDetails(moduleTypeArr);
        setOptionTypeArr(moduleTypeArr);

        // Check if moduleTypeArr contains only "IMAGE"
        if (moduleTypeArr.length === 1 && moduleTypeArr[0] === "IMAGE") {
          moduleTypeArr.push("TEXT");
          setOptionTypeArr(moduleTypeArr); // Update optionTypeArr to include "TEXT"
        }
        if (response.data[0].moduleType.length == 1) {
          setQuetionType(response.data[0].moduleType[0]);
          if (response.data[0].moduleType[0] === "VIDEO") {
            setOptionType("TEXT");
            setOptionTypeArr(["TEXT"]);
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const addContestFormik = useFormik({
    initialValues: {
      title: { en: "" },
      moduleId: moduleId,
      options: [{ title: { en: "" }, isCorrect: false }],
      contestType: "",
      media: "",
      optionType: "",
    },
    onSubmit: async (values) => {
      values.contestType = quetionType;
      values.optionType = optionType;

      if (values.options.length == 1) {
        toast.custom(
          <SingleErrorMessage
            data={"Please select 2 or more options"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      if (values.media == "") {
        delete values.media;
      }
      if (quetionType == "VIDEO" && values.media == "") {
        toast.custom(
          <SingleErrorMessage
            data={"Please select media"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      const isEmptyOption = values.options.filter(
        (option) => option.title.en === ""
      );
      if (isEmptyOption.length > 0) {
        toast.custom(
          <SingleErrorMessage
            data={"Please fill option"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      const isCorrectAns = values.options.filter(
        (option) => option.isCorrect == true
      );
      if (isCorrectAns.length == 0) {
        toast.custom(
          <SingleErrorMessage
            data={"Please select any one option as correct"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      console.log("values  ", values);
      const res = await createContest(values);
      if (res.status) {
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        navigate(-1);
      } else {
        toast.custom(
          <SingleErrorMessage
            data={res}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const addMoreOptions = () => {
    addContestFormik.setFieldValue("options", [
      ...addContestFormik.values.options,
      { title: { en: "" }, isCorrect: false },
    ]);
  };

  const addRemoveOptions = (mainIndex) => {
    addContestFormik.setFieldValue(
      "options",
      addContestFormik.values.options.filter(
        (value, index) => index !== mainIndex
      )
    );
  };

  const uploadMediaImage = async (file, type, index) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadImage(formData, "module");
    if (res.status) {
      if (type == 1) addContestFormik.setFieldValue(`media`, res.data[0]);
      else
        addContestFormik.setFieldValue(
          `options[${index}].title.en`,
          res.data[0]
        );
    }
  };

  useEffect(() => {
    getModuleDetails();
  }, []);

  const [videoPreview, setVideoPreview] = useState(null);

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create a video element to generate a thumbnail
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file);
      videoElement.currentTime = 1; // Set time at which to capture the thumbnail (e.g., 1 second)

      videoElement.onloadeddata = () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        const thumbnail = canvas.toDataURL("image/png");
        setVideoPreview(thumbnail); // Set the thumbnail as preview
      };

      const formData = new FormData();
      formData.append("file", file);

      try {
        const res = await uploadImage(formData, "module");
        if (res.status) {
          addContestFormik.setFieldValue(`media`, res.data[0]);
        }
      } catch (error) {
        console.error("Error uploading video:", error);
      }
    }
  };
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  { name: "Question", link: `/contest/list/${moduleId}` },
                  { name: "Add Question", link: "/contest/add" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <form onSubmit={addContestFormik.handleSubmit} className="m-4">
          <div className="row">
            {contestDetails?.length > 0 ? (
              <>
                <div className="col-lg-6">
                  <div className="input-box">
                    <div className="input-label">Question Type</div>
                    <div className="input_field">
                      <select
                        required
                        type="text"
                        className="input"
                        placeholder="Level"
                        name="zone"
                        value={
                          contestDetails.length === 1
                            ? contestDetails[0]
                            : quetionType
                        }
                        onChange={(e) => {
                          setQuetionType(e.target.value);
                          if (e.target.value == "VIDEO") {
                            setOptionType("TEXT");
                          }
                        }}
                      >
                        {contestDetails.length > 1 ? (
                          <option value="">Select Question Type</option>
                        ) : (
                          ""
                        )}
                        {contestDetails?.map((value) => {
                          return <option value={value}>{value}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="input-box">
                    <div className="input-label">Option Type</div>
                    <div className="input_field">
                      <select
                        required
                        type="text"
                        className="input"
                        placeholder="Level"
                        name="zone"
                        value={optionType}
                        onChange={(e) => {
                          setOptionType(e.target.value);
                        }}
                        disabled={quetionType == "VIDEO"}
                      >
                        {quetionType === "VIDEO" &&
                        contestDetails.length == 1 ? (
                          <option value="TEXT" selected>
                            TEXT
                          </option>
                        ) : (
                          <>
                            <option value="">Select Option Type</option>
                            {optionTypeArr
                              ?.filter((type) => type !== "VIDEO")
                              .map((value) => {
                                return (
                                  <option key={value} value={value}>
                                    {value}
                                  </option>
                                );
                              })}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              " "
            )}
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Title</div>
                <div className="input_field">
                  <input
                    required
                    type="text"
                    className="input"
                    name="title.en"
                    placeholder="Enter title"
                    value={addContestFormik.values.title.en}
                    onChange={addContestFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            {quetionType === "VIDEO" ? (
              <>
                <div className="col-lg-6">
                  <div className="input-box">
                    <div className="input-label">Video Type</div>
                    <div className="input_field">
                      <select
                        required
                        type="text"
                        className="input"
                        placeholder="Level"
                        name="zone"
                        value={type}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                      >
                        <option value="">Select Video Type</option>
                        {videoType?.map((value) => {
                          return (
                            <option value={value.value}>{value.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-lg-6"></div>
            )}

            <div className="col-lg-6">
              {quetionType == "IMAGE" ? (
                <div className="input-box">
                  <div className="input-label">Image</div>
                  <ThumbnailPicker
                    width="100%"
                    height="250px"
                    onFileSelect={(file) => {
                      const _URL = window.URL || window.webkitURL;

                      const img = new Image();
                      uploadMediaImage(file, 1);

                      img.src = _URL.createObjectURL(file);
                    }}
                    image={addContestFormik.values?.media}
                  />
                </div>
              ) : quetionType == "VIDEO" ? (
                <>
                  {type == "INTERNAL_VIDEO" ? (
                    <div className="input-box">
                      <input
                        type="file"
                        accept="video/*"
                        onChange={handleVideoUpload}
                      />

                      {videoPreview && (
                        <div>
                          <img
                            src={videoPreview}
                            alt="Video Thumbnail"
                            style={{ width: "200px", height: "auto" }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="input-box">
                      <div className="input-label">Video URL</div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="media"
                          placeholder="Enter url"
                          value={addContestFormik.values.media}
                          onChange={addContestFormik.handleChange}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="col-lg-6"></div>
            {addContestFormik.values.options?.map((value, index) => {
              return (
                <div className="col-lg-6">
                  {optionType == "TEXT" || quetionType == "VIDEO" ? (
                    <div className="input-box">
                      <div className="input-label">Option {index + 1}</div>
                      <div className="input_field custom_field">
                        <input
                          // required
                          type="text"
                          className="input custom_input"
                          name={`options[${index}].title.en`}
                          placeholder="Enter title"
                          value={
                            addContestFormik.values.options[index].title.en
                          }
                          onChange={addContestFormik.handleChange}
                        />
                        <div className="checkbox_div">
                          <input
                            style={{ width: "auto" }}
                            type="checkbox"
                            className="input"
                            name={`options[${index}].isCorrect`}
                            placeholder="Enter title"
                            value={
                              addContestFormik.values.options[index].isCorrect
                            }
                            onChange={addContestFormik.handleChange}
                            // required
                          />
                          <img
                            className="custom_trash"
                            src={trashIcon}
                            onClick={() => {
                              addRemoveOptions(index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : optionType == "IMAGE" ? (
                    <div className="input-box ">
                      <div className="input-label ">Option {index + 1}</div>
                      <div className="custom_img_option">
                        <ThumbnailPicker
                          width="100%"
                          height="250px"
                          onFileSelect={(file) => {
                            const _URL = window.URL || window.webkitURL;

                            const img = new Image();
                            uploadMediaImage(file, 2, index);

                            img.src = _URL.createObjectURL(file);
                          }}
                          image={
                            addContestFormik.values?.options[index].title.en
                          }
                        />
                        <div className="checkbox_div">
                          <input
                            style={{ width: "auto" }}
                            type="checkbox"
                            className="input"
                            name={`options[${index}].isCorrect`}
                            placeholder="Enter title"
                            value={
                              addContestFormik.values.options[index].isCorrect
                            }
                            onChange={addContestFormik.handleChange}
                            // required
                          />
                          <img
                            className="custom_trash"
                            src={trashIcon}
                            onClick={() => {
                              addRemoveOptions(index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>

          <div>
            <div>
              <div className="input-box ">
                <div className="flex_btn">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={addMoreOptions}
                    disabled={addContestFormik.values.options.length == 4}
                  >
                    Add More Option
                  </button>
                  <button
                    className="btn btn-success"
                    type="submit"
                    disabled={addContestFormik.values.options.length == 0}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContestAdd;
