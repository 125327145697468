import { requestWithToken } from "../helper/helper";

const fetchUsersList = async (
  currentPage,
  perPage,
  search,
  sort,
  order,
  roleId
) => {
  try {
    const queryParams = [];

    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);
    if (roleId) queryParams.push(`role=${roleId}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/admin/userList${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
//User Control Panel API
const fetchUserTypeList = async (
  currentPage,
  perPage,
  search,
  sort,
  order,
  roleId
) => {
  try {
    const queryParams = [];

    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);
    if (roleId) queryParams.push(`role=${roleId}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/admin/userType${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deleteUserType = async (
  roleId
) => {
  try {
    const queryParams = [];

    const response = await requestWithToken(
      "DELETE",
      `/admin/userType/${roleId}`
    );
    return response;
  } catch (err) {
    return err.response.data;
  }
};
const editUserTypeList = async (
  roleId,
  data

) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/userType/${roleId}`,data
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const getUserTypeDetails = async (

  roleId
) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/userTypeDetails/${roleId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const createUserType = async (data) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/admin/userType/`,data
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};


const fetchAdmin = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/roles`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const editAdmin = async (data) => {
  try {
    const response = await requestWithToken("PUT", `/admin/userRole`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createUser = async (data) => {
  try {
    const response = await requestWithToken("POST", `/admin/create/user`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getUserDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/user/profile/details/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editUserDetails = async (data) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/user/profile/details`,
      data
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteAddress = async (data) => {
  try {
    const response = await requestWithToken("DELETE", `/address`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
export {
  fetchAdmin,
  editAdmin,
  fetchUsersList,
  createUser,
  getUserDetails,
  editUserDetails,
  deleteAddress,
  fetchUserTypeList,
  deleteUserType,
  editUserTypeList,
  createUserType,
  getUserTypeDetails,
};
