import { requestWithToken } from "../helper/helper";

const fetchStates = async (id) => {
  try {
    const response = await requestWithToken("GET", `/location/states`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchStatesByCountryId = async (id) => {
  try {
    const response = await requestWithToken("GET", `/location/state/list?countryId=${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchDistricts = async (id) => {
  try {
    const response = await requestWithToken("GET", `/location/districts/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSubDistricts = async (id) => {
  try {
    const response = await requestWithToken("GET", `/location/blocks/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchGaons = async (id) => {
  try {
    const response = await requestWithToken("GET", `/location/gaons/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchCountry = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/country`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllStates = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/location/state/list?countryId=${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllDistricts = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/location/district/list?stateId=${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllSubDistricts = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/location/block/list?districtId=${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAllGaons = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/location/gaon/list?blockId=${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAddresses = async () => {
  try {
    const response = await requestWithToken("GET", `/address/list`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchWarehouses = async (productId, payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/address/warehouses/${productId}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchStates,
  fetchDistricts,
  fetchSubDistricts,
  fetchGaons,
  fetchCountry,
  fetchAllStates,
  fetchAllSubDistricts,
  fetchAllDistricts,
  fetchAllGaons,
  fetchAddresses,
  fetchWarehouses,
  fetchStatesByCountryId
};
