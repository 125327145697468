import { Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import Icon from "../../components/icon/Icon";
import { createModule } from "../../action/contest";
import uploadImage from "../../action/imageUpload";
import moment from "moment";
import { SingleErrorMessage } from "../singleErrorMessage";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { toast } from "react-hot-toast";
import { gaonTextBasedOnRoleTYpe } from "../../helper/constants";

const ModuleAdd = () => {
  const [isModuleTypeOpen, setIsModuleTypeOpen] = useState(false);
  const dropdownRef = useRef(null);

  const navigate = useNavigate();

  const [level] = useState([
    { name: "Global", value: "GLOBAL" },
    { name: "State", value: "STATE" },
    { name: "District", value: "DISTRICT" },
    { name: "Sub-District", value: "SUB_DISTRICT" },
    { name: gaonTextBasedOnRoleTYpe(), value: "GAON" },
  ]);

  const [moduleType] = useState([
    { name: "Text", value: "TEXT" },
    { name: "Video", value: "VIDEO" },
    { name: "Image", value: "IMAGE" },
  ]);

  const [timer] = useState([
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "15", value: 15 },
    { name: "20", value: 20 },
  ]);

  const [type] = useState([
    { name: "Generic", value: 0 },
    { name: "Live", value: 1 },
  ]);

  const addContestFormik = useFormik({
    initialValues: {
      title: { en: "" },
      level: "",
      levelIds: [],
      startDate: "",
      endDate: "",
      moduleType: [],
      moduleType: [],
      assets: { src: "", w: 200, h: 200 },
      price: "",
      type: "",
      passing: 100,
      timer: 0,
      totalWinners: "",
      coins_per_question: "",
      isPopular: false,
    },
    onSubmit: async (values) => {
      console.log(values);
      if (values.moduleType.length == 0) {
        toast.custom(
          <SingleErrorMessage
            data={"Please select quiz type"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      if (values.moduleType === "IMAGE") {
        if (values.assets.src === "") {
          toast.custom(
            <SingleErrorMessage
              data={"Image is required"}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
          return;
        } else {
          const res = await createModule(values);
          if (res.status) {
            toast.custom(
              <SingleErrorMessage
                data={res.message}
                error={false}
                bColor="#175FC9"
                onClose={onCloseToaste}
              />
            );
            navigate("/module/list");
          } else {
            toast.custom(
              <SingleErrorMessage
                data={res.message}
                error={true}
                bColor="#D92D20"
                onClose={onCloseToaste}
              />
            );
          }
        }
      } else {
        const res = await createModule(values);
        if (res.status) {
          toast.custom(
            <SingleErrorMessage
              data={res.message}
              error={false}
              bColor="#175FC9"
              onClose={onCloseToaste}
            />
          );
          navigate("/module/list");
        } else {
          toast.custom(
            <SingleErrorMessage
              data={res.message}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
        }
      }
    },
  });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModuleTypeOpen(false); // Close dropdown if clicked outside
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const uploadMediaImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadImage(formData, "module");
    if (res.status) {
      addContestFormik.setFieldValue(`assets.src`, res.data[0]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModuleTypeOpen(false); // Close dropdown if clicked outside
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const handleCheckboxChange = (e, value) => {
    const newModuleTypes = e.target.checked
      ? [...addContestFormik.values.moduleType, value]
      : addContestFormik.values.moduleType.filter(
          (moduleType) => moduleType !== value
        );
    addContestFormik.setFieldValue("moduleType", newModuleTypes);
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb
                paths={[
                  { name: "Contest", link: "/module/list" },
                  { name: "Add Contest", link: "/module/add" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Formik>
          <Form onSubmit={addContestFormik.handleSubmit} className="m-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Contest Title</div>
                  <div className="input_field">
                    <input
                      required
                      type="text"
                      className="input"
                      name="title.en"
                      placeholder="Enter title"
                      value={addContestFormik.values.title.en}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label">Winning Prize Pool</div>
                  <div className="input_field">
                    <input
                      required
                      type="number"
                      className="input"
                      name="price"
                      placeholder="Enter prize"
                      value={addContestFormik.values.price}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="input-box" ref={dropdownRef}>
                  <div className="input-label">Quiz Type</div>
                  <div className="input_field custom_input">
                    <div
                      className="dropdown-header"
                      onClick={() => setIsModuleTypeOpen(!isModuleTypeOpen)}
                    >
                      {Array.isArray(addContestFormik.values.moduleType) &&
                      addContestFormik.values.moduleType.length > 0
                        ? addContestFormik.values.moduleType.join(", ")
                        : "Select Module Type"}
                    </div>
                    {isModuleTypeOpen && (
                      <div className="dropdown-menuu">
                        {moduleType.map((value) => (
                          <label key={value.value} className="dropdown-itemm">
                            <Field
                              type="checkbox"
                              name="moduleType"
                              value={value.value}
                              checked={addContestFormik.values.moduleType.includes(
                                value.value
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(e, value.value)
                              }
                            />
                            {value.name}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label">Start Date</div>
                  <div className="input_field">
                    <input
                      required
                      type="date"
                      className="input"
                      name="startDate"
                      min={new Date().toJSON().slice(0, 10)}
                      placeholder="Enter startDate"
                      value={addContestFormik.values.startDate}
                      onChange={addContestFormik.handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label">Timer</div>
                  <div className="input_field">
                    <select
                      required
                      type="text"
                      className="input"
                      placeholder="Level"
                      name="timer"
                      value={addContestFormik?.values?.timer}
                      onChange={(e) => {
                        addContestFormik.setFieldValue("timer", e.target.value);
                      }}
                    >
                      <option value="">Select Timer</option>
                      {timer.map((value) => {
                        return (
                          <option value={value.value}>{value.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label"></div>
                  <div className="input-label">Is Popular?</div>
                  <div className="checkform_box">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="option1"
                        onChange={() => {
                          addContestFormik.setFieldValue("isPopular", true);
                        }}
                        required
                      />
                      <label class="form-check-label" for="inlineRadio1">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="option2"
                        onChange={() => {
                          addContestFormik.setFieldValue("isPopular", false);
                        }}
                        required
                      />
                      <label class="form-check-label" for="inlineRadio2">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                {addContestFormik.values.moduleType === "IMAGE" ? (
                  <div className="input-box">
                    <div className="input-label">Image</div>
                    <ThumbnailPicker
                      width="100%"
                      height="250px"
                      onFileSelect={(file) => {
                        const _URL = window.URL || window.webkitURL;

                        const img = new Image();
                        uploadMediaImage(file);

                        img.src = _URL.createObjectURL(file);
                      }}
                      image={addContestFormik.values.assets.src}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-lg-6">
                <div className="input-box">
                  <div className="input-label">Contest Reach</div>
                  <div className="input_field">
                    <select
                      required
                      type="text"
                      className="input"
                      placeholder="Level"
                      name="zone"
                      value={addContestFormik?.values?.level}
                      onChange={(e) => {
                        addContestFormik.setFieldValue("level", e.target.value);
                      }}
                    >
                      <option value="">Select Level</option>
                      {level.map((value) => {
                        return (
                          <option value={value.value}>{value.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {/* <div className="input-box">
                <div className="input-label">Passing</div>
                <div className="input_field">
                  <input
                    required
                    type="number"
                    className="input"
                    name="passing"
                    placeholder="Enter Passing"
                    value={addContestFormik.values.passing}
                    onChange={addContestFormik.handleChange}
                  />
                </div>
              </div> */}
                <div className="input-box">
                  <div className="input-label">Maximum Number Of Winners</div>
                  <div className="input_field">
                    <input
                      required
                      type="number"
                      className="input"
                      name="totalWinners"
                      placeholder="Enter maximum number of winners"
                      value={addContestFormik.values.totalWinners}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label">Contest Type</div>
                  <div className="input_field">
                    <select
                      required
                      type="text"
                      className="input"
                      placeholder="Level"
                      name="type"
                      value={addContestFormik?.values?.type}
                      onChange={(e) => {
                        addContestFormik.setFieldValue("type", e.target.value);
                      }}
                    >
                      <option value="">Select Type</option>
                      {type.map((value) => {
                        return (
                          <option value={value.value}>{value.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="input-box">
                  <div className="input-label">End Date</div>
                  <div className="input_field">
                    <input
                      required
                      type="date"
                      className="input"
                      name="endDate"
                      placeholder="End Date"
                      value={addContestFormik.values.endDate}
                      min={new Date().toJSON().slice(0, 10)}
                      onChange={addContestFormik.handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div>
                <div className="input-box">
                  <div className="input-label">Coins</div>
                  <div className="input_field">
                    <input
                      type="number"
                      className="input"
                      name="coins_per_question"
                      placeholder="Enter coins"
                      value={addContestFormik.values.coins_per_question}
                      onChange={addContestFormik.handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="input-box ">
                  <div className="flex_btn">
                    <button className="btn btn-success" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ModuleAdd;
