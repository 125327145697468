import { RouterProvider } from "react-router-dom";
import route from "../src/routes/route";

function App() {
  const role = localStorage.getItem("roleType");
  document.getElementsByTagName("TITLE")[0].text =
    role == "GAON" ? "TheGaon" : "Farmlink";

  return <RouterProvider router={route} />;
}

export default App;
