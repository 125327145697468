import React, { useEffect, useRef, useState } from "react";
import arr1 from "../../../assets/images/v_single.png";
import CommonTableBody from "./commonTableBody";
import { useNavigate } from "react-router-dom";

export const CommonTable = ({ title, data, viewDetails, status }) => {
  const navigate = useNavigate();
  const [showAction, setShowAction] = useState(null);
  const dropdownRef = useRef(null); // Reference for the dropdown

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowAction(null);
    }
  };

  useEffect(() => {
    if (showAction) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [showAction]);

  const role = localStorage.getItem("role");

  const handleRedirect = () => {
    if (role == "WAREHOUSE") {
      navigate(`/bidWarehouse/${status}`);
    } else {
      navigate(`/buyerReqest/list`);
    }
  };

  return (
    <>
      <div className="dashboard_table_box card1">
        <div className="dashboard_table_head">
          <div className="dashboard_table_head-ttl">{title}</div>
          {role !== "SUPERADMIN" && role !== "ADMIN" && (
            <div className="card2__header-right" onClick={handleRedirect}>
              View List
              <img
                src={arr1}
                alt="rightArrow"
                className="card2__header-right-arrow"
              />
            </div>
          )}
        </div>
        <div className="table-responsive">
          <table>
            <thead>
              <th>Order ID</th>
              <th>Order Date</th>
              <th>Address</th>
              <th>Product</th>
              <th>Variety</th>
              <th>Grade</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total Price</th>
              <th>Delivery Date</th>
              {role !== "SUPERADMIN" && role !== "ADMIN" && <th>Action</th>}
            </thead>
            <tbody>
              {data?.length ? (
                data.map((value, index) => {
                  return (
                    <CommonTableBody
                      key={index}
                      value={value}
                      index={index}
                      ref={dropdownRef}
                      setShowAction={setShowAction}
                      showAction={showAction}
                      role={role}
                      viewDetails={viewDetails}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>No data found !!!</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
