import { Suspense, Fragment, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SideNav } from "./sideNav";
import "../styles/payout.css";
import "../styles/style.css";
import bellIcon from "../assets/images/bell.svg";
import { getMe } from "../action/users";
const role = localStorage.getItem("role");

export function DashboardLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const fetchMe = async () => {
    const response = await getMe();
    if (response.status) {
      setUserData(response?.data);
    }
  };

  useEffect(() => {
    fetchMe();
  }, [location.pathname]);
  return (
    <div className="payout_page">
      <div
        className="sidemenu-container"
        style={{ position: "relative", display: "inline-block" }}
      >
        <SideNav />
      </div>
      <div className="payout_dashboard">
        <div className="payout_content">
          <div className="notification_sec">
            {role !== "SUPERADMIN" && role !== "ADMIN" && (
              <div className="notification_icon">
                <img
                  className="bell_icon"
                  src={bellIcon}
                  alt="bellIcon"
                  height="25px"
                  width="25px"
                  onClick={() => {
                    navigate("/notification/list");
                  }}
                />
                {userData?.notificationCount > 0 && (
                  <span>{userData.notificationCount}</span>
                )}
              </div>
            )}
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
