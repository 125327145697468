import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

export const AreaChart = ({total, data, color, Ymax}) => { 
  const option = {
    color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
    title: {
      text: total || 0,
      left: "10px",
      top: "20px",
      textStyle: {
        color: "#000000",
        fontWeight: "700",
        fontFamily: "Poppins",
        fontSize: 24,
      },
    },
    grid: {
      left: "0",
      right: "0",
      top: "20%",
      bottom: "1%",
      containLabel: false,
    },
    xAxis: [
      {
        type: "category",
        show: false, // Hide X-axis labels
        boundaryGap: false,
      },
    ],
    yAxis: [
      {
        type: "value",
        show: false, // Hide X-axis labels
        max: Ymax
      },
    ],
    series: [
      {
        name: "Line 1",
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          color: color || "#A1CFDD",
          width: 3,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: color || "#A1CFDD",
            },
            {
              offset: 1,
              color: "#ffffff",
            },
          ]),
        },
        emphasis: {
          focus: "series",
        },
        data: data?.length ? data.map(el=>el.count) : [],
      },
    ],
  };
  return (
    <ReactECharts
      option={option}
      style={{ height: 200, width: "100%" }}
    />
  );
};
