import { requestWithToken } from "../helper/helper";

const addWarehouseBid = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/bidWarehouse`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchWarehouseBidList = async (currentPage, perPage, id, status) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/bidWarehouse/list?pageSize=${perPage}&pageNo=${currentPage}&id=${id}&status=${status}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchWarehouseBids = async (currentPage, perPage, status) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/bidWarehouse/list?pageSize=${perPage}&pageNo=${currentPage}&status=${status}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const acceptBid = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/buyerRequest/bid/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createWarehouseRequest = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/warehouseRequest`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchWarehouseRequestList = async (currentPage, perPage, payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/warehouseRequest/list/en?pageSize=${perPage}&pageNo=${currentPage}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchFarmertList = async (currentPage, perPage, id, status) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/farmerBid/list?pageSize=${perPage}&pageNo=${currentPage}&id=${id}&status=${status}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editReuqest = async (payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/warehouseRequest/request`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editWarehouseBid = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/warehouseRequest/bid/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getBidFarmers = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/warehouseRequest/acceptBid/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const postCompleteBid = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/warehouseRequest/complete/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getFarmerBidDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/farmerBid/details/en/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const changeWarhouseBidStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/bidWarehouse/status/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getWarehouseBidDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/bidWarehouse/details/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchwarehousePendingRequestList = async (pageNo, pageSize) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/bidWarehouse/list/new/en${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  addWarehouseBid,
  fetchWarehouseBidList,
  acceptBid,
  createWarehouseRequest,
  fetchWarehouseRequestList,
  fetchFarmertList,
  editReuqest,
  fetchWarehouseBids,
  editWarehouseBid,
  getBidFarmers,
  postCompleteBid,
  getFarmerBidDetails,
  changeWarhouseBidStatus,
  getWarehouseBidDetails,
  fetchwarehousePendingRequestList,
};
