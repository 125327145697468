import { requestWithToken } from "../helper/helper";

const uploadImage = async (payload) => {
    try {
      const response = await requestWithToken("POST", `/file/upload/social`, payload);
      return response.data
    } catch (err) {
      return err.response.data;
    }
  };
  export default uploadImage;