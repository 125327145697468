export const PageNotFound = () => {
  const role = localStorage.getItem("role");

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        placeContent: "center",
      }}
    >
      <div>
        <div style={{ fontSize: "50px", fontWeight: "700", color: "red" }}>
          Page Not Found
        </div>
        {role == "SUPERADMIN" || role == "BUYER" || role == "WAREHOUSE" ? (
          <button className="flex_btn">
            <a
              href={
                role == "SUPERADMIN"
                  ? "/gaon"
                  : role == "BUYER"
                  ? "/buyerReqest/list"
                  : role == "WAREHOUSE"
                  ? "/bidWarehouse"
                  : "/"
              }
            >
              Homepage
            </a>
          </button>
        ) : (
          <div className="login_btn_div">
            <a className="flex_btn login_btn" href="/login">
              Login
            </a>
          </div>
        )}
      </div>
      {/* <div className="col-12 d-flex flex-column justify-content-center align-items-center"> */}

      {/* </div> */}
    </div>
  );
};
