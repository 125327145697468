import { gaonTextBasedOnRoleTYpe } from "../helper/constants";

const role = localStorage.getItem("role");

export const sidenavData = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Buyer Request",
    icon: "home",
    path: "/buyerReqest/list",
  },
];

export const warehouse = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Bid Warehouse",
    path: "/bidWarehouse",
    icon: "warehouse",
  },
  {
    title: "Request To Farmers",
    path: "/warehouseRequest/list",
    icon: "farm",
  },
  {
    title: "Farmer Products",
    path: "/farmer/products",
    icon: "farm",
  },
];

export const adminMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: gaonTextBasedOnRoleTYpe()+"s",
    path: "/gaon",
    icon: "gaon",
  },
  {
    title: "Users",
    path: "/userList",
    icon: "users",
  },
  {
    title: "Units",
    path: "/unitList",
    icon: "units",
  },
  {
    title: "Products",
    path: "/product/list",
    icon: "products",
  }, 
  {
    title: "Minimum Selling Price",
    path: "/msp/list",
    icon: "priceTag",
  },
  {
    title: "Grade",
    path: "/grade/list",
    icon: "gradeIcon",
  },
];

export const superAdminMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "User Department",
    path: "/user_department",
    icon: "user_control",
  },
  {
    title: gaonTextBasedOnRoleTYpe()+"s",
    path: "/gaon",
    icon: "gaon",
  },
  {
    title: "Users",
    path: "/userList",
    icon: "users",
  },
  {
    title: "Units",
    path: "/unitList",
    icon: "units",
  },
  {
    title: "Products",
    path: "/product/list",
    icon: "products",
  },
  {
    title: "Contest",
    path: "/module/list",
    icon: "contest",
  },
  {
    title: "Minimum Selling Price",
    path: "/msp/list",
    icon: "priceTag",
  },
  {
    title: "Grade",
    path: "/grade/list",
    icon: "gradeIcon",
  },
];

export const exporterMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Exporter Request",
    icon: "home",
    path: "/buyerReqest/list",
  },
];

export const retailerMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Retailer Request",
    icon: "home",
    path: "/buyerReqest/list",
  },
];
