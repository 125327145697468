import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import { fetchWarehouseRequestList } from "../../action/warehouse";
import Button from "../../components/bootstrap/Button";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../components/bootstrap/Dropdown";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import { handleCurrency } from "../../helper/helper";

export const RequestToFarmerList = () => {
  const [bidList, setBidList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationValue, setPaginationValue] = useState(10);
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  //   const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const arr = ["Low-High", "High-Low"];
  const [filterArray, setFilterArray] = useState();
  let bidCount;
  if (filterArray == "Low-High") {
    bidCount = 1;
  }
  if (filterArray == "High-Low") {
    bidCount = -1;
  }

  const fetchRequestList = async () => {
    setIsLoading(true);

    const payload = {};
    if (bidCount) {
      payload.bidCount = bidCount;
    }
    const response = await fetchWarehouseRequestList(
      currentPage,
      paginationValue,
      payload
    );
    if (response.status) {
      setBidList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequestList();
  }, [currentPage, paginationValue, status, bidCount]);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  {
                    name: "Request To Farmers",
                    link: "/warehouseRequest/list",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <div className="scroll_x">
          <table className="payout_table upi-mandate_table">
            <thead>
              <tr className="tableHead">
                <th className="thHeader">
                  <div className="payoutHead hndIcon">Order ID</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead hndIcon">Order Date</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Product</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Variety</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Grade</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Maximum Selling Price</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Required Quantity </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Accepted Quantity </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Price </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Total Price </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Delivery Date</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">
                    Action
                    <Dropdown className="d-inline">
                      <DropdownToggle hasIcon={false}>
                        <Button icon="funnel-fill" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {arr.map((t) => (
                          <DropdownItem
                            onClick={() => {
                              console.log(t);
                              setFilterArray(t);
                              // handleFilter(t);
                            }}>
                            {t}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </th>
              </tr>
            </thead>

            {/* <tbody> */}

            {isLoading ? (
              <tr>
                <td
                  colSpan="8"
                  className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : bidList.length > 0 ? (
              bidList.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item?.orderId ?? "-"}</td>
                    <td className="phone_number">
                      {moment(item?.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="table_amount">{item?.product}</td>
                    <td className="table_amount ">{item?.veriety ?? "-"}</td>
                    <td className="">{item?.grade}</td>
                    <td className="table_amount">
                      <div>
                        {item?.mspDetails?.minimum_selling_price
                          ? item?.mspDetails?.minimum_selling_price +
                            " /" +
                            item?.mspDetails?.unit?.shortcode
                          : "-"}
                      </div>
                    </td>
                    <td className="Status">
                      <div>
                        {item?.quantity ?? 0} {item?.unit}
                      </div>
                    </td>
                    <td className="Status">
                      <div>
                        {item?.totalAcceptedBidQuantity} {item?.unit}
                      </div>
                    </td>
                    <td className="">
                      {handleCurrency()} {item?.price}
                    </td>
                    <td>
                      <div>
                        {handleCurrency()}{" "}
                        {(item?.price ?? 0) *
                          (item?.totalAcceptedBidQuantity ?? 0) *
                          (item?.conversion ?? 0)}
                      </div>
                    </td>
                    <td>{moment(item?.deliveryDate).format("DD-MM-YYYY")}</td>
                    <td>
                      {item?.status == 2 ? (
                        "Completed"
                      ) : (
                        <>
                          <Button
                            color={item?.bidsCount == 0 ? "dark" : "light"}
                            isLight="btn btn-primary"
                            tag="a"
                            to={`/farmerBid/${item._id}`}>
                            {item?.bidsCount == 0
                              ? "no bids yet"
                              : item?.bidsCount}

                            {item?.bidsCount > 1
                              ? "Farmers Bid"
                              : item?.bidsCount > 0
                              ? "Farmer Bid"
                              : ""}
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
                <tr>
                  <td
                    colSpan="15"
                    className="text_center">
                    No data available
                  </td>
                </tr>
              </>
            )}
            {/* </tbody> */}
          </table>{" "}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
      </div>
    </div>
  );
};
