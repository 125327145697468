import { requestWithToken, requestWithoutToken } from "../helper/helper";
import {
  AUTH_USER,
  AUTH_USER_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../action/types";

// Authenticate User
export const authUser = () => async (dispatch) => {
  try {
    const response = await requestWithToken("GET", "/user/login");

    dispatch({
      type: AUTH_USER,
      payload: response.data.data,
    });

    return response.data;
  } catch (err) {
    // dispatch(error(err));
    dispatch({ type: AUTH_USER_FAIL });

    return err.response.data;
  }
};

// Login User
export const login = (mobile, otp) => async (dispatch) => {
  try {
    let body = { mobile, otp };
    const response = await requestWithoutToken("POST", "/user/webLogin", body);
    // console.log("res ", response.data.data.token);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response?.data?.data?.token,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err.response;
    // dispatch(error(err));
  }
};

// Login User
export const sendOTP = async (body) => {
  try {
    const response = await requestWithoutToken(
      "POST",
      "/user/sendLoginOtp",
      body
    );
    return response.data;
  } catch (err) {
    return err.response.data;
    // dispatch(error(err));
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
