import React, { useState } from 'react';
import '../../styles/style.css';
import closebtnsvg from '../../assets/images/closewhite.svg';

export const ImagePopup = ({ src, alt }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => setIsOpen(true);
    const closePopup = () => setIsOpen(false);

    return (
        <>
            <img src={src} alt={alt} onClick={openPopup} style={{ cursor: 'pointer', maxWidth: '100px' }} />
            {isOpen && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content" onClick={closePopup} >
                        <img src={src} alt={alt} onClick={(e) => e.stopPropagation()} />
                        <button className="close-btn" onClick={closePopup}> 
                            <img src={closebtnsvg} alt="Close" width="40" height="40"/>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

