import React, { useState, useEffect } from "react";
import Pagination from "../../customComponents/pagination/paginationView";
import searchIcon from "../../assets/images/search-lg.svg";
import filerIcon from "../../assets/images/Up_Down.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import {
  createUser,
  editAdmin,
  fetchAdmin,
  fetchUsersList,
} from "../../action/userApi";
import Option from "../../components/bootstrap/Option";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import { Loader } from "../loader";
import infoBtn from "../../assets/images/info-circle.svg";
import { useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import {
  fetchCountry,
  fetchDistricts,
  fetchGaons,
  fetchStates,
  fetchSubDistricts,
} from "../../action/location";
import Select from "../../components/bootstrap/forms/Select";
import { gaonTextBasedOnRoleTYpe } from "../../helper/constants";

const UserList = () => {
  const navigate = useNavigate();

  const [userList, setUserList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [roleId, setRoleId] = useState("");
  const [isOpenCanvas, setIsOpenCanvas] = useState(false);
  const [gaonList, setGaonList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState("");
  const [countryId, setCountryId] = useState("");

  const getAdmin = async () => {
    const res = await fetchAdmin();
    if (res.status) {
      setRoleData(res.data);
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  const getUserData = async (searchValue = search, searchRole = roleId) => {
    setIsLoading(true);
    const response = await fetchUsersList(
      pageNo,
      pageSize,
      searchValue,
      sort,
      order,
      searchRole
    ); // Adjust 10 to the desired number of items per page
    if (response.status) {
      setUserList(response.data.data);
      setTotalData(response.data.count);
      setIsLoading(false);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleReset = () => {
    const resetSearchValue = ""; // Reset search value
    const resetRoleValue = "";
    setSearch("");
    setRoleId("");
    getUserData(resetSearchValue, resetRoleValue);
  };

  useEffect(() => {
    getUserData();
  }, [pageNo, pageSize, sort, order]);

  const handleUpdate = async () => {
    setIsLoading(true);
    const response = await editAdmin({
      userId: selectedUser,
      roleId: selectedRole,
    });
    if (response.status) {
      getUserData();
    }
    setIsOpenModal(false);
    setIsLoading(false);
  };

  const handleRoleChange = (userId, roleId) => {
    setSelectedUser(userId);
    setSelectedRole(roleId);
    setIsOpenModal(true);
  };

  const userFormik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      roleId: "",
      roleType: "",
      gaonId: "",
      blockId: "",
      districtId: "",
      stateId: "",
      countryId: "",
      address: "",
      coordinates: [0, 0],
      postalCode: "",
      location: "",
    },

    onSubmit: async (values) => {
      console.log(values);
      const response = await createUser(values);
      if (response.status) {
        setIsOpenCanvas(false);
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        setIsLoading(false);
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const fetchGaonList = async () => {
    setIsLoading(true);
    const response = await fetchGaons(blockId);
    if (response.status) {
      const arr = response?.data.map((item) => {
        return { value: item._id, label: item.name };
      });
      setGaonList(arr);
    }
  };

  const getCountry = async () => {
    const res = await fetchCountry();
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setCountries(arr);
    }
  };

  const getState = async () => {
    const res = await fetchStates(countryId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setStates(arr);
    }
  };

  const getDistrict = async () => {
    const res = await fetchDistricts(stateId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setDistricts(arr);
    }
  };

  const getSubDistrict = async () => {
    const res = await fetchSubDistricts(districtId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setBlocks(arr);
    }
  };

  useEffect(() => {
    if (blockId) fetchGaonList();
  }, [blockId]);

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    getState();
    // if (countryId) getState();
  }, [countryId]);

  useEffect(() => {
    if (stateId) getDistrict();
  }, [stateId]);

  useEffect(() => {
    if (districtId) getSubDistrict();
  }, [districtId]);

  return (
    <>
      <div className="payout_inner_content">
        <div className="payout_data-content tablePage_content_header">
          {/* <div className="payout_data-head">
            <div className="payout_data-head-top">
              <div className="payout_data-left">
                <div className="payout_data_ttl">
                  <Breadcrumb paths={[{ name: "Users" }]} />
                </div>

              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Search</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Type any keyword"
                    id="beneficiary"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <div className="build_search">
                    <img
                      src={searchIcon}
                      alt="Search Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Role</div>
                <Select
                  className="custom_dropdn"
                  onChange={(e) => setRoleId(e.target.value)}
                  value={roleId}>
                  <Option value="">Select Role</Option>
                  {roleData.map((role) => (
                    <Option
                      key={role._id}
                      value={role._id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-lg-3 submit_btn_grp ">
              <button
                className="btn_submit btn btn-dark"
                type="button"
                onClick={() => {
                  handleReset();
                }}
                disabled={!search && !roleId}>
                Reset
              </button>
              <button
                className={`btn btn-success btn_submit ${
                  search ? "active" : ""
                }`}
                type="submit"
                disabled={!search && !roleId}
                onClick={() => {
                  getUserData();
                }}>
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left">
              <div className="payout_data_ttl1">Users</div>
            </div>

            <Button
              color="info"
              tag="a"
              type="button"
              onClick={() => {
                // setIsOpenCanvas(true)
                navigate("/user/add");
              }}
              // to="/user/add"
            >
              Add New User
            </Button>
          </div>
        </div>
        <div className="tab_box">
          <Tabs>
            <TabPanel>
              <div className="">
                <table className="payout_table upi-mandate_table">
                  <thead>
                    <tr className="tableHead">
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">
                          Name
                          <img
                            src={filerIcon}
                            onClick={() => {
                              setSort("name");
                              setOrder(order == 1 ? -1 : 1);
                            }}
                            alt="filter"
                          />
                        </div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Email</div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Mobile No</div>
                      </th>
                      {/* <th className="thHeader">
                        <div className="payoutHead hndIcon">Place</div>
                      </th> */}
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Role</div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="4">
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {userList?.length > 0 ? (
                          userList?.map((item) => (
                            <tr key={item?._id}>
                              <td>{item?.name}</td>
                              <td>{item?.email || "-"}</td>
                              <td>{item?.mobile}</td>
                              {/* <td>{item?.gaon}</td> */}
                              <td>
                                <Select
                                  size="sm"
                                  ariaLabel="Role"
                                  onChange={(e) =>
                                    handleRoleChange(item._id, e.target.value)
                                  }
                                  value={item.role}>
                                  <Option>{item.role}</Option>
                                  {roleData
                                    .filter((data) => data.name !== item.role)
                                    .map((i) => (
                                      <Option
                                        key={i._id}
                                        value={i._id}>
                                        {i.name}
                                      </Option>
                                    ))}
                                </Select>
                              </td>
                              <td>
                                <img
                                  src={infoBtn}
                                  onClick={() => {
                                    navigate(`/user/profile/${item._id}`);
                                  }}
                                  alt="info"
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="5"
                              className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </Tabs>
          {userList.length > 0 && (
            <>
              <Pagination
                currentPage={pageNo}
                totalCount={totalData}
                pageSize={pageSize}
                onPageChange={(page) => setPageNo(page)}
                changePageSize={(page) => {
                  setPageSize(page);
                  setPageNo(1);
                }}
              />
            </>
          )}

          <Modal
            show={isOpenModal}
            onHide={() => setIsOpenModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Update Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to change the role of this user?
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-dark"
                variant="secondary"
                onClick={() => setIsOpenModal(false)}>
                No
              </Button>
              <Button
                className=" btn-success"
                variant="primary"
                onClick={handleUpdate}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* off canvas to create warehouse new request to farmer*/}
          <Offcanvas
            show={isOpenCanvas}
            onHide={() => {
              setIsOpenCanvas(false);
            }}
            placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>New Request</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="add_buyer_content">
                <form
                  action=""
                  onSubmit={userFormik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input-box">
                        <div className="input-label">
                          Name
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter name"
                            id="beneficiary"
                            name="name"
                            onChange={(e) => {
                              userFormik.setFieldValue("name", e.target.value);
                            }}
                            value={userFormik.values.name}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Mobile
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter contact no."
                            id="beneficiary"
                            name="mobile"
                            onChange={(e) => {
                              userFormik.setFieldValue(
                                "mobile",
                                e.target.value
                              );
                            }}
                            value={userFormik.values.mobile}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Email Id
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter email"
                            id="beneficiary"
                            name="email"
                            onChange={(e) => {
                              userFormik.setFieldValue("email", e.target.value);
                            }}
                            value={userFormik.values.email}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Role
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <select
                            className="input"
                            size="sm"
                            ariaLabel="Role"
                            onChange={(e) => {
                              userFormik.setFieldValue(
                                "roleId",
                                e.target.value
                              );
                            }}>
                            <option value="">Select Role</option>
                            {roleData.map((i) => (
                              <option
                                key={i._id}
                                value={i._id}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Country
                          <span>*</span>
                        </div>

                        <Select
                          name="countryId"
                          options={countries}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                              ? [selectedOptions.value]
                              : [];
                            userFormik.setFieldValue("countryId", id);
                            setCountryId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          State
                          <span>*</span>
                        </div>

                        <Select
                          name="stateId"
                          options={states}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                              ? [selectedOptions.value]
                              : [];
                            userFormik.setFieldValue("stateId", id);
                            setStateId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          District
                          <span>*</span>
                        </div>

                        <Select
                          name="districtId"
                          options={districts}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                              ? [selectedOptions.value]
                              : [];
                            userFormik.setFieldValue("districtId", id);
                            setDistrictId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Sub District
                          <span>*</span>
                        </div>

                        <Select
                          name="blockId"
                          options={blocks}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                              ? [selectedOptions.value]
                              : [];
                            userFormik.setFieldValue("blockId", id);
                            setBlockId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          {gaonTextBasedOnRoleTYpe()}
                          <span>*</span>
                        </div>

                        <Select
                          name="gaonId"
                          options={gaonList}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                              ? [selectedOptions.value]
                              : [];
                            userFormik.setFieldValue("gaonId", id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Address
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter address"
                            id="beneficiary"
                            name="address"
                            onChange={(e) => {
                              userFormik.setFieldValue(
                                "address",
                                e.target.value
                              );
                            }}
                            value={userFormik.values.address}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Postal Code
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter address"
                            id="beneficiary"
                            name="postalCode"
                            onChange={(e) => {
                              userFormik.setFieldValue(
                                "postalCode",
                                e.target.value
                              );
                            }}
                            value={userFormik.values.postalCode}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex_btn mt-3 ">
                    <button
                      className="btn btn-success"
                      type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </>
  );
};

export default UserList;
