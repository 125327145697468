import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import {
  fetchBuyerPendingRequestList,
  fetchBuyerRequestList,
} from "../../action/buyer";
import {
  addWarehouseBid,
  createWarehouseRequest,
  fetchWarehouseBids,
  fetchwarehousePendingRequestList,
} from "../../action/warehouse";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import { getProducts, getunits } from "../../action/users";
import { useFormik } from "formik";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "../../components/bootstrap/Button";
import {
  handleBuyerTitle,
  handleCurrency,
  handleKeyDown,
} from "../../helper/helper";
import { Loader } from "../loader";
import Pagination from "../../customComponents/pagination/paginationView";
import Select from "../../components/bootstrap/forms/Select";
import Option from "../../components/bootstrap/Option";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";

export const WarehouseBidList = () => {
  const [buyerReuqstList, setBuyerReuqstList] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationValue, setPaginationValue] = useState(10);
  const [id, setId] = useState("");
  const [buyerReqId, setBuyerReqId] = useState("");

  const [show, setShow] = useState(false);
  const [showBidCanvas, setShowBidCanvas] = useState(false);
  const [units, setUnits] = useState([]);
  const [products, setproducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingList, setPendingList] = useState([]);
  const [value, setValue] = useState(25); // Default value set to 25
  const [addressId, setAddressId] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const param = useParams();
  const [status, setStatus] = useState(param ? param.status : 0);

  const handleClose = () => {
    setShow(false);
    requestToFarmerFormik.handleReset();
  };
  const handleShow = () => setShow(true);

  const fetchBuyerList = async () => {
    setIsLoading(true);
    const response = await fetchBuyerRequestList(
      currentPage,
      paginationValue,
      0
    );
    if (response.status) {
      setBuyerReuqstList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const fetchWarehouseBidList = async () => {
    setIsLoading(true);
    const response = await fetchWarehouseBids(
      currentPage,
      paginationValue,
      status
    );
    if (response.status) {
      setBidList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const fetchBuyerPendingList = async () => {
    setIsLoading(true);
    const response = await fetchwarehousePendingRequestList(
      currentPage,
      paginationValue
    );

    if (response.status) {
      setPendingList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };

  const requestToFarmerFormik = useFormik({
    initialValues: {
      quantity: "",
      unitId: "",
      price: "",
      deliveryDate: "",
      buyerReqId: "",
      distance: "",
    },
    onSubmit: async (values) => {
      values.deliveryDate = moment(values.deliveryDate).format("YYYY-MM-DD");
      values.buyerReqId = buyerReqId;
      values.distance = Number(value);
      console.log(values, "values");

      const response = await createWarehouseRequest(values);
      if (response.status) {
        console.log("submit");
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        fetchWarehouseBidList();
        setValue(25);
      } else {
        console.log("not submit");
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
      handleClose();
      requestToFarmerFormik.handleReset();
    },
  });

  const addBidFormik = useFormik({
    initialValues: {
      bidId: "",
      price: 0,
      deliveryDate: "",
      quantity: "",
      unitId: "",
      addressId: "",
    },
    onSubmit: async (values) => {
      values.bidId = id;
      values.addressId = addressId;
      console.log(values);
      const response = await addWarehouseBid(values);
      if (response.status) {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        fetchBuyerPendingList();
        setShowBidCanvas(false);
        addBidFormik.handleReset();
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        setShowBidCanvas(false);
      }
      addBidFormik.handleReset();
    },
  });

  useEffect(() => {
    fetchWarehouseBidList();
  }, [currentPage, paginationValue, status, param]);

  useEffect(() => {
    if (param.status == 4) {
      console.log("status", param.status);
      setStatus(4);
      setTabIndex(1);
      handleTabChange(4);
    } else if (param.status == 1) {
      console.log("status", param.status);
      setTabIndex(2);
      handleTabChange(1);
    }
  }, [param]);

  useEffect(() => {
    fetchBuyerPendingList();
    fetchBuyerList();
    fetchProducts();
    fetchUnits();
  }, [currentPage, paginationValue]);

  const fetchUnits = async () => {
    const response = await getunits();
    if (response.status) {
      setUnits(response.data);
    } else {
    }
  };

  const fetchProducts = async () => {
    const response = await getProducts();
    if (response.status) {
      setproducts(response.data);
    } else {
    }
  };
  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleRangeChange = (e) => {
    setValue(e.target.value);
  };
  const handleTabChange = (status) => {
    setStatus(status);
    setCurrentPage(1);
    setPaginationValue(10);
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {handleBuyerTitle()} Request List
              {/* <div className="hits">1321 Hits</div> */}
            </div>
            {/* <div className="payout_data-desc">
              Keep track of your daily payouts.
            </div> */}
          </div>
        </div>
      </div>
      <div className="tab_box">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab
              onClick={() => {
                fetchBuyerPendingList();
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              New Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                handleTabChange(4);
              }}
            >
              Applied Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setStatus(1);
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              {" "}
              Confirmed Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setStatus(2);
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              Completed Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
          </TabList>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">
                        {handleBuyerTitle()} Name
                      </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Role</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Maximum Selling Price</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    {/* <th className="thHeader">
                      <div className="payoutHead"> Address</div>
                    </th> */}
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Action </div>
                    </th>
                  </tr>
                </thead>

                {/* <tbody> */}

                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : pendingList.length > 0 ? (
                  pendingList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item.orderId}</td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status">{item?.userDetails?.name}</td>
                        <td className="Status">{item?.roleDetails?.name}</td>
                        <td className="Status">
                          {item?.location}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">
                          <div>
                            {item?.mspDetails?.minimum_selling_price
                              ? item?.mspDetails?.minimum_selling_price +
                                " /" +
                                item?.mspDetails?.unit?.shortcode
                              : "-"}
                          </div>
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td className="table_amount ">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="">{item?.grade}</td>
                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status">
                          <div>
                            {item?.quantity} {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        {/* <td className="Status">
                          {item?.warehouseAddress?.address ?? "-"} 
                        </td> */}
                        <td>
                          <div>
                            {handleCurrency()}{" "}
                            {(item?.price ?? 0) *
                              (item?.quantity ?? 0) *
                              (item?.conversion ?? 0)}
                          </div>
                        </td>

                        <td className="noWrap">
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>

                        <td>
                          {/* <div className="action_payment"> */}
                          <Button
                            color="dark"
                            className="noWrap"
                            isLight="btn btn-primary"
                            tag="a"
                            onClick={() => {
                              let id = item?.warehouseAddress?._id
                                ? item?.warehouseAddress?._id
                                : item?.warehouseDefaultAddress[0]?._id;
                              setAddressId(id);
                              setId(item._id);

                              // Prefill form values using the selected row data
                              addBidFormik.setValues({
                                bidId: item._id,
                                quantity: item.quantity,
                                unitId: item.unitId,
                                price: item.price,
                                deliveryDate: moment(item.deliveryDate).format(
                                  "YYYY-MM-DD"
                                ),
                                addressId: id,
                              });

                              setShowBidCanvas(true);
                            }}
                          >
                            Apply Bid
                          </Button>

                          {/* </div> */}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-align-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
                {/* </tbody> */}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            {/* <div className="payout_table-box upi-mandate_table-box"> */}
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">
                        {handleBuyerTitle()} Name
                      </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Role</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Maximum Selling Price</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : bidList.length > 0 ? (
                  bidList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item?.bidDetails?.orderId}</td>
                        <td className="phone_number">{item?.buyer?.name}</td>
                        <td className="Status">{item?.roleDetails?.name}</td>
                        <td className="Status">
                          {item?.bidDetails?.addressDetails?.location?.address}
                          {/* {item?.location} */}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">
                          <div>
                            {item?.mspDetails?.minimum_selling_price
                              ? item?.mspDetails?.minimum_selling_price +
                                " /" +
                                item?.mspDetails?.unit?.shortcode
                              : "-"}
                          </div>
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td className="table_amount ">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="">{item?.grade}</td>

                        <td className="Status">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status">
                          <div>
                            {item?.quantity} {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {handleCurrency()}{" "}
                            {(item?.price ?? 0) *
                              (item?.quantity ?? 0) *
                              (item?.conversion ?? 0)}
                          </div>
                        </td>
                        <td>
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-align-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </table>{" "}
            </div>
            {/* </div> */}
          </TabPanel>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">
                        {handleBuyerTitle()} Name
                      </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Role</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Address</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">MSP</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    {/* <th className="thHeader">
                      <div className="payoutHead">
                        Accepted Farmers Quantity{" "}
                      </div>
                    </th> */}
                    <th className="thHeader">
                      <div className="payoutHead">Total Price</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Action </div>
                    </th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : bidList.length > 0 ? (
                  bidList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item?.bidDetails?.orderId}</td>
                        <td className="phone_number">{item?.buyer?.name}</td>
                        <td className="Status">{item?.roleDetails?.name}</td>
                        <td className="Status address_td">
                          {item?.bidDetails?.addressDetails?.location?.address}
                          {/* {item?.location} */}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td className="table_amount ">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="">{item?.grade}</td>

                        <td className="Status">
                          <div>
                            {" "}
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status noWrap">
                          <div>
                            {item?.mspDetails?.minimum_selling_price
                              ? item?.mspDetails?.minimum_selling_price +
                                " /" +
                                item?.mspDetails?.unit?.shortcode
                              : "-"}
                          </div>
                        </td>
                        <td className="Status noWrap">
                          <div>
                            {item?.acceptedQty} {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {handleCurrency()}{" "}
                            {(item?.price ?? 0) *
                              (item?.acceptedQty ?? 0) *
                              (item?.conversion ?? 0)}
                          </div>
                        </td>
                        {/* <td className="Status noWrap">
                          <div>
                            {item?.allocateQty
                              ? item?.allocateQty + " " + item?.unit
                              : 0}
                          </div>
                        </td> */}
                        <td className="noWrap">
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <div className="action_payment">
                            {item?.isWarehouseRequestedToFarmer ? (
                              "Requested"
                            ) : (
                              <Button
                                color="dark"
                                className="noWrap"
                                isLight="btn btn-primary"
                                tag="a"
                                onClick={() => {
                                  let id = item?.warehouseAddress?._id
                                    ? item?.warehouseAddress?._id
                                    : item?.warehouseDefaultAddress[0]?._id;
                                  setId(item._id);
                                  setBuyerReqId(item.bidDetails._id);
                                  console.log(buyerReqId, "buyer Require id");
                                  // Prefill form values using the selected row data
                                  requestToFarmerFormik.setValues({
                                    quantity: item.acceptedQty,
                                    unitId: item.unitId,
                                    price: item.price,
                                    deliveryDate: moment(
                                      item.deliveryDate
                                    ).format("YYYY-MM-DD"),
                                  });

                                  handleShow();
                                }}
                              >
                                Request to Farmer
                              </Button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-align-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Order ID</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">
                        {handleBuyerTitle()} Name
                      </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Role</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Address</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Maximum Selling Price</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead">Product</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Variety</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Grade</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Quantity </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Total Price </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead">Delivery Date</div>
                    </th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : bidList.length > 0 ? (
                  bidList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item?.bidDetails?.orderId}</td>
                        <td className="phone_number">{item?.buyer?.name}</td>
                        <td className="Status">{item?.roleDetails?.name}</td>
                        <td className="Status">
                          {item?.bidDetails?.addressDetails?.location?.address}
                          {/* {item?.location} */}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">
                          <div>
                            {item?.mspDetails?.minimum_selling_price
                              ? item?.mspDetails?.minimum_selling_price +
                                " /" +
                                item?.mspDetails?.unit?.shortcode
                              : "-"}
                          </div>
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td className="table_amount ">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="">{item?.grade}</td>

                        <td className="Status">
                          <div>
                            {" "}
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td className="Status noWrap">
                          <div>
                            {item?.acceptedQty} {item?.unit ? item?.unit : ""}
                          </div>
                        </td>
                        <td>
                          <div>
                            {handleCurrency()}{" "}
                            {(item?.price ?? 0) *
                              (item?.acceptedQty ?? 0) *
                              (item?.conversion ?? 0)}
                          </div>
                        </td>
                        <td className="noWrap">
                          {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="10" className="text-align-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </table>{" "}
            </div>
          </TabPanel>
          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </Tabs>

        {/* off canvas to create warehouse new request to farmer*/}
        <Offcanvas
          show={showBidCanvas}
          onHide={() => {
            setShowBidCanvas(false);
            addBidFormik.handleReset();
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Apply Bid</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form action="" onSubmit={addBidFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Quantity
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter quantity"
                          id="beneficiary"
                          name="quantity"
                          min="1"
                          max="100000000"
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "quantity",
                              Number(e.target.value)
                            );
                          }}
                          value={addBidFormik.values.quantity}
                          required
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">
                        Unit
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <Select
                          className="input"
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "unitId",
                              e.target.value
                            );
                          }}
                          value={addBidFormik.values.unitId ?? ""}
                          required
                        >
                          <Option value="">Select Unit</Option>
                          {units.map((unit) => {
                            return (
                              <Option
                                key={unit._id}
                                id={unit._id}
                                value={unit._id}
                              >
                                {unit.shortcode}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">
                        Price/kg
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter price"
                          id="beneficiary"
                          name="price"
                          min="1"
                          max="1000000"
                          onChange={(e) => {
                            addBidFormik.setFieldValue("price", e.target.value);
                          }}
                          value={addBidFormik.values.price}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input-label">
                      Delivery Date
                      <span>*</span>
                    </div>
                    <div className="input_field">
                      <input
                        required
                        name="deliveryDate"
                        className="input"
                        type="date"
                        placeholder="select date"
                        value={addBidFormik.values.deliveryDate}
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addBidFormik.handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex_btn mt-3 ">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* off canvas to create warehouse new request to farmer*/}
        <Offcanvas
          show={show}
          onHide={() => {
            handleClose();
            setValue(25);
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Confirm Request</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form action="" onSubmit={requestToFarmerFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <Form.Label>Distance: {value}(KM)</Form.Label>
                    <Form.Range
                      value={value}
                      onChange={handleRangeChange}
                      min={0} // Minimum value for the range slider
                      max={4000} // Maximum value for the range slider
                      step={1}
                    />
                    <div className="input-box">
                      <div className="input-label">
                        Quantity
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter quantity"
                          id="beneficiary"
                          name="quantity"
                          min="1"
                          max="100000000"
                          onChange={(e) => {
                            requestToFarmerFormik.setFieldValue(
                              "quantity",
                              Number(e.target.value)
                            );
                          }}
                          value={requestToFarmerFormik?.values?.quantity}
                          required
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">
                        Unit
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <Select
                          className="input"
                          onChange={(e) => {
                            console.log(e.target.value, "  e");
                            requestToFarmerFormik.setFieldValue(
                              "unitId",
                              e.target.value
                            );
                          }}
                          value={requestToFarmerFormik.values.unitId ?? ""}
                          required
                        >
                          <Option value="">Select Unit</Option>
                          {units.map((unit) => {
                            return (
                              <Option
                                key={unit._id}
                                id={unit._id}
                                value={unit._id}
                              >
                                {unit.shortcode}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>

                    <div className="input-box">
                      <div className="input-label">
                        Price
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter price"
                          id="beneficiary"
                          name="price"
                          min="1"
                          max="10000000"
                          onChange={(e) => {
                            requestToFarmerFormik.setFieldValue(
                              "price",
                              e.target.value
                            );
                          }}
                          value={requestToFarmerFormik.values.price}
                          onKeyDown={handleKeyDown}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <div className="input-label">
                        Delivery Date
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          className="input"
                          required
                          name="deliveryDate"
                          type="date"
                          placeholder="select date"
                          value={requestToFarmerFormik.values.deliveryDate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={requestToFarmerFormik.handleChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex_btn mt-3 ">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};
