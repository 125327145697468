import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";
// import Input from '../bootstrap/forms/Input';
// import Button from '../bootstrap/Button';
import styles from "./ThumbnailPicker.module.css";

const ThumbnailPicker = ({ onFileSelect, width, height, image,readOnly }) => {
  const dragDropContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {}, [image]);

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const onDragOver = (e) => {
    e.preventDefault();
    dragDropContainerRef.current.classList.add(styles["drag-drop-container"]);
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    dragDropContainerRef.current.classList.add(styles["drag-drop-container"]);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    dragDropContainerRef.current.classList.remove(
      styles["drag-drop-container"]
    );
  };

  const onDrop = (e) => {
    e.preventDefault();
    dragDropContainerRef.current.classList.remove(
      styles["drag-drop-container"]
    );
    onFileChange(e.dataTransfer.files[0]);
  };

  const onFileChange = (file) => {
    if (file) {
      setSelectedFile(file);
      onFileSelect(file);
    }
  };

  return (
    <div
      ref={dragDropContainerRef}
      className="drag-drop-container position-relative flex-grow-1"
      style={{ height, width }}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {selectedFile && image && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={`${styles["image-preview"]} h-100 w-100`}
          style={{
            display: "block",
            // background: `url(${URL.createObjectURL(localImage)})`,
            background: `${
              selectedFile
                ? `url(${URL.createObjectURL(selectedFile)})`
                : `url(${process.env.REACT_APP_S3_URL + image})`
            }`,
          }}
          onClick={openFileDialog}
        />
      )}
      {image && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={`${styles["image-preview"]} h-100 w-100`}
          style={{
            display: "block",
            // background: `url(${URL.createObjectURL(localImage)})`,
            background: `${
              selectedFile
                ? `url(${URL.createObjectURL(selectedFile)})`
                : `url(${process.env.REACT_APP_S3_URL + image})`
            }`,
          }}
          onClick={openFileDialog}
        />
      )}
      <button
        type="button"
        className={`${styles["file-picker"]} form-control`}
        onClick={openFileDialog}
      >
        {image ? (
          <img
            alt="jsd"
            style={{ width, height }}
            src={`${process.env.REACT_APP_S3_URL}/${image}`}
          />
        ) : (
          <Icon icon="camera-fill" size="3x" />
        )}
      </button>
      <input
        disabled= {readOnly??false}
        typeof="button"
        ref={fileInputRef}
        type="file"
        className="d-none"
        onChange={(e) => onFileChange(e.target.files[0])}
      />
    </div>
  );
};

ThumbnailPicker.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  image: PropTypes.string,
};

ThumbnailPicker.defaultProps = {
  width: "100%",
  height: "auto",
  image: "",
};

export default ThumbnailPicker;
